import { Some } from "./Some";
import { Toast } from "react-native-toast-notifications";

type ShowToastParams = {
  message: string;
  onClose?: () => void;
};

export const showToast = ({ message, onClose }: ShowToastParams) => {
  Toast.show(message, {
    duration: 1500,
    onClose: () => {
      if (Some(onClose)) {
        onClose();
      }
    },
    placement: "top",
    type: "danger",
  });
};
