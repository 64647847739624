// Copyright 2022 Merit International Inc. All Rights Reserved.

import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import type {
  LoginAsMeritCsResponse as MeritCS,
  LoginAsParentResponse as Parent,
  LoginAsServiceProviderResponse as ServiceProvider,
} from "../__generated__/api/LoginRoute";

export enum UserType {
  PARENT = "parent",
  SERVICE_PROVIDER = "service_provider",
  MERIT_CS = "merit_cs",
}

export type SelectedOrg = {
  readonly id: string;
  readonly templateId: string;
  readonly name: string;
};

export type User = MeritCS | Parent | ServiceProvider;

type UserState = {
  readonly userType: UserType | undefined;
  readonly user: User | undefined;
  readonly selectedOrg: SelectedOrg | undefined;
  readonly setUser: (user: User) => void;
  readonly setUserType: (UserType: UserType) => void;
  readonly setSelectedOrg: (selectedOrgId: SelectedOrg) => void;

  /**
   * Logs the user out from userStore.
   *
   * @warn Use the useLogout hook for a more appropriate logout mechanism
   */
  readonly logoutFromUserStore: () => void;
};

export const useUserStore = create<UserState>()(
  persist(
    (set) => ({
      logoutFromUserStore: () => {
        set({ selectedOrg: undefined, user: undefined });
      },
      selectedOrg: undefined,
      setSelectedOrg: (selectedOrg: SelectedOrg) => {
        set({ selectedOrg });
      },
      setUser: (user: User) => {
        set({ user });
      },
      setUserType: (userType: UserType) => {
        set({ userType });
      },
      user: undefined,
      userType: undefined,
    }),
    {
      name: "userstate-storage",
      storage: createJSONStorage(() => AsyncStorage),
    },
  ),
);
