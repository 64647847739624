// Copyright 2022 Merit International Inc. All Rights Reserved

import { Image, Pressable, SafeAreaView, StyleSheet, Text, View } from "react-native";
import { PRIMARY_THEME_COLOR } from "../constants";
import { UserType } from "../store/userStore";
import { setTestProps } from "../utils/propHelper";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useLogin } from "../hooks/auth";
import LogoRectangle from "../../assets/images/logo-rectangle.png";
import LogoSquare from "../../assets/images/logo-square.png";
import MeritSmile from "../../assets/images/merit-smile-transparent.png";
import React from "react";
import SplashImg from "../../assets/images/service-provider-splash.png";

export const ServiceProviderLogin = () => {
  const promptLogin = useLogin(UserType.SERVICE_PROVIDER);
  const { isDesktopOrLarger } = useDeviceSize();

  const styles = StyleSheet.create({
    alignItems: {
      alignItems: isDesktopOrLarger ? "flex-start" : "center",
    },
    button: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
      paddingHorizontal: 24,
      paddingVertical: isDesktopOrLarger ? 6 : 12,
    },
    buttonWrapper: {
      backgroundColor: "#00CCC0",
      borderRadius: 4,
      paddingVertical: 8,
      width: 270,
    },
    container: {
      backgroundColor: "#ffffff",
      flex: 1,
    },
    heading: {
      fontSize: isDesktopOrLarger ? 42 : 28,
      fontWeight: "500",
    },
    logo: {
      ...(isDesktopOrLarger
        ? {
            height: 48,
            width: 160,
          }
        : {
            height: 140,
            width: 140,
          }),
    },
    rightSection: {
      backgroundColor: PRIMARY_THEME_COLOR,
      flex: 1,
      justifyContent: "center",
      paddingLeft: 100,
    },
    section: {
      alignItems: "center",
      justifyContent: "center",
    },
    textAlign: {
      textAlign: isDesktopOrLarger ? "left" : "center",
    },
  });

  return (
    <SafeAreaView style={{ backgroundColor: "#ffffff", flex: 1 }}>
      <View style={styles.container}>
        <View style={[{ flex: 1 }, isDesktopOrLarger ? { flexDirection: "row" } : styles.section]}>
          <View style={[styles.alignItems, { flex: 1, paddingTop: 72 }]}>
            <View style={{ flex: 1, paddingLeft: isDesktopOrLarger ? 84 : undefined, width: "100%" }}>
              <View style={[styles.alignItems, { flex: isDesktopOrLarger ? undefined : 5, justifyContent: "center" }]}>
                <Image
                  accessibilityLabel="KEEP logo"
                  accessible
                  resizeMode="contain"
                  source={isDesktopOrLarger ? LogoRectangle : LogoSquare}
                  style={styles.logo}
                />
              </View>
              <View style={{ flex: isDesktopOrLarger ? undefined : 5 }}>
                <View style={{ height: 28 }} />
                <View style={{ maxWidth: 450 }}>
                  <View style={{ paddingVertical: 16 }}>
                    <Text
                      style={[styles.textAlign, styles.heading]}
                      {...setTestProps({ name: "welcomeTitle-ServiceProviderLogin" })}
                    >
                      Welcome to the Kansas Education Enrichment Program (KEEP)
                    </Text>
                  </View>
                  <View>
                    <Text style={[styles.textAlign, { color: "#0B1D37", fontSize: 16, lineHeight: 24 }]}>
                      As a service provider, link your bank account and act on your claims
                    </Text>
                  </View>
                </View>
                <View style={{ height: 60 }} />
                <View style={[styles.alignItems, { paddingVertical: 16 }]}>
                  <Pressable
                    accessibilityRole="button"
                    onPress={() => {
                      promptLogin();
                    }}
                    style={styles.buttonWrapper}
                    {...setTestProps({ name: "linkWithMeritButton-ServiceProviderLogin" })}
                  >
                    <View style={styles.button}>
                      <Image
                        accessibilityLabel="Merit smile logo"
                        accessible
                        resizeMode="contain"
                        source={MeritSmile}
                        style={{ height: 16, width: 44 }}
                      />
                      <View style={{ paddingHorizontal: 16 }}>
                        <Text>|</Text>
                      </View>
                      <Text style={[styles.textAlign, { fontSize: 16, fontWeight: "500" }]}>Link with Merit</Text>
                    </View>
                  </Pressable>
                </View>
              </View>
            </View>
          </View>
          {isDesktopOrLarger && (
            <View style={styles.rightSection}>
              <Image
                accessibilityLabel="KEEP dashboard image"
                accessible
                resizeMode="contain"
                source={SplashImg}
                style={{ height: 640, width: "100%" }}
              />
            </View>
          )}
        </View>
      </View>
    </SafeAreaView>
  );
};
