import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { useMeritAuth0 } from "../../hooks/auth";
import type { FCWithChildren } from "../../types/component";

export const DdProvider: FCWithChildren = ({ children }) => {
  const { isAuthenticated, user } = useMeritAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      datadogRum.setUser({
        id: user.entityID,
      });
    }

    return () => {
      datadogRum.clearUser();
    };
  }, [isAuthenticated, user]);

  return <>{children}</>;
};
