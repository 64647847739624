// Copyright 2022 Merit International Inc. All Rights Reserved

import * as Sentry from "sentry-expo";
import { Error500Screen } from "../screens/ErrorScreens/Error500Screen";
import { ErrorBoundary } from "react-error-boundary";
import { Platform } from "react-native";
import { Some } from "./Some";
import { useUserStore } from "../store/userStore";
import type { ReactNode } from "react";

type Props = {
  readonly children: ReactNode;
};

export function SentryErrorBoundary({ children }: Props) {
  const user = useUserStore((state) => state.user);

  return (
    <ErrorBoundary
      fallbackRender={Error500Screen}
      onError={(error) => {
        if (Platform.OS === "web") {
          Sentry.Browser.withScope((scope) => {
            if (Some(user)) {
              scope.setUser({
                id: `${user.type}-${user.id}`,
              });
            }

            Sentry.Browser.captureException(error);
          });
        } else {
          Sentry.Native.captureException(error);
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
