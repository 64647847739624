import { None } from "../utils/None";
import { Platform } from "react-native";
import { useApi } from "../services/useApi";
import { useEffect, useState } from "react";
import Constants from "expo-constants";

const useShowUpgradeScreen = () => {
  const [showUpgradeAppScreen, setShowUpgradeAppScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { versionClient } = useApi();

  useEffect(() => {
    const isAppVersionAllowed = async () => {
      if (None(Constants.expoConfig)) {
        throw new Error("Expo config not loaded yet!");
      }

      if (None(Constants.expoConfig.version)) {
        throw new Error("Expo config not loaded yet!");
      }

      if (Platform.OS === "android" || Platform.OS === "ios") {
        try {
          setIsLoading(true);
          const response = await versionClient.getAppUpdateInfo(Constants.expoConfig.version);
          setShowUpgradeAppScreen(response.updateApp);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };
    isAppVersionAllowed();
  }, [versionClient]);

  return { isLoading, showUpgradeAppScreen };
};

export { useShowUpgradeScreen };
