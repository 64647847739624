// Copyright 2022 Merit International Inc. All Rights Reserved

import "react-datepicker/dist/react-datepicker.css";
// eslint-disable-next-line sort-imports-es6-autofix/sort-imports-es6
import "./DatePicker.css";
import { CustomDateInput } from "./CustomDateInput";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Some } from "../../utils/Some";
import { setTestProps } from "../../utils/propHelper";
import { useLocaleStore } from "../../store/localeStore";
import React, { useEffect } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import chevronDown from "../../../assets/icons/chevron_down_s_default.png";
import es from "date-fns/locale/es";
import type { DatePickerProps } from "./types";

const styles = StyleSheet.create({
  header: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
  },
});

export type DateType = Date | null;

export const DatePicker = ({
  disabled,
  maxDate,
  minDate,
  onBlur,
  onChange,
  onFocus,
  testID,
  value,
}: DatePickerProps) => {
  const { locale } = useLocaleStore();

  const blur = () => {
    if (Some(onBlur)) {
      onBlur();
    }
  };

  useEffect(() => {
    registerLocale("es", es);
  });

  return (
    <View style={{ position: "relative" }} {...setTestProps({ name: testID })}>
      <ReactDatePicker<"", false>
        className={disabled === true ? "datePicker-input-disabled" : "datePicker-input"}
        customInput={<CustomDateInput onBlur={onBlur} onFocus={onFocus} />}
        dateFormat="MM / dd / yyyy"
        disabled={disabled}
        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 3)}
        locale={locale}
        maxDate={maxDate}
        minDate={minDate}
        onBlur={onBlur}
        onChange={(newDate) => {
          onChange(newDate?.toDateString() ?? undefined);
        }}
        onClickOutside={blur}
        onFocus={onFocus}
        onSelect={blur}
        renderCustomHeader={({ date: headerDate, decreaseMonth, increaseMonth }) => (
          <View style={styles.header}>
            <TouchableOpacity onPress={decreaseMonth}>
              <Image accessible source={chevronDown} style={{ height: 20, width: 20 }} />
            </TouchableOpacity>
            <Text>{new Intl.DateTimeFormat(locale, { month: "long", year: "numeric" }).format(headerDate)}</Text>
            <TouchableOpacity onPress={increaseMonth}>
              <Image accessible source={chevronDown} style={{ height: 20, width: 20 }} />
            </TouchableOpacity>
          </View>
        )}
        selected={value}
        showPopperArrow={false}
      />
    </View>
  );
};
