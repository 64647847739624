import { Image, Text, View } from "react-native";
import { ToastProvider } from "react-native-toast-notifications";
import { UnreachableCaseError } from "../utils/UnreachableCaseError";
import { useDeviceSize } from "./useDeviceSize";
import React from "react";
import SuccessIcon from "../../assets/icons/success_m.png";
import WarningIcon from "../../assets/icons/warning_m.png";
import type { ReactNode } from "react";

type Props = {
  readonly children: ReactNode;
};

type ToastType = "danger" | "success" | "warning";

export const ToastMessage = ({ children }: Props) => {
  const { isDesktopOrLarger } = useDeviceSize();

  const getBorderTopColor = (toastType: ToastType) => {
    switch (toastType) {
      case "danger":
        return "#FF5340";
      case "success":
        return "#00B27D";
      case "warning":
        return "#F4BC00";
      default:
        throw new UnreachableCaseError(toastType);
    }
  };

  return (
    <ToastProvider
      duration={4000}
      offsetBottom={60}
      renderToast={({ message, type }) => (
        <View style={{ justifyContent: isDesktopOrLarger ? "flex-start" : "center", width: "100%" }}>
          <View
            style={{
              alignItems: "center",
              backgroundColor: "#FFFFFF",
              borderColor: "#CCC",
              borderRadius: 4,
              borderTopColor: getBorderTopColor(type as ToastType),
              borderTopWidth: 5,
              borderWidth: 1,
              flexDirection: "row",
              flexShrink: 1,
              marginHorizontal: 10,
              maxWidth: isDesktopOrLarger ? 300 : undefined,
              padding: 12,
            }}
          >
            <View style={{ justifyContent: "center", paddingRight: 16 }}>
              <Image
                accessibilityLabel="status icon"
                accessible
                source={type === "success" ? SuccessIcon : WarningIcon}
                style={{ height: 20, width: 20 }}
              />
            </View>
            <Text style={{ flexShrink: 1 }}>{message}</Text>
          </View>
        </View>
      )}
    >
      {children}
    </ToastProvider>
  );
};
