import { Button } from "../components/Button";
import { ExternalLink } from "../components/ExternalLink";
import { HELP_EMAIL_ADDRESS, PRIMARY_THEME_COLOR } from "../constants";
import { Image, SafeAreaView, StatusBar, StyleSheet, Text, View } from "react-native";
import { setTestProps } from "../utils/propHelper";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useLogout } from "../hooks/useLogout";
import { useTranslation } from "../hooks/useTranslation";
import Lock from "../../assets/icons/lock.png";

export function NotEligibleScreen() {
  const i18n = useTranslation();
  const { logout } = useLogout();
  const { isDesktopOrLarger } = useDeviceSize();
  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      flex: 1,
    },
    header: {
      alignItems: "center",
      backgroundColor: PRIMARY_THEME_COLOR,
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 24,
      paddingVertical: 24,
    },
    headerText: {
      color: "#FFFFFF",
      fontSize: 20,
      fontWeight: "600",
    },
  });

  return (
    <SafeAreaView style={{ backgroundColor: "#FFFFFF", flex: 1 }}>
      <StatusBar backgroundColor={PRIMARY_THEME_COLOR} />
      <View style={styles.header}>
        <Text style={styles.headerText}>KEEP Claims</Text>
        <Button
          onPress={() => {
            logout();
          }}
          size="small"
          testID="logoutButton-NotEligibleScreen"
          text="Logout"
          type="secondary"
        />
      </View>
      <View style={styles.container}>
        <View style={{ width: isDesktopOrLarger ? "30%" : "80%" }}>
          <View style={{ alignItems: "center", paddingVertical: 40 }}>
            <Image accessibilityLabel="lock" accessible source={Lock} style={{ height: 110, width: 110 }} />
          </View>
          <View style={{ paddingVertical: 16 }}>
            <Text
              {...setTestProps({ name: "noStudentsMessage-NotEligibleScreen" })}
              style={{ fontSize: 28, fontWeight: "600" }}
            >
              {i18n.t("NotEligibleScreen.title")}
            </Text>
          </View>
          <Text style={{ lineHeight: 24 }} {...setTestProps({ name: "reachOutToMessage-NotEligibleScreen" })}>
            {i18n.t("NotEligibleScreen.subtitle", {
              externalLinkElement: (
                <ExternalLink
                  testID="helpEmailLink-NotEligibleScreen"
                  text={HELP_EMAIL_ADDRESS}
                  textStyle={{ lineHeight: 24 }}
                  url={`mailto:${HELP_EMAIL_ADDRESS}`}
                />
              ),
            })}
          </Text>
        </View>
      </View>
    </SafeAreaView>
  );
}
