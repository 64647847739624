export const getServiceCategoryLabel = (categoryName: string) => {
  switch (categoryName) {
    case "Camps":
      return "Camps";
    case "Language classes":
      return "Language Classes";
    case "Instrument lessons (and vocal)":
      return "Music lessons / Instrument Purchases";
    case "Curriculum, school supplies, technology":
      return "Curriculum materials / School Supplies / Technology";
    default:
      return categoryName;
  }
};
