import { Button } from "../../components/Button";
import { HELP_URL, PRIMARY_THEME_COLOR } from "../../constants";
import { Image, Platform, Pressable, SafeAreaView, StyleSheet, Text, View } from "react-native";
import { ManageClaims } from "../../components/MeritCS/ManageClaims";
import { None } from "../../utils/None";
import { UpdateServiceProviderStatus } from "../../components/MeritCS/UpdateServiceProviderStatus";
import { UserType, useUserStore } from "../../store/userStore";
import { openURL } from "../../utils/openURL";
import { setTestProps } from "../../utils/propHelper";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useLogout } from "../../hooks/useLogout";
import Help from "../../../assets/icons/help.png";
import React, { useState } from "react";

const tabs = ["Manage claims", "Manage service provider"] as const;

export const Dashboard = () => {
  const { logout } = useLogout();
  const { isDesktopOrLarger } = useDeviceSize();
  const [selectedTab, setSelectedTab] = useState<(typeof tabs)[number]>(tabs[0]);

  const user = useUserStore((_) => _.user);

  if (None(user)) {
    throw new Error("Could not load user details");
  }

  if (user.type !== UserType.MERIT_CS) {
    throw new Error("Logged in user is not a merit cs");
  }

  const styles = StyleSheet.create({
    activeTabs: {
      borderBottomColor: PRIMARY_THEME_COLOR,
      borderBottomWidth: 2,
    },
    header: {
      alignItems: "center",
      backgroundColor: PRIMARY_THEME_COLOR,
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 24,
    },
    headerText: {
      color: "#FFFFFF",
      fontSize: 20,
      fontWeight: "600",
    },
    inlineTabs: {
      backgroundColor: "#FFFFFF",
      borderBottomColor: "#D3D3D3",
      borderBottomWidth: StyleSheet.hairlineWidth,
      flexDirection: "row",
      paddingLeft: 20,
      paddingTop: 20,
    },
    tabs: {
      display: "flex",
      fontSize: 18,
      fontWeight: "500",
      justifyContent: "space-between",
      padding: 15,
      position: "relative",
    },
    tabsContainer: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      width: "100%",
    },
  });

  return (
    <SafeAreaView style={{ backgroundColor: "#FFFFFF", flex: 2 }}>
      <View style={styles.header}>
        <Text style={styles.headerText} {...setTestProps({ name: "header-Dashboard" })}>
          KEEP Claims
        </Text>

        <View style={{ flexDirection: "row" }}>
          <Pressable
            onPress={() => {
              // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
              Platform.OS === "web" ? window.open(HELP_URL) : openURL(HELP_URL);
            }}
            style={{ flexDirection: "row", marginRight: 20, paddingVertical: 6 }}
          >
            <Image accessible source={Help} style={{ height: 20, width: 20 }} />
            <View style={{ justifyContent: "center", marginLeft: 8 }}>
              <Text style={{ color: "#FFFFFF" }}>{isDesktopOrLarger ? "Help center" : "Help"}</Text>
            </View>
          </Pressable>
          <Button
            onPress={() => {
              logout();
            }}
            size="small"
            testID="logoutButton-Dashboard"
            text="Logout"
            type="secondary"
          />
        </View>
      </View>
      <View style={styles.tabsContainer}>
        <View style={styles.inlineTabs}>
          {tabs.map((currentTab) => (
            <Pressable
              onPress={() => {
                setSelectedTab(currentTab);
              }}
              style={[styles.tabs, selectedTab === currentTab && styles.activeTabs]}
              {...setTestProps({ name: `${currentTab}-Tab-Dashboard` })}
            >
              <Text>{currentTab}</Text>
            </Pressable>
          ))}
        </View>
      </View>

      {selectedTab === "Manage claims" && <ManageClaims />}
      {selectedTab === "Manage service provider" && <UpdateServiceProviderStatus />}
    </SafeAreaView>
  );
};
