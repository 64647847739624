// Copyright 2022 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import { Button } from "../../components/Button";
import { ClaimAlreadyReviewedScreen } from "./ClaimAlreadyReviewedScreen";
import { ErrorMessage, Formik } from "formik";
import { Image, Modal, Platform, Pressable, SafeAreaView, StyleSheet, Text, View } from "react-native";
import { Loading } from "../../components/Loading";
import { None } from "../../utils/None";
import { PRIMARY_THEME_COLOR } from "../../constants";
import { RejectModal } from "./RejectModal";
import { Some } from "../../utils/Some";
import { Table } from "../../components/Table/Table";
import { TextInput } from "../../components/TextInput";
import { UserType, useUserStore } from "./../../store/userStore";
import { formatCurrency } from "../../utils/FormatHelper";
import { getServiceCategoryLabel } from "../../utils/ServiceCategory";
import { openURL } from "../../utils/openURL";
import { setTestProps } from "../../utils/propHelper";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useToast } from "react-native-toast-notifications";
import CloseIcon from "../../../assets/icons/close_l_action.png";
import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import type { FormikProps } from "formik";
import type { GetClaimResponse } from "../../__generated__/api/ReviewClaimRoute";
import type { GetServiceProviderResponse } from "../../__generated__/api/ServiceProviderRoute";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParamsAll } from "../../Routes";
import type { RouteProp } from "@react-navigation/native";
import type { TableColumn } from "../../components/Table/types";

type FormValue = {
  readonly serviceProviderNumber: string;
};

export const ReviewClaimScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { errorHandler } = useDefaultErrorHandler();
  const { reviewClaimClient, unauthorizedServiceProviderClient } = useApi();
  const { isDesktopOrLarger } = useDeviceSize();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showSendForExternalReviewModal, setShowSendForExternalReviewModal] = useState(false);
  const [reviewClaimDetails, setReviewClaimDetails] = useState<GetClaimResponse>();
  const [reviewClaimStatusMessage, setReviewClaimStatusMessage] = useState<string>();
  const [reviewerName, setReviewerName] = useState<string>();
  const [showReviewerNameError, setShowReviewerNameError] = useState(false);
  const user = useUserStore((_) => _.user);
  const isServiceProviderSignedIn = Some(user) && user.type === UserType.SERVICE_PROVIDER;

  const {
    params: { token },
  } = useRoute<RouteProp<RouteParamsAll, "ReviewClaim">>();

  const navigation = useNavigation<NativeStackNavigationProp<RouteParamsAll>>();
  const [serviceProvider, setServiceProvider] = useState<GetServiceProviderResponse>();
  const toast = useToast();

  useEffect(() => {
    const getReviewClaimDetails = async () => {
      try {
        setIsLoading(true);
        const response = await reviewClaimClient.getClaim(token);
        if (response.success) {
          setReviewClaimDetails(response.data);
          setIsLoading(false);
        } else {
          setReviewClaimStatusMessage(response.message);
        }
      } catch (error: unknown) {
        errorHandler(error);
      }
    };

    getReviewClaimDetails();
  }, [errorHandler, reviewClaimClient, token]);

  const approveClaim = async () => {
    try {
      setIsLoading(true);

      if (None(reviewerName)) {
        throw new Error("Somehow got to approve button while reviewerName was undefined");
      }

      const response = await reviewClaimClient.approveClaim(token, {
        reviewerName,
      });

      if (response.success) {
        if (isServiceProviderSignedIn) {
          navigation.replace("ServiceProviderDashboard");
          toast.show(
            <Text {...setTestProps({ name: "claimApprovedMessage-ReviewClaimScreen" })}>Claim approved</Text>,
            {
              placement: "bottom",
              type: "success",
            },
          );
        } else {
          navigation.replace("ClaimApproved");
        }
      } else {
        setReviewClaimStatusMessage(response.message);
      }
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  const rejectClaim = async ({ description, reason }: { readonly reason: string; readonly description?: string }) => {
    try {
      setIsLoading(true);

      if (None(reviewerName)) {
        throw new Error("Somehow got to reject button while reviewerName was undefined");
      }

      const response = await reviewClaimClient.rejectClaim(token, {
        notes: description,
        reason,
        reviewerName,
      });

      if (response.success) {
        if (isServiceProviderSignedIn) {
          navigation.replace("ServiceProviderDashboard");
          toast.show(
            <Text {...setTestProps({ name: "claimRejectedMessage-ReviewClaimScreen" })}>Claim rejected</Text>,
            {
              placement: "bottom",
              type: "warning",
            },
          );
        } else {
          navigation.replace("ClaimRejected");
        }
      } else {
        setReviewClaimStatusMessage(response.message);
      }
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  const sendForExternalReview = async () => {
    try {
      setIsLoading(true);

      if (None(reviewerName)) {
        throw new Error("Somehow got to sendForExternalReview while reviewerName was undefined");
      }

      const response = await reviewClaimClient.sendForExternalReview(token, {
        reviewerName,
      });

      if (response.success) {
        navigation.replace("SentForExternalReview");
      } else {
        setReviewClaimStatusMessage(response.message);
      }
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  const assignServiceProvider = async ({ serviceProviderNumber }: Readonly<FormValue>) => {
    try {
      setIsLoading(true);
      const response = await reviewClaimClient.assignServiceProvider(token, {
        serviceProviderNumber,
      });
      setIsLoading(false);
      if (response.success) {
        toast.show(
          <Text {...setTestProps({ name: "successMessage-ReviewClaimScreen" })}>
            Successfully assigned service provider to the claim
          </Text>,
          {
            duration: 3000,
            type: "success",
          },
        );
      } else {
        toast.show(response.message, { duration: 3000, type: "danger" });
      }
      navigation.replace("ReviewClaim", { token });
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  const errorText = (error: string) => (
    <View style={{ paddingVertical: 8 }}>
      <Text
        style={{ color: "#D03931", fontSize: 12 }}
        {...setTestProps({ name: `${error}-ErrorMessage-ReviewClaimScreen` })}
      >
        {error}
      </Text>
    </View>
  );

  const validationSchema = Yup.object().shape({
    serviceProviderNumber: Yup.string()
      .trim()
      .required("Please enter a service provider number")
      .test("is-valid-number", "Please enter a valid service provider number", async (value) => {
        if (Some(value) && /^[0-9]{6}$/u.test(value)) {
          if (Some(serviceProvider) && serviceProvider.number === value) {
            return true;
          }

          try {
            const response = await unauthorizedServiceProviderClient.getServiceProviderForReviewClaim(value, token);

            if (response.success) {
              setServiceProvider(response.data);

              return true;
            }
          } catch {}
        }

        setServiceProvider(undefined);

        return false;
      }),
  });

  const responsiveFlex = isDesktopOrLarger ? "row" : "column";

  const styles = StyleSheet.create({
    assignServiceProvider: {
      flexDirection: "row",
      paddingVertical: 16,
    },
    centeredView: {
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, 0.5)",
      flex: 1,
      justifyContent: "center",
    },
    contentWrapper: {
      backgroundColor: "#FFFFFF",
      marginTop: isDesktopOrLarger ? 40 : 0,
      paddingHorizontal: 40,
      paddingVertical: 24,
      width: isDesktopOrLarger ? "80%" : "100%",
    },
    footer: {
      backgroundColor: "#FFFFFF",
      borderTopColor: "#DFE1E6",
      borderTopWidth: 1,
      bottom: 0,
      flexDirection: responsiveFlex,
      justifyContent: "space-between",
      paddingHorizontal: 32,
      paddingVertical: 16,
      // @ts-expect-error Position 'fixed' is acceptable for the web
      position: isDesktopOrLarger ? "fixed" : "relative",
      width: "100%",
    },
    header: {
      alignItems: "center",
      backgroundColor: PRIMARY_THEME_COLOR,
      flexDirection: "row",
      justifyContent: "center",
      paddingHorizontal: 40,
      paddingVertical: 24,
      width: "100%",
    },
    headerText: {
      color: "#FFFFFF",
      fontSize: 20,
      fontWeight: "600",
    },
    infoIconWrapper: {
      marginLeft: isDesktopOrLarger ? 8 : 10,
      marginRight: isDesktopOrLarger ? 0 : 25,
    },
    innerContainer: {
      alignItems: "center",
      backgroundColor: "#F2F2F2",
      width: "100%",
    },
    inputStyle: {
      borderColor: "rgba(193,199,208,1)",
      borderRadius: 4,
      borderWidth: 1,
      paddingHorizontal: 12,
      paddingVertical: 10,
    },
    modalView: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      borderRadius: 4,
      width: isDesktopOrLarger ? "45%" : "80%",
    },
    nonAceServiceProvider: {
      backgroundColor: "#f9f8f8",
      borderBottomColor: "#C1C7D0",
      borderRadius: 4,
      flexDirection: "column",
      padding: 20,
    },
    row: {
      borderBottomColor: "#C1C7D0",
      borderBottomWidth: 1,
      flexDirection: "row",
      paddingVertical: 16,
    },
    serviceProviderText: {
      fontSize: 12,
      paddingVertical: 8,
    },
    tooltip: {
      backgroundColor: "#007A98",
      borderColor: "#DFE1E6",
      borderRadius: 4,
      borderWidth: 1,
      bottom: isDesktopOrLarger ? 22 : 25,
      elevation: 20,
      paddingHorizontal: 16,
      paddingVertical: 16,
      position: "absolute",
      ...(isDesktopOrLarger ? { right: 15 } : { left: 10 }),
      shadowColor: "rgba(0, 0, 0, 0.3)",
      shadowRadius: 4,
      width: 220,
    },
  });

  const formField = ({ handleBlur, handleChange, handleSubmit, values }: FormikProps<FormValue>) => (
    <>
      <View style={styles.assignServiceProvider}>
        <View style={{ flex: 1, justifyContent: "flex-start" }}>
          <Text>Assign Service Provider</Text>
        </View>
        <View style={{ flex: 2, flexDirection: "row" }}>
          <View style={{ flex: 2 }}>
            <TextInput
              onChangeText={handleChange("serviceProviderNumber")}
              onKeyPress={handleBlur("serviceProviderNumber")}
              placeholder="000000"
              testID="serviceProviderNumberInputField-ReviewClaimScreen"
              value={values.serviceProviderNumber}
            />

            <ErrorMessage name="serviceProviderNumber">{errorText}</ErrorMessage>

            {Some(serviceProvider) && (
              <Text style={styles.serviceProviderText}>
                This is the provider number for:
                <Text
                  style={{ fontWeight: "700" }}
                  {...setTestProps({ name: "serviceProviderName-ReviewClaimScreen" })}
                >
                  {serviceProvider.name}
                </Text>
              </Text>
            )}
          </View>
          <View style={{ flex: 1, marginLeft: 20 }}>
            <Button disabled={isLoading} onPress={handleSubmit} testID="applyButton-ReviewClaimScreen" text="Apply" />
          </View>
        </View>
      </View>
    </>
  );

  // Table columns
  const columns: readonly TableColumn[] = [
    { key: "name", title: "Student Name", width: "auto" },
    { key: "status", title: "Status", width: "auto" },
    { key: "submittedDate", title: "Submitted Date", width: "auto" },
    { key: "serviceProviderNumber", title: "Service Provider", width: "auto" },
    { key: "amount", title: "Amount", width: "auto" },
    { key: "internalReviewLink", sortable: false, title: "Review Link", width: "auto" },
  ];

  // Table data
  const relevantClaims = useMemo(
    () =>
      reviewClaimDetails?.relevantClaims
        .filter((_) => _.uuid !== reviewClaimDetails.claim.uuid)
        .map((childClaim, index) => ({
          amount: childClaim.amount,
          id: childClaim.uuid,
          internalReviewLink: (
            <View style={{ paddingRight: 24 }}>
              <Button
                onPress={() => {
                  // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
                  Platform.OS === "web"
                    ? window.open(childClaim.internalReviewLink)
                    : openURL(childClaim.internalReviewLink);
                }}
                size="small"
                testID={`${index}-ViewLinkButton-ReviewClaimScreen`}
                text="View"
                type="secondary"
              />
            </View>
          ),
          name: `${childClaim.child.firstName} ${childClaim.child.lastName}`,
          rowId: index,
          serviceProviderNumber: Some(childClaim.serviceProviderNumber) ? childClaim.serviceProviderNumber : "N/A",
          status: childClaim.status,
          submittedDate: dayjs(childClaim.createdAt).format("MM/DD/YYYY"),
        })),
    [reviewClaimDetails?.relevantClaims, reviewClaimDetails?.claim.uuid],
  );

  if (Some(reviewClaimStatusMessage)) {
    return <ClaimAlreadyReviewedScreen message={reviewClaimStatusMessage} />;
  }

  if (isLoading || None(reviewClaimDetails)) {
    return <Loading />;
  }

  const {
    amount,
    category,
    child: { firstName, lastName },
    dateOfService,
    description,
    files,
    nonAceServiceProvider,
    parent,
    payTo,
    physicalGood,
    serviceProvider: qesp,
    status,
  } = reviewClaimDetails.claim;
  const { available, pending } = reviewClaimDetails.amountInfo;
  const unspentAmount = available + pending;

  const isDeletedServiceProvider = None(nonAceServiceProvider) && None(qesp);
  const isReadOnlyReview = qesp?.status === "Inactive";
  const isAmountInfoInvalid = available < 0 || unspentAmount <= 0 || amount > unspentAmount;
  const preventReviewActions = isDeletedServiceProvider || isReadOnlyReview || isAmountInfoInvalid;

  return (
    <SafeAreaView
      style={{
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        flex: 1,
      }}
    >
      <View style={styles.header}>
        <Text style={styles.headerText} {...setTestProps({ name: "header-ReviewClaimScreen" })}>
          Review claim
        </Text>
      </View>

      <View style={styles.innerContainer}>
        <View style={styles.contentWrapper}>
          {reviewClaimDetails.reviewType === "internal" && qesp?.status === "Inactive" && (
            <View style={{ paddingVertical: 16 }}>
              <Text
                style={{ color: "#FF5340", fontSize: 20, fontWeight: "600" }}
                {...setTestProps({ name: "enableQespToActiveMessage-ReviewClaimScreen" })}
              >
                WARNING- This QESP is INACTIVE. Please turn QESP to ACTIVE to enable review actions for this claim.
              </Text>
            </View>
          )}
          {reviewClaimDetails.reviewType === "external" && qesp?.status === "Inactive" && (
            <View style={{ paddingVertical: 16 }}>
              <Text
                style={{ color: "#FF5340", fontSize: 20, fontWeight: "600" }}
                {...setTestProps({ name: "inActiveQespWarningMessage-ReviewClaimScreen" })}
              >
                WARNING- This QESP is INACTIVE.
              </Text>
            </View>
          )}
          {reviewClaimDetails.reviewType === "internal" && (
            <View style={{ paddingVertical: 16 }}>
              <Text
                style={{ color: "#FF5340", fontSize: 20, fontWeight: "600" }}
                {...setTestProps({ name: "internalLinkMessage-ReviewClaimScreen" })}
              >
                This is the internal link for Merit employees only
              </Text>
            </View>
          )}

          <View style={styles.row}>
            <View style={{ flex: 1 }}>
              <Text>Claim status</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "claimStatus-ReviewClaimScreen" })}>{status}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ flex: 1 }}>
              <Text>Student</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "studentFullName-ReviewClaimScreen" })}>{`${firstName} ${lastName}`}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ flex: 1 }}>
              <Text>Parent/Guardian</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "parentFullName-ReviewClaimScreen" })}>
                {parent.firstName} {parent.lastName}
              </Text>
            </View>
          </View>

          {reviewClaimDetails.reviewType === "internal" && Some(qesp?.name) && (
            <View style={styles.row}>
              <View style={{ flex: 1 }}>
                <Text>Service provider name</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text>{qesp.name}</Text>
              </View>
            </View>
          )}

          {Some(qesp?.number) && (
            <View style={styles.row}>
              <View style={{ flex: 1 }}>
                <Text>Service provider number</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text {...setTestProps({ name: "serviceProviderNumber-ReviewClaimScreen" })}>{qesp.number}</Text>
              </View>
            </View>
          )}

          {None(qesp?.number) && Some(nonAceServiceProvider) && (
            <>
              <View
                style={[
                  styles.nonAceServiceProvider,
                  {
                    borderBottomWidth: None(qesp?.number) && Some(nonAceServiceProvider) ? 0 : 1,
                  },
                ]}
              >
                <View style={styles.assignServiceProvider}>
                  <View style={{ flex: 1 }}>
                    <Text>Service provider name</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text {...setTestProps({ name: "nonAceServiceProviderName-ReviewClaimScreen" })}>
                      {nonAceServiceProvider.name}
                    </Text>
                  </View>
                </View>
                <View style={styles.assignServiceProvider}>
                  <View style={{ flex: 1 }}>
                    <Text>Service provider address</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text {...setTestProps({ name: "nonAceServiceProviderAddress-ReviewClaimScreen" })}>
                      {nonAceServiceProvider.address}
                    </Text>
                  </View>
                </View>
                <View style={styles.assignServiceProvider}>
                  <View style={{ flex: 1 }}>
                    <Text>Service provider phone number</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text {...setTestProps({ name: "nonAceServiceProviderPhoneNumber-ReviewClaimScreen" })}>
                      {nonAceServiceProvider.phoneNumber}
                    </Text>
                  </View>
                </View>
                <View style={styles.assignServiceProvider}>
                  <View style={{ flex: 1 }}>
                    <Text>Service provider email address</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text {...setTestProps({ name: "nonAceServiceProviderEmail-ReviewClaimScreen" })}>
                      {nonAceServiceProvider.email}
                    </Text>
                  </View>
                </View>
                <View style={styles.assignServiceProvider}>
                  <View style={{ flex: 1 }}>
                    <Text>Service provider website</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text {...setTestProps({ name: "nonAceServiceProviderWebsite-ReviewClaimScreen" })}>
                      {nonAceServiceProvider.website}
                    </Text>
                  </View>
                </View>
                {Some(nonAceServiceProvider.contactName) && (
                  <View style={styles.assignServiceProvider}>
                    <View style={{ flex: 1 }}>
                      <Text>Contact name (optional)</Text>
                    </View>
                    <View style={{ flex: 2 }}>
                      <Text {...setTestProps({ name: "nonAceServiceProviderContactName-ReviewClaimScreen" })}>
                        {nonAceServiceProvider.contactName}
                      </Text>
                    </View>
                  </View>
                )}
                {Some(nonAceServiceProvider.contactTitle) && (
                  <View style={styles.assignServiceProvider}>
                    <View style={{ flex: 1 }}>
                      <Text>Contact title (optional)</Text>
                    </View>
                    <View style={{ flex: 2 }}>
                      <Text {...setTestProps({ name: "nonAceServiceProviderContactTitle-ReviewClaimScreen" })}>
                        {nonAceServiceProvider.contactTitle}
                      </Text>
                    </View>
                  </View>
                )}

                {reviewClaimDetails.reviewType === "internal" && (
                  <Formik
                    initialValues={{ serviceProviderNumber: "" }}
                    onSubmit={assignServiceProvider}
                    validationSchema={validationSchema}
                  >
                    {formField}
                  </Formik>
                )}
              </View>
            </>
          )}

          <View
            style={[
              styles.row,
              {
                ...(None(qesp?.number) &&
                  Some(nonAceServiceProvider) && {
                    borderTopColor: "#C1C7D0",
                    borderTopWidth: 1,
                  }),
              },
            ]}
          >
            <View style={{ flex: 1 }}>
              <Text>Service category</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "serviceCategory-ReviewClaimScreen" })}>
                {getServiceCategoryLabel(category)}
              </Text>
            </View>
          </View>

          {Some(description) && (
            <View style={styles.row}>
              <View style={{ flex: 1 }}>
                <Text>What educational benefits did your child receive?</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text {...setTestProps({ name: "educationalBenefits-ReviewClaimScreen" })}>{description}</Text>
              </View>
            </View>
          )}

          {Some(physicalGood) && (
            <View style={styles.row}>
              <View style={{ flex: 1 }}>
                <Text>Is this for a physical good?</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text {...setTestProps({ name: "physicalGood-ReviewClaimScreen" })}>{physicalGood ? "Yes" : "No"}</Text>
              </View>
            </View>
          )}

          <View style={styles.row}>
            <View style={{ flex: 1 }}>
              <Text>Date of Service</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "serviceDate-ReviewClaimScreen" })}>
                {dayjs(dateOfService).format("MM/DD/YYYY")}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ flex: 1 }}>
              <Text>Claim amount</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "claimAmount-ReviewClaimScreen" })}>{formatCurrency(amount)}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ flex: 1 }}>
              <Text>Deliver funds to</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "payTo-ReviewClaimScreen" })}>
                {payTo === "User" ? "Student (Parent/Guardian)" : "Service Provider"}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ flex: 1 }}>
              <Text>Account/invoice/receipt number</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "receiptNumber-ReviewClaimScreen" })}>
                {files
                  .filter((_) => Some(_.receiptNumber))
                  .map((_) => _.receiptNumber)
                  .join(", ")}
              </Text>
            </View>
          </View>

          <View style={[styles.row, { borderBottomWidth: 0 }]}>
            <View style={{ flex: 1 }}>
              <Text>Receipt or Invoice</Text>
            </View>
            <View style={{ flex: 2 }}>
              {files.map((file, index) => (
                <Pressable
                  key={file.filename}
                  onPress={() => {
                    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
                    Platform.OS === "web" ? window.open(file.url) : openURL(file.url);
                  }}
                  style={{ marginBottom: 20 }}
                >
                  {file.detectedFileExt === "pdf" ? (
                    <Text style={{ color: "#006699" }}>{file.filename}</Text>
                  ) : (
                    <Image
                      source={{ uri: file.url }}
                      style={{ height: 200, width: 200 }}
                      {...setTestProps({ name: `${index}-InvoiceImage-ReviewClaimScreen` })}
                    />
                  )}
                </Pressable>
              ))}
            </View>
          </View>

          {reviewClaimDetails.reviewType === "internal" && (
            <View style={styles.row}>
              <View style={{ flex: 1 }}>
                <Text>Remaining funds</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text
                  {...setTestProps({ name: "remainingFunds-ReviewClaimScreen" })}
                  style={isAmountInfoInvalid && { color: "red" }}
                >
                  {formatCurrency(unspentAmount)}
                </Text>
              </View>
            </View>
          )}

          {reviewClaimDetails.reviewType === "internal" && (
            <>
              <View style={{ backgroundColor: "#f2f2f2", height: 5, marginVertical: 20 }} />
              <View style={{ alignItems: "center", paddingVertical: 10 }}>
                <Text
                  style={{ fontSize: 18, fontWeight: "600" }}
                  {...setTestProps({ name: "relevantClaimsTitle-ReviewClaimScreen" })}
                >
                  All relevant claims
                </Text>
              </View>
              <Table
                columns={columns}
                data={relevantClaims}
                defaultSort={{
                  direction: "Descending",
                  key: "amount",
                }}
                emptyComponent={<View />}
                render={(data, key) => {
                  switch (key) {
                    case "amount":
                      return (
                        <Text
                          style={
                            reviewClaimDetails.claim.amount === data.amount && {
                              color: "#FF5340",
                              fontWeight: "800",
                            }
                          }
                          {...setTestProps({ name: `${data.rowId}-Amount-ReviewClaimScreen` })}
                        >
                          {formatCurrency(data.amount)}
                        </Text>
                      );

                    case "internalReviewLink":
                      return <>{data[key]}</>;
                    default:
                      return (
                        <Text {...setTestProps({ name: `${data.rowId}-${key}-ReviewClaimScreen` })}>{data[key]}</Text>
                      );
                  }
                }}
              />
            </>
          )}
          {isDesktopOrLarger && <View style={{ height: 80 }} />}
        </View>
      </View>

      {reviewClaimDetails.reviewed ? (
        <View style={styles.footer}>
          <Text {...setTestProps({ name: "reviewedClaimMessage-ReviewClaimScreen" })}>
            This claim is already reviewed
          </Text>
        </View>
      ) : (
        <View style={styles.footer}>
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: responsiveFlex }}>
              <View style={{ flex: 5, paddingBottom: isDesktopOrLarger ? 0 : 12 }}>
                <Text {...setTestProps({ name: "approveOrRejectHelpText-ReviewClaimScreen" })}>
                  By entering your name you are taking responsibility for the approval or rejection of this claim and
                  its accuracy
                </Text>
              </View>
              <View style={{ flex: 5 }}>
                <TextInput
                  onChangeText={(text) => {
                    setReviewerName(text.trim());
                  }}
                  placeholder="Reviewer"
                  testID="reviewerNameInputField-ReviewClaimScreen"
                />
              </View>
            </View>
          </View>

          <View style={{ flex: 1 }}>
            <View style={{ alignItems: "center", flexDirection: responsiveFlex, justifyContent: "flex-end" }}>
              {showReviewerNameError && (
                <Text
                  style={{ color: "red", fontSize: 16, marginRight: 16 }}
                  {...setTestProps({ name: "reviewerNameFillOutMessage-ReviewClaimScreen" })}
                >
                  You must fill out the reviewer name field
                </Text>
              )}

              {reviewClaimDetails.reviewType === "internal" && Some(qesp) && !qesp.isFauxServiceProvider && (
                <Button
                  disabled={preventReviewActions}
                  onPress={() => {
                    const reviewerError = None(reviewerName) || reviewerName === "";
                    setShowReviewerNameError(reviewerError);

                    if (!reviewerError) {
                      setShowSendForExternalReviewModal(true);
                    }
                  }}
                  testID="passesInternalReviewButton-ReviewClaimScreen"
                  text="Passes internal review"
                  type="secondary"
                />
              )}
              <View style={{ paddingHorizontal: 16, paddingVertical: isDesktopOrLarger ? 0 : 8 }}>
                <Button
                  disabled={preventReviewActions}
                  onPress={() => {
                    const reviewerError = None(reviewerName) || reviewerName === "";
                    setShowReviewerNameError(reviewerError);

                    if (!reviewerError) {
                      setShowRejectModal(true);
                    }
                  }}
                  testID="rejectButton-ReviewClaimScreen"
                  text="Reject"
                  type="destructive"
                />
              </View>
              {(reviewClaimDetails.reviewType === "external" ||
                (reviewClaimDetails.reviewType === "internal" &&
                  (qesp?.isFauxServiceProvider ?? Some(nonAceServiceProvider)))) && (
                <Button
                  disabled={preventReviewActions}
                  onPress={() => {
                    const reviewerError = None(reviewerName) || reviewerName === "";
                    setShowReviewerNameError(reviewerError);

                    if (!reviewerError) {
                      setShowApproveModal(true);
                    }
                  }}
                  testID="approveButton-ReviewClaimScreen"
                  text="Approve"
                />
              )}
            </View>
          </View>
        </View>
      )}

      <Modal
        animationType="fade"
        onRequestClose={() => {
          setShowRejectModal(false);
        }}
        transparent
        visible={showRejectModal}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={{ paddingHorizontal: 32, paddingVertical: 24, width: "100%" }}>
              <RejectModal
                onCancel={() => {
                  setShowRejectModal(false);
                }}
                onReject={(reason) => {
                  rejectClaim(reason);
                  setShowRejectModal(false);
                }}
              />
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="fade"
        onRequestClose={() => {
          setShowApproveModal(false);
        }}
        transparent
        visible={showApproveModal}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={{ paddingHorizontal: 32, paddingVertical: 24, width: "100%" }}>
              <View style={{ flexDirection: "row", justifyContent: "space-between", paddingVertical: 12 }}>
                <Text
                  style={{ fontSize: 20, fontWeight: "600" }}
                  {...setTestProps({ name: "approveModalTitle-ReviewClaimScreen" })}
                >
                  Approve this claim
                </Text>
                <Pressable
                  onPress={() => {
                    setShowApproveModal(false);
                  }}
                >
                  <Image
                    accessibilityLabel="Close"
                    accessible
                    resizeMode="contain"
                    source={CloseIcon}
                    style={{ height: 14, width: 14 }}
                    {...setTestProps({ name: "approveModalCloseIcon-ReviewClaimScreen" })}
                  />
                </Pressable>
              </View>
              <>
                <Text {...setTestProps({ name: "approveModalConfirmMessage-ReviewClaimScreen" })}>
                  Are you sure you want to approve this claim?
                </Text>
                <View style={{ height: 24 }} />
                <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
                  <Button
                    onPress={() => {
                      setShowApproveModal(false);
                    }}
                    testID="approveModalCancelButton-ReviewClaimScreen"
                    text="Cancel"
                    type="secondary"
                  />
                  <View style={{ width: 8 }} />
                  <Button
                    onPress={() => {
                      approveClaim();
                    }}
                    testID="approveModalApproveButton-ReviewClaimScreen"
                    text="Approve"
                  />
                </View>
              </>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="fade"
        onRequestClose={() => {
          setShowSendForExternalReviewModal(false);
        }}
        transparent
        visible={showSendForExternalReviewModal}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={{ paddingHorizontal: 32, paddingVertical: 24, width: "100%" }}>
              <View style={{ flexDirection: "row", justifyContent: "space-between", paddingVertical: 12 }}>
                <Text
                  {...setTestProps({ name: "externalModalTitle-ReviewClaimScreen" })}
                  style={{ fontSize: 20, fontWeight: "600" }}
                >
                  Send this claim for vendor approval review
                </Text>
                <Pressable
                  onPress={() => {
                    setShowSendForExternalReviewModal(false);
                  }}
                >
                  <Image
                    accessibilityLabel="Close"
                    accessible
                    resizeMode="contain"
                    source={CloseIcon}
                    style={{ height: 14, width: 14 }}
                    {...setTestProps({ name: "externalModalCloseIcon-ReviewClaimScreen" })}
                  />
                </Pressable>
              </View>
              <>
                <Text {...setTestProps({ name: "externalModalConfirmMessage-ReviewClaimScreen" })}>
                  Are you sure you want to send this claim for vendor approval review?
                </Text>
                <View style={{ height: 24 }} />
                <View
                  style={{
                    alignItems: "center",
                    flexDirection: isDesktopOrLarger ? "row" : "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onPress={() => {
                      setShowSendForExternalReviewModal(false);
                    }}
                    testID="externalModalCancelButton-ReviewClaimScreen"
                    text="Cancel"
                    type="secondary"
                  />
                  <View style={{ paddingVertical: isDesktopOrLarger ? 0 : 4, width: 8 }} />
                  <Button
                    onPress={() => {
                      sendForExternalReview();
                    }}
                    testID="externalModalReviewButton-ReviewClaimScreen"
                    text="Send for vendor approval review"
                  />
                </View>
              </>
            </View>
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
};
