// Copyright 2022 Merit International Inc. All Rights Reserved.

import { LanguageCode } from "../locales";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";

export type LocaleState = {
  readonly locale: LanguageCode;
  readonly setLocale: (locale: LanguageCode) => void;
  readonly supportedLocales: readonly LanguageCode[];
};

export const useLocaleStore = create<LocaleState>()(
  persist(
    (set) => ({
      locale: LanguageCode.English,
      setLocale: (locale: LanguageCode) => {
        set((state) => ({ ...state, locale }));
      },
      supportedLocales: Object.values(LanguageCode).filter((languageCode) => typeof languageCode === "string"),
    }),
    {
      name: "locale-storage",
      storage: createJSONStorage(() => AsyncStorage),
    },
  ),
);
