// Copyright 2022 Merit International Inc. All Rights Reserved

import { BankAccountForm } from "../../components/BankAccountForm";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { Image, Pressable, SafeAreaView, StatusBar, StyleSheet, Text, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { None } from "../../utils/None";
import { PRIMARY_THEME_COLOR } from "../../constants";
import { UserType, useUserStore } from "../../store/userStore";
import { setTestProps } from "../../utils/propHelper";
import { useApi } from "../../services/useApi";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "../../hooks/useTranslation";
import CloseIconSecondary from "../../../assets/icons/close_m_action_secondary.png";
import React, { useState } from "react";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParamsAll } from "../../Routes";

export const LinkBankAccountScreen = () => {
  const { setUser, user } = useUserStore();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParamsAll>>();
  const { userClient } = useApi();
  const { isDesktopOrLarger } = useDeviceSize();
  const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false);
  const i18n = useTranslation();

  if (None(user)) {
    throw new Error("Could not load user details");
  }

  if (user.type !== UserType.PARENT) {
    throw new Error("Logged in user is not a parent");
  }

  const styles = StyleSheet.create({
    header: {
      alignItems: "center",
      backgroundColor: PRIMARY_THEME_COLOR,
      borderBottomColor: "#CCCCCC",
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 40,
      paddingVertical: 24,
      width: "100%",
      zIndex: 99,
    },
    headerText: {
      color: "#FFFFFF",
      fontSize: 18,
      fontWeight: "600",
    },
    wrapper: {
      backgroundColor: isDesktopOrLarger ? "#F2F2F2" : "#FFFFFF",
      flex: 1,
    },
  });

  const updateUserBankDetails = async () => {
    const updatedUser = await userClient.setHasProvidedBankDetailsForParent();
    setUser(updatedUser);
    navigation.navigate("Dashboard");
  };

  return (
    <SafeAreaView style={styles.wrapper}>
      <StatusBar backgroundColor={PRIMARY_THEME_COLOR} />

      <View style={styles.header}>
        {isDesktopOrLarger && <View style={{ height: 20, width: 20 }} />}
        <Text style={styles.headerText} {...setTestProps({ name: "header-LinkBankAccountScreen" })}>
          {i18n.t("LinkBankAccountScreen.title")}
        </Text>
        <Pressable
          onPress={() => {
            if (user.hasHealthyBankConnection) {
              navigation.navigate("Dashboard");
            } else {
              setShowCloseConfirmationModal(true);
            }
          }}
        >
          <Image
            accessibilityLabel="Close"
            accessible
            resizeMode="contain"
            source={CloseIconSecondary}
            style={{ height: 20, width: 20 }}
            {...setTestProps({ name: "closeIcon-LinkBankAccountScreen" })}
          />
        </Pressable>
      </View>

      <KeyboardAwareScrollView keyboardShouldPersistTaps="handled" showsVerticalScrollIndicator={false}>
        <BankAccountForm
          onSuccess={() => {
            updateUserBankDetails();
          }}
        />
      </KeyboardAwareScrollView>
      <ConfirmationModal
        isVisible={showCloseConfirmationModal}
        onCancel={() => {
          setShowCloseConfirmationModal(false);
        }}
        onConfirm={() => {
          if (navigation.canGoBack()) {
            navigation.goBack();
          } else {
            navigation.navigate("Dashboard");
          }
        }}
        text={i18n.t("LinkBankAccountScreen.exitConfirmation")}
      />
    </SafeAreaView>
  );
};
