import { Button } from "./Button";
import { Image, StyleSheet, Text, View } from "react-native";
import { Loading } from "./Loading";
import { None } from "../utils/None";
import { ROUTES } from "../Routes";
import { Table } from "./Table";
import { formatCurrency } from "../utils/FormatHelper";
import { openURL } from "../utils/openURL";
import { setTestProps } from "../utils/propHelper";
import { useApi } from "../services/useApi";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";
import { useEffect, useMemo, useState } from "react";
import { useUserStore } from "../store/userStore";
import DocumentWithSearchmark from "../../assets/images/document-with-searchmark.png";
import TableLoader from "../../assets/images/table-loader.png";
import dayjs from "dayjs";
import type { GetHistoryClaimsResponse } from "../__generated__/api/ServiceProviderRoute";
import type { TableColumn } from "./Table/types";

const styles = StyleSheet.create({
  boldText: {
    fontFamily: "Proxima Nova",
    fontSize: 28,
    fontWeight: "600",
    paddingVertical: 40,
  },
  centeredView: {
    alignItems: "center",
    height: "auto",
    justifyContent: "center",
    padding: 40,
    width: "100%",
  },
  content: {
    height: "100%",
    width: "100%",
  },
});

type Props = {
  readonly isSelected: boolean;
};

export const ServiceProviderHistoryClaims = ({ isSelected }: Props) => {
  const { serviceProviderClient } = useApi();
  const [historyClaims, setHistoryClaims] = useState<GetHistoryClaimsResponse["historyClaims"]>();
  const { errorHandler } = useDefaultErrorHandler();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { selectedOrg } = useUserStore();

  useEffect(() => {
    const getHistoryClaims = async () => {
      try {
        if (None(selectedOrg)) {
          throw new Error("Service provider cannot be in this state without selecting an org");
        }
        setIsDataLoading(true);
        const response = await serviceProviderClient.getHistoryClaims(selectedOrg.id);
        setHistoryClaims(response.historyClaims);
        setIsDataLoading(false);
      } catch (error: unknown) {
        errorHandler(error);
      }
    };
    if (isSelected) {
      getHistoryClaims();
    }
  }, [errorHandler, isSelected, selectedOrg, serviceProviderClient]);

  // Table columns
  const columns: readonly TableColumn[] = [
    { key: "childName", title: "Student name", width: "auto" },
    { key: "parentName", title: "Parent name", width: "auto" },
    { key: "submittedDate", title: "Submit date", width: 130 },
    { key: "id", title: "Claim ID", width: 100 },
    { key: "amount", title: "Claim amount", width: 140 },
    { key: "deliverFundsTo", title: "Deliver funds to", width: 160 },
    { key: "status", title: "Status", width: 100 },
    { key: "reviewedAt", title: "Date approved/rejected", width: 210 },
    { key: "reviewLink", sortable: false, title: "Review", width: 100 },
  ];

  // Table data
  const historyClaimsTableData = useMemo(
    () =>
      historyClaims?.map((claim) => ({
        amount: claim.amount,
        childName: `${claim.child.firstName} ${claim.child.lastName}`,
        createdAt: claim.createdAt,
        deliverFundsTo: claim.deliverFundsTo === "User" ? "Parent/Guardian" : "Service provider",
        id: claim.id,
        parentName: `${claim.parent.firstName} ${claim.parent.lastName}`,
        reviewLink: (
          <View style={{ paddingRight: 24, width: 120 }}>
            <Button
              customContent={<Text style={{ fontWeight: "600" }}>View</Text>}
              onPress={() => {
                openURL(`${ROUTES.ServiceProviderClaimScreen}?claimId=${claim.id}`);
              }}
              size="small"
              testID={`${claim.id}-ViewLinkButton-ServiceProviderHistoryClaims`}
              type="secondary"
            />
          </View>
        ),
        reviewedAt: dayjs(claim.reviewedAt).format("MM/DD/YYYY"),
        status: claim.status,
        submittedDate: dayjs(claim.createdAt).format("MM/DD/YYYY"),
      })),
    [historyClaims],
  );

  if (!isSelected) {
    return null;
  }

  if (isDataLoading || None(historyClaims)) {
    return (
      <View style={{ paddingVertical: 50 }}>
        <Loading />
      </View>
    );
  }

  return (
    <View style={styles.content}>
      {historyClaims.length === 0 ? (
        <View style={styles.centeredView}>
          <Image
            accessibilityLabel="document with searchmark"
            accessible
            source={DocumentWithSearchmark}
            style={{ height: 95, paddingTop: 20, width: 95 }}
          />

          <Text
            style={styles.boldText}
            {...setTestProps({ name: "noHistoryClaimsMessage-ServiceProviderHistoryClaims" })}
          >
            You have no history claims
          </Text>
          <Image
            accessibilityLabel="table loader"
            accessible
            source={TableLoader}
            style={{ height: 184, width: 582 }}
          />
        </View>
      ) : (
        <Table
          columns={columns}
          data={historyClaimsTableData}
          defaultSort={{ direction: "Descending", key: "createdAt" }}
          emptyComponent={
            <View style={styles.centeredView}>
              <Text>You have no history claims</Text>
            </View>
          }
          render={(data, key) => {
            switch (key) {
              case "amount":
                return (
                  <Text {...setTestProps({ name: `${data.id}-${key}-ServiceProviderHistoryClaims` })}>
                    {formatCurrency(data.amount)}
                  </Text>
                );

              default:
                return (
                  <Text {...setTestProps({ name: `${data.id}-${key}-ServiceProviderHistoryClaims` })}>{data[key]}</Text>
                );
            }
          }}
        />
      )}
    </View>
  );
};
