import { App } from "./App";
import { registerRootComponent } from "expo";
import { config } from "./src/config/config";
import { initDatadog } from "./src/utils/datadog";

if (process.env.NODE_ENV == "development" ? false : config.datadog.enabled) {
  initDatadog({
    applicationId: config.datadog.applicationId,
    clientToken: config.datadog.clientToken,
    environment: config.datadog.environment,
    serviceName: "kansas-app",
  });
}

registerRootComponent(App);
