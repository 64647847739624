import { Button } from "../../components/Button";
import { ExternalLink } from "../../components/ExternalLink";
import { HELP_EMAIL_ADDRESS } from "../../constants";
import { Image, StyleSheet, Text, View } from "react-native";
import { useLogout } from "../../hooks/useLogout";
import Lock from "../../../assets/icons/lock.png";
import React from "react";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: "#F4F5F7",
    flex: 1,
  },
  header: {
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 24,
    paddingVertical: 24,
  },
  headerText: {
    color: "#000000",
    fontSize: 20,
    fontWeight: "600",
  },
});

export function NotEligible() {
  const { logout } = useLogout();

  return (
    <View>
      <View style={styles.header}>
        <Text style={styles.headerText}>KEEP Claims</Text>
        <Button
          onPress={() => {
            logout();
          }}
          size="small"
          text="Logout"
          type="secondary"
        />
      </View>
      <View style={styles.container}>
        <View style={{ width: "30%" }}>
          <View style={{ alignItems: "center", paddingVertical: 40 }}>
            <Image source={Lock} style={{ height: 110, width: 110 }} />
          </View>
          <View style={{ paddingVertical: 16 }}>
            <Text style={{ fontSize: 28, fontWeight: "600" }}>
              It looks like you aren't registered as a Merit CS in KEEP
            </Text>
          </View>
          <Text style={{ lineHeight: 24 }}>
            If you think this is a mistake, please reach out to{" "}
            <ExternalLink
              text={HELP_EMAIL_ADDRESS}
              textStyle={{ lineHeight: 24 }}
              url={`"mailto:${HELP_EMAIL_ADDRESS}`}
            />
          </Text>
        </View>
      </View>
    </View>
  );
}
