// Copyright 2022 Merit International Inc. All Rights Reserved

import { Image, SafeAreaView, StyleSheet, Text, View } from "react-native";
import { PRIMARY_THEME_COLOR } from "../constants";
import { UnreachableCaseError } from "../utils/UnreachableCaseError";
import { setTestProps } from "../utils/propHelper";
import BankSymbol from "../../assets/images/bank-symbol.png";
import DocumentWithCheckmark from "../../assets/images/document-with-checkmark.png";
import React from "react";

type Props = {
  readonly heading: string;
  readonly image: "bank" | "document";
  readonly text: string;
};

const styles = StyleSheet.create({
  header: {
    alignItems: "center",
    backgroundColor: PRIMARY_THEME_COLOR,
    borderBottomWidth: 1,
    flexDirection: "row",
    justifyContent: "center",
    paddingHorizontal: 40,
    paddingVertical: 24,
    width: "100%",
  },
  headerText: {
    color: "#FFFFFF",
    fontSize: 20,
    fontWeight: "600",
  },
});

export const GenericInfoScreen = ({ heading, image, text }: Props) => {
  const getImage = () => {
    switch (image) {
      case "document":
        return (
          <Image
            accessibilityLabel="document with checkmark"
            accessible
            source={DocumentWithCheckmark}
            style={{ height: 120, width: 120 }}
          />
        );

      case "bank":
        return <Image accessibilityLabel="" source={BankSymbol} style={{ height: 120, width: 120 }} />;

      default:
        throw new UnreachableCaseError(image);
    }
  };

  return (
    <SafeAreaView
      style={{
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        flex: 1,
      }}
    >
      <View style={styles.header}>
        <Text style={styles.headerText}>{heading}</Text>
      </View>

      <View style={{ alignItems: "center", paddingVertical: 40, width: "30%" }}>
        {getImage()}

        <View style={{ paddingVertical: 40 }}>
          <Text style={{ fontSize: 28, fontWeight: "600" }} {...setTestProps({ name: "message-GenericInfoScreen" })}>
            {text}
          </Text>
        </View>
      </View>
    </SafeAreaView>
  );
};
