// Copyright 2024 Merit International Inc. All Rights Reserved

import { Button } from "../Button";
import { Image, Modal, Pressable, StyleSheet, Text, View } from "react-native";
import { Loading } from "../Loading";
import { None } from "../../utils/None";
import { NotEligible } from "../../screens/ServiceProvider/NotEligible";
import { Select } from "../Select";
import { Some } from "../../utils/Some";
import { setTestProps } from "../../utils/propHelper";
import { showToast } from "../../utils/showToast";
import { useApi } from "../../services/useApi";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useState } from "react";
import { useUserStore } from "../../store/userStore";
import CloseIcon from "../../../assets/icons/close_l_action.png";
import type { SelectedOrg as ServiceProvider } from "../../store/userStore";

type Props = {
  readonly visible: boolean;
  readonly serviceProviders: readonly ServiceProvider[] | undefined;
  readonly onClose: () => void;
};

export const SelectOrgModal = ({ onClose, serviceProviders, visible }: Props) => {
  const { isDesktopOrLarger } = useDeviceSize();
  const { setSelectedOrg } = useUserStore();
  const setUser = useUserStore((_) => _.setUser);
  const { loginClient } = useApi();

  const [selectedOption, setSelectedOption] = useState<ServiceProvider>();

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      borderRadius: 4,
      paddingHorizontal: 32,
      paddingVertical: 30,
      width: isDesktopOrLarger ? "50%" : "95%",
    },
    footer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: 25,
      width: "100%",
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingVertical: 12,
      width: "100%",
    },
    headerText: {
      fontSize: 20,
      fontWeight: "600",
    },
    label: {
      display: "flex",
      flexDirection: "row",
      fontSize: 14,
      justifyContent: "flex-start",
      paddingBottom: 5,
      paddingTop: 20,
      width: "100%",
    },
    selectOrgDropdown: {
      width: "100%",
      zIndex: 10,
    },
    wrapper: {
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, 0.5)",
      flex: 1,
      justifyContent: "center",
    },
  });

  if (None(serviceProviders)) {
    return <Loading />;
  }

  if (serviceProviders.length === 0) {
    return <NotEligible />;
  }

  const serviceProviderOrgOptions = serviceProviders.map((container) => ({
    label: container.name,
    value: container.id,
  }));

  const onConfirm = async () => {
    if (Some(selectedOption)) {
      setSelectedOrg(selectedOption);

      const response = await loginClient.loginAsServiceProvider(selectedOption.id);
      if (response.success) {
        setUser(response.data);
      } else {
        showToast({ message: response.message });
      }
    } else {
      showToast({ message: "Please select org to proceed" });
    }
  };

  return (
    <Modal animationType="fade" onRequestClose={onClose} transparent visible={visible}>
      <View style={styles.wrapper}>
        <View style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.headerText} {...setTestProps({ name: "selectOrgHeader-SelectOrgModal" })}>
              Select Organisation
            </Text>

            <Pressable onPress={onClose}>
              <Image
                accessibilityLabel="Close"
                accessible
                resizeMode="contain"
                source={CloseIcon}
                style={{ height: 14, width: 14 }}
              />
            </Pressable>
          </View>

          <View style={styles.label}>
            <Text>Please select organisation</Text>
          </View>
          <View style={styles.selectOrgDropdown}>
            <Select
              onSelectOption={(option) => {
                if (Some(option)) {
                  setSelectedOption(serviceProviders.find((_) => _.id === option.value));
                }
              }}
              options={serviceProviderOrgOptions}
              testID="selectOrgDropdown-SelectOrgModal"
            />
          </View>
          <View style={styles.footer}>
            <Button
              onPress={onClose}
              text="Close"
              type="secondary"
              {...setTestProps({ name: "closeIcon-SelectOrgModal" })}
            />
            <View style={{ width: 8 }} />
            <Button
              disabled={false}
              onPress={() => {
                onConfirm();
              }}
              testID="continueButton-SelectOrgModal"
              text="Continue"
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};
