// Copyright 2022 Merit International Inc. All Rights Reserved

import { ExternalLink } from "../components/ExternalLink";
import { Footer } from "../components/Footer";
import { HELP_URL_EMAIL_NOT_RECEIVED, PRIMARY_THEME_COLOR } from "../constants";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { SwitchLanguageButton } from "../components/SwitchLanguageButton";
import { UserType } from "../store/userStore";
import { setTestProps } from "../utils/propHelper";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useLogin } from "../hooks/auth";
import { useTranslation } from "../hooks/useTranslation";
import LogoRectangle from "../../assets/images/logo-rectangle.png";
import LogoSquare from "../../assets/images/logo-square.png";
import MeritSmile from "../../assets/images/merit-smile-transparent.png";
import React from "react";
import SplashImg from "../../assets/images/splash.png";

export const SplashScreen = () => {
  const promptLogin = useLogin(UserType.PARENT);
  const { isDesktopOrLarger } = useDeviceSize();

  const styles = StyleSheet.create({
    alignItems: {
      alignItems: isDesktopOrLarger ? "flex-start" : "center",
    },
    button: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
      paddingHorizontal: 24,
      paddingVertical: isDesktopOrLarger ? 6 : 12,
    },
    buttonWrapper: {
      backgroundColor: "#00CCC0",
      borderRadius: 4,
      paddingVertical: 8,
      width: 270,
    },
    container: {
      backgroundColor: "#ffffff",
      flex: 1,
    },
    heading: {
      fontSize: isDesktopOrLarger ? 42 : 28,
      fontWeight: "500",
    },
    logo: {
      ...(isDesktopOrLarger
        ? {
            height: 48,
            width: 160,
          }
        : {
            height: 140,
            width: 140,
          }),
    },
    section: {
      alignItems: "center",
      justifyContent: "center",
    },
    textAlign: {
      textAlign: isDesktopOrLarger ? "left" : "center",
    },
  });

  const i18n = useTranslation();

  return (
    <SafeAreaView style={{ backgroundColor: "#ffffff", flex: 1 }}>
      <View style={styles.container}>
        <View style={[{ flex: 1 }, isDesktopOrLarger ? { flexDirection: "row" } : styles.section]}>
          <View style={[styles.alignItems, { flex: 1, paddingTop: 72 }]}>
            <View style={{ flex: 1, paddingLeft: isDesktopOrLarger ? 84 : undefined, width: "100%" }}>
              <View style={[styles.alignItems, { flex: isDesktopOrLarger ? undefined : 1, justifyContent: "center" }]}>
                <Image
                  accessibilityLabel="KEEP logo"
                  accessible
                  resizeMode="contain"
                  source={isDesktopOrLarger ? LogoRectangle : LogoSquare}
                  style={styles.logo}
                />
              </View>
              <View style={{ flex: 1, paddingTop: isDesktopOrLarger ? 28 : 0 }}>
                <View style={{ paddingVertical: 16 }}>
                  <Text
                    style={[styles.textAlign, styles.heading]}
                    {...setTestProps({ name: "welcomeTitle-SplashScreen" })}
                  >
                    {i18n.t("SplashScreen.title")}
                  </Text>
                </View>
                <View>
                  <Text
                    style={[styles.textAlign, { color: "#0B1D37", fontSize: 16 }]}
                    {...setTestProps({ name: "loginToManageText-SplashScreen" })}
                  >
                    {i18n.t("SplashScreen.subtitle")}
                  </Text>
                </View>
                <View style={{ flex: 1, maxHeight: 60 }} />
                <View style={[styles.alignItems, { paddingVertical: 16 }]}>
                  <Pressable
                    accessibilityRole="button"
                    onPress={() => {
                      promptLogin();
                    }}
                    style={styles.buttonWrapper}
                    {...setTestProps({ name: "loginWithMeritButton-SplashScreen" })}
                  >
                    <View style={styles.button}>
                      <Image
                        accessibilityLabel="Merit smile logo"
                        resizeMode="contain"
                        source={MeritSmile}
                        style={{ height: 16, width: 44 }}
                      />
                      <View style={{ paddingHorizontal: 16 }}>
                        <Text>|</Text>
                      </View>
                      <Text style={[styles.textAlign, { flex: 1, fontSize: 16, fontWeight: "500" }]}>
                        {i18n.t("SplashScreen.login")}
                      </Text>
                    </View>
                  </Pressable>
                </View>
                <ExternalLink
                  testID="helpLink-SplashScreen"
                  text={i18n.t("SplashScreen.help")}
                  textStyle={isDesktopOrLarger ? undefined : { textAlign: "center" }}
                  url={HELP_URL_EMAIL_NOT_RECEIVED}
                />
              </View>
            </View>
            {isDesktopOrLarger && (
              <View style={{ paddingLeft: 84 }}>
                <Footer />
              </View>
            )}
          </View>
          {isDesktopOrLarger && (
            <View style={{ backgroundColor: PRIMARY_THEME_COLOR, flex: 1, justifyContent: "center" }}>
              <Image
                accessibilityLabel="Program dashboard image"
                accessible
                source={SplashImg}
                style={{ height: 640, width: "100%" }}
              />
            </View>
          )}
          {!isDesktopOrLarger && (
            <SwitchLanguageButton
              style={{ position: "absolute", right: 16, top: 8 }}
              testID="loggedOutHome"
              theme="dark"
            />
          )}
        </View>
      </View>
    </SafeAreaView>
  );
};
