// Copyright 2022 Merit International Inc. All Rights Reserved

import { None } from "./None";
import { Platform } from "react-native";
import { Some } from "./Some";
import { UnreachableCaseError } from "./UnreachableCaseError";
import Constants from "expo-constants";

type PlatformTestProps =
  | Record<string, never>
  | { readonly accessibilityLabel: string; readonly testID: string }
  | { readonly testID: string };

export const setTestProps = ({ name }: { readonly name: string | undefined }): PlatformTestProps => {
  if (Some(name)) {
    const testID = name.replace(/\s/gu, "_");
    const os = Platform.OS;

    switch (os) {
      case "android":
        if (
          None(Constants.expoConfig) ||
          None(Constants.expoConfig.android) ||
          None(Constants.expoConfig.android.package)
        ) {
          throw new Error("Failed to fetch Android package configuration");
        }

        return {
          testID: `${Constants.expoConfig.android.package}:id/${testID}`,
        };
      case "ios":
        return {
          testID,
        };
      case "web":
        return {
          testID,
        };
      case "windows":
        return {};
      case "macos":
        return {};
      default:
        throw new UnreachableCaseError(os);
    }
  }

  return {};
};
