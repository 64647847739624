import type { Translation } from "./Translation";

export const translation: Translation = {
  BankAccountForm: {
    accountNumberEmptyError: "Please enter the account number",
    accountNumberInvalidError: "Please enter valid account number. It is a numeric string of 4-17 digits",
    accountNumberLabel: "Bank account number",
    bankAccountNameEmptyError: "Please enter the name",
    bankAccountNameLabel: "Bank account name",
    bankAccountTypeEmptyError: "Please select the bank account type",
    bankAccountTypeLabel: "Bank account type",
    bankAccountTypeOptionChecking: "Checking",
    bankAccountTypeOptionSavings: "Savings",
    confirmAccountNumberEmptyError: "Please enter the same account number",
    confirmAccountNumberInvalidError: "Please enter the same account number",
    confirmAccountNumberLabel: "Confirm account number",
    routingNumberEmptyError: "Please enter the routing number",
    routingNumberInvalidError:
      "Routing number must be 9 digits. If your routing number starts with '0', enter that first",
    routingNumberLabel: "Routing number",
    submitButtonText: "Submit",
    toastSuccessMessage: "Your bank details has been submitted",
  },
  BankAlreadyLinkedScreen: {
    contentSubtitleNative: "Please reload the app",
    contentSubtitleWeb: "Please refresh the page",
    contentTitle: "Your bank account is already connected.",
    headerTitle: "Connect bank status",
  },
  ChildCard: {
    amountCardLabelAvailable: "Available",
    amountCardLabelPending: "Pending",
    amountCardLabelSpent: "Spent",
    claimAmount: "Claim amount",
    claimStatusApproved: "Approved",
    claimStatusPending: "Pending",
    claimStatusPendingExternal: "Pending",
    claimStatusRejected: "Rejected",
    claimsHistoryAmountHeader: "Claim Amount",
    claimsHistoryCollapseText: "Collapse history",
    claimsHistoryDateHeader: "Date",
    claimsHistorySeeMoreText: "See more history",
    claimsHistoryStatusHeader: "Status",
    expires: "Expires",
    newClaimButtonText: "New claim",
  },
  ConfirmationModal: {
    cancel: "No",
    confirm: "Yes",
    title: "Are you sure?",
  },
  Dashboard: {
    connectBankButtonText: "Connect bank account",
    connectBankDescription:
      "Until you have set up your bank account, you will not be able to submit receipts for reimbursement to you. You will only be able to submit invoices that pay directly to a service provider.",
    connectBankTitle: "Connect your bank account to receive reimbursements",
    helpCenter: "Help center",
    logout: "Logout",
    reconnectBankDescription:
      "Please reconnect your bank account so you can submit receipts for reimbursement to you. Otherwise, you will only be able to submit invoices that pay directly to a service provider.",
    reconnectBankTitle: "Your bank account has been disconnected",
    title: "KEEP Claims",
  },
  DatePicker: {
    placeholder: "Select date",
  },
  DocumentPicker: {
    errorDuplicateFile: "File already uploaded, please select another",
    errorFileTooLarge: "Please select a file which is below 10mb",
    errorFormatUnsupported: "Selected %{filetype} file type not supported",
    uploadButtonText: "Upload a file",
  },
  FileUploadItem: {
    documentNumberFormatError: "Please enter only alphanumeric characters",
    documentNumberLabel: "Account/invoice/receipt number (optional)",
    documentNumberPlaceholder: "Enter number",
  },
  Footer: {
    companyName: "Merit International \u00a9 2024",
    help: "Help",
    privacy: "Privacy",
    terms: "Terms",
  },
  ImagePicker: {
    errorDuplicateFile: "File already uploaded, please select another",
    errorFileTooLarge: "Please select a file which is below 10mb",
    errorFormatUnsupported: "Selected %{filetype} file type not supported",
    errorSomethingWrongImage: "Something wrong with selected image",
    uploadButtonText: "Upload a file",
  },
  LinkBankAccountScreen: {
    exitConfirmation: "If you exit before submitting, your progress will be lost",
    title: "Submit bank account details",
  },
  LoginSuccess: {
    loadingText: "Logging you in…",
  },
  NotEligibleScreen: {
    subtitle: "If you think this is a mistake, please reach out to %{externalLinkElement}",
    title: "It looks like you don't have any students in KEEP",
  },
  Select: {
    placeholder: "Choose an option…",
  },
  ServiceProvider: {
    title: "Service provider",
  },
  ServiceProviderForm: {
    addressEmptyError: "Please enter address",
    addressLabel: "Address",
    addressPlaceholder: "Address",
    contactNameLabel: "Contact name (optional)",
    contactNamePlaceholder: "Contact name",
    contactTitleLabel: "Contact title (optional)",
    contactTitlePlaceholder: "Contact title",
    emailAddressEmptyError: "Please enter email address",
    emailAddressInvalidError: "Invalid email address",
    emailAddressLabel: "Email address",
    emailAddressPlaceholder: "Email address",
    nameEmptyError: "Please enter a name",
    nameLabel: "Name",
    namePlaceholder: "Name",
    phoneNumberEmptyError: "Please enter phone number",
    phoneNumberInvalidError: "Phone number is not valid",
    phoneNumberLabel: "Phone number",
    phoneNumberPlaceholder: "Phone number",
    saveButtonText: "Save",
    websiteEmptyError: "Please enter website",
    websiteInvalidError: "Please enter a valid website",
    websiteLabel: "Website",
    websitePlaceholder: "Website",
  },
  ServiceProviderInfo: {
    addressLabel: "Address",
    contactNameLabel: "Contact name",
    contactTitleLabel: "Contact title",
    editButtonText: "Edit",
    emailAddressLabel: "Email address",
    nameLabel: "Name",
    phoneNumberLabel: "Phone number",
    removeButtonText: "Remove",
    title: "Service provider information",
    websiteLabel: "Website",
  },
  SplashScreen: {
    help: "Need help accessing your Merit account?",
    login: "Login With Merit",
    subtitle: "Login to manage your KEEP account",
    title: "Welcome to the Kansas Education Enrichment Program (KEEP)",
  },
  SubmitNewClaim: {
    amountDetail: "%{amount} remaining",
    amountEmptyError: "Please enter claim amount",
    amountInvalidError: "Please enter valid amount",
    amountLabel: "Claim amount ($)",
    amountNoFundsAvailableError: "There are no funds available",
    amountRangeError: "Please enter an amount between $0.01 and %{amount}",
    attestationCheckboxText:
      "I attest that the information I have provided in this claim is true and accurate. I further attest that services were not provided by a relative of the awardee, services were rendered by an organization that is licensed, certified, or otherwise credentialed to perform these services. I understand that if I am submitting a claim for a Service Provider that is not already pre-qualified in the KEEP Education Marketplace, that Service Provider will need to be validated by Merit in order to verify my receipt, which may increase reimbursement processing time.",
    categoryEmptyError: "Please select a service category",
    categoryLabel: "Service category",
    categoryOptionCurriculumSchoolSuppliesTechnology: "Curriculum materials / School Supplies / Technology",
    categoryOptionDayCamps: "Camps",
    categoryOptionInstrumentLessons: "Music lessons / Instrument Purchases",
    categoryOptionLanguageClasses: "Language Classes",
    categoryOptionTutoring: "Tutoring",
    dateOfServiceEmptyError: "Please select a date of service",
    dateOfServiceExpiredError:
      "The service date must not be after %{expirationDate}, as the award expires on that date",
    dateOfServiceLabel: "Date of service",
    descriptionEmptyError:
      "Please describe the skills your child learned from this service that improved their education",
    descriptionLabel: "What educational benefits did your child receive?",
    descriptionPlaceholder:
      "Please describe the skills your child learned from this service that improved their education",
    exitConfirmation: "If you exit before submitting, your progress will be lost",
    formSubmitButtonText: "Submit",
    headerTitle: "Submit a new claim",
    invoiceBelowRangeError: "Please select at least 1 invoice / receipt image",
    invoiceEmptyError: "Please select invoice / receipt image",
    invoiceUploadFilePayToSelfLabel:
      "Upload a paid receipt to be reimbursed for this purchase (5 max, supported file types: %{fileTypes})",
    invoiceUploadFilePayToVendorLabel:
      "Upload an unpaid invoice to be paid directly to the service provider (5 max, supported file types: %{fileTypes})",
    invoiceUploadFileTooltip: "Handwritten receipts can only be accepted when validated by service providers",
    invoiceUploadedFileLabel: "Uploaded file",
    invoiceUploadedFileNumberLabel: "Account/invoice/receipt number (optional)",
    invoiceUploadedFileNumberPlaceholder: "Enter number",
    payToEmptyError: "Please select a 'Deliver funds to'",
    payToLabel: "Deliver funds to",
    payToOptionSelf: "Me",
    payToOptionVendor: "Service provider",
    serviceProviderCannotBePaidDirectlyError:
      "You cannot currently submit a claim for this service provider because you have not yet connected your bank account and this service provider does not currently accept direct payments from KEEP grants",
    serviceProviderDetail: "This is the provider number for: %{serviceProviderTextElement}",
    serviceProviderInactiveError:
      "This service provider is no longer in the Marketplace. Please click the link below to enter provider information.",
    serviceProviderModalTitle: "Service Provider",
    serviceProviderNotInProgramLinkText: "Is the service provider not currently in KEEP?",
    serviceProviderNumberEmptyError: "Please enter a service provider number",
    serviceProviderNumberInvalidError: "Please enter a valid service provider number",
    serviceProviderNumberLabel: "Service provider number",
    serviceProviderRemoveConfirmation: "This action cannot be undone",
    serviceProviderTooltip:
      "To find a service provider number, visit the KEEP Marketplace at %{externalLinkElement} and search for the provider's name. The service provider number will be listed on the provider's marketplace listing.",
    studentLabel: "Student",
    toastSuccessMessage: "Your claim has been submitted",
    updateAmountForAcceptedClaim: "Update the amount for an 'Accepted’ claim",
  },
};
