// Copyright 2022 Merit International Inc. All Rights Reserved

import { DdRumReactNavigationTracking } from "@datadog/mobile-react-navigation";
import { NavigationContainer } from "@react-navigation/native";
import { ROUTES } from "./Routes";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useMeritAuth0 } from "./hooks/auth";
import { useNavigationMap } from "./hooks/useNavigationMap";
import { useRef } from "react";
import { useUserStore } from "./store/userStore";
import type { RouteParamsAll } from "./Routes";

const Stack = createNativeStackNavigator<RouteParamsAll>();
const isDevelopment = process.env.NODE_ENV === "development";

const linking = {
  config: {
    screens: ROUTES,
  },
  prefixes: isDevelopment ? ["exp://"] : [""],
};

export function Router() {
  const ref = useRef(null);

  const { isAuthenticated } = useMeritAuth0();
  const { user, userType } = useUserStore();

  const screenConfigs = useNavigationMap({ isAuthenticated, user, userType });

  return (
    <NavigationContainer
      linking={linking}
      onReady={() => {
        DdRumReactNavigationTracking.startTrackingViews(ref.current, (_, trackedName) => trackedName);
      }}
      ref={ref}
    >
      <Stack.Navigator screenOptions={{ headerShown: false, title: "KEEP Claims" }}>
        {screenConfigs.map(({ component, name }) => (
          <Stack.Screen component={component} key={name} name={name} />
        ))}
      </Stack.Navigator>
    </NavigationContainer>
  );
}
