import { Button } from "../Button";
import { Image, Pressable, SafeAreaView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Loading } from "../Loading";
import { None } from "../../utils/None";
import { PRIMARY_THEME_COLOR } from "../../constants";
import { Some } from "../../utils/Some";
import { TextInput } from "../TextInput";
import { UpdateClaimAmount } from "./UpdateClaimAmount";
import { UpdateClaimPayTo } from "./UpdateClaimPayTo";
import { UpdateClaimStatus } from "./UpdateClaimStatus";
import { setTestProps } from "../../utils/propHelper";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useToast } from "react-native-toast-notifications";
import { useTranslation } from "../../hooks/useTranslation";
import React, { useState } from "react";
import informationIcon from "../../../assets/icons/information.png";
import informationOutlineIcon from "../../../assets/icons/information_outlined_m_default.png";
import type { GetClaimByMeritCsResponse } from "../../__generated__/api/ClaimRoute";

enum Actions {
  UPDATE_STATUS = "Update Claim Status",
  UPDATE_AMOUNT = "Update Claim Amount",
  UPDATE_PAY_TO = "Update Claim Pay To",
}

export const ManageClaims = () => {
  const toast = useToast();
  const { claimClient } = useApi();
  const i18n = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useDefaultErrorHandler();
  const [validationError, setValidationError] = useState<string>();
  const [claimId, setClaimId] = useState<string>();
  const [claimDetails, setClaimDetails] = useState<GetClaimByMeritCsResponse>();
  const [showClaimAmountTooltip, setShowClaimAmountTooltip] = useState(false);
  const [selectedAction, setSelectedAction] = useState<Actions>();
  const { isTabletOrLarger } = useDeviceSize();

  const styles = StyleSheet.create({
    actionContainer: {
      alignItems: "center",
      margin: 20,
    },
    claimAmountContainer: {
      display: "flex",
      flexDirection: "row",
    },
    claimContainer: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      paddingTop: 12,
    },
    claimStatus: {
      fontWeight: "bold",
    },
    claimStatusMessage: {
      marginVertical: 20,
    },
    container: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
    },
    radioButton: {
      alignItems: "center",
      flexDirection: "row",
      margin: 20,
    },
    radioButtonIcon: {
      alignItems: "center",
      borderColor: PRIMARY_THEME_COLOR,
      borderRadius: 10,
      borderWidth: 2,
      height: 20,
      justifyContent: "center",
      marginRight: 10,
      width: 20,
    },
    radioButtonLabel: {
      fontSize: 16,
    },
    radioButtonSelected: {
      backgroundColor: PRIMARY_THEME_COLOR,
      borderRadius: 6,
      height: 12,
      width: 12,
    },
    radioContainer: {
      display: "flex",
      flexDirection: isTabletOrLarger ? "row" : "column",
    },
    tooltip: {
      backgroundColor: "#007A98",
      borderRadius: 4,
      paddingHorizontal: 16,
      paddingVertical: 12,
      position: "absolute",
      right: isTabletOrLarger ? -205 : 0,
      shadowColor: "rgba(0, 0, 0, 0.1)",
      shadowOffset: { height: 0, width: 0 },
      shadowOpacity: 0.8,
      shadowRadius: 4,
      top: isTabletOrLarger ? -45 : -50,
      width: 200,
      zIndex: 2,
    },
  });

  const handleClaimResponse = (claimResponse: GetClaimByMeritCsResponse) => {
    setClaimDetails(claimResponse);
    setShowClaimAmountTooltip(false);

    if (Some(claimResponse)) {
      if (
        selectedAction !== Actions.UPDATE_AMOUNT &&
        selectedAction !== Actions.UPDATE_PAY_TO &&
        (claimResponse.status === "Accepted" || claimResponse.status === "Rejected")
      ) {
        setSelectedAction(Actions.UPDATE_STATUS);
      } else if (selectedAction === Actions.UPDATE_PAY_TO) {
        setSelectedAction(Actions.UPDATE_PAY_TO);
      } else {
        setSelectedAction(Actions.UPDATE_AMOUNT);
      }
    }
  };

  const getClaim = async () => {
    if (None(claimId)) {
      throw new Error("Somehow trying to get claim details without valid claim id");
    }
    setIsLoading(true);
    try {
      const response = await claimClient.getClaimByMeritCs(Number(claimId));
      if (response.success) {
        handleClaimResponse(response.data);
      } else {
        toast.show(<Text {...setTestProps({ name: "errorMessage-UnapproveClaim" })}>{response.message}</Text>, {
          placement: "top",
          type: "danger",
        });
      }
    } catch (error: unknown) {
      errorHandler(error);
    }
    setIsLoading(false);
  };

  return (
    <SafeAreaView style={{ backgroundColor: "#FFFFFF", flex: 2 }}>
      <View style={styles.container}>
        <View>
          <Text>Enter claim id</Text>
          <View style={styles.claimContainer}>
            <TextInput
              keyboardType="decimal-pad"
              onChangeText={(value) => {
                setClaimDetails(undefined);
                if (/^\d+$/u.test(value)) {
                  setValidationError(undefined);
                  setClaimId(parseInt(value, 10).toString());
                } else {
                  setValidationError("Please enter valid claim id");
                  setClaimId(undefined);
                }
              }}
              placeholder="Enter claim id"
              testID="claimIdInputField-Dashboard"
              value={claimId}
            />
            <View style={[{ marginLeft: 20 }]}>
              <Button
                customContent={
                  isLoading ? (
                    <View style={{ minWidth: 160 }}>
                      <Loading />
                    </View>
                  ) : undefined
                }
                disabled={isLoading || Some(validationError) || None(claimId)}
                onPress={() => {
                  getClaim();
                }}
                testID="searchButton-Dashboard"
                text="Search"
              />
            </View>
          </View>
        </View>

        {Some(claimDetails) && (
          <>
            {!isLoading && Some(validationError) ? (
              <>
                <Text style={[styles.claimStatusMessage, { color: "#FF0000" }]}>{validationError}</Text>
              </>
            ) : (
              <>
                <Text style={styles.claimStatusMessage}>
                  Claim Status :
                  <Text style={styles.claimStatus} {...setTestProps({ name: "claimStatus-Dashboard" })}>
                    {claimDetails.status}
                  </Text>
                </Text>
              </>
            )}
            {Some(claimDetails.internalReviewLink) && (
              <View style={[{ width: 200 }]}>
                <Button
                  onPress={() => {
                    window.open(claimDetails.internalReviewLink);
                  }}
                  testID="viewClaimButton-Dashboard"
                  text="View claim"
                  type="secondary"
                />
              </View>
            )}

            <View style={styles.actionContainer}>
              <View style={styles.radioContainer}>
                {(claimDetails.status === "Accepted" || claimDetails.status === "Rejected") && (
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedAction(Actions.UPDATE_STATUS);
                    }}
                    style={styles.radioButton}
                  >
                    <View style={styles.radioButtonIcon} testID="updateClaimStatusRadioButton-ManageClaims">
                      {selectedAction === Actions.UPDATE_STATUS && <View style={styles.radioButtonSelected} />}
                    </View>
                    <Text style={styles.radioButtonLabel}>{Actions.UPDATE_STATUS}</Text>
                  </TouchableOpacity>
                )}
                <View style={styles.claimAmountContainer}>
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedAction(Actions.UPDATE_AMOUNT);
                    }}
                    style={{ ...styles.radioButton, marginRight: 5 }}
                  >
                    <View style={styles.radioButtonIcon} testID="updateClaimAmountRadioButton-UnapproveClaim">
                      {selectedAction === Actions.UPDATE_AMOUNT && <View style={styles.radioButtonSelected} />}
                    </View>
                    <Text style={styles.radioButtonLabel}>{Actions.UPDATE_AMOUNT}</Text>
                  </TouchableOpacity>

                  <Pressable
                    onPress={() => {
                      setShowClaimAmountTooltip((prevState) => !prevState);
                    }}
                    style={{ marginTop: 10 }}
                  >
                    <Image
                      accessible
                      source={showClaimAmountTooltip ? informationIcon : informationOutlineIcon}
                      style={{ height: 20, width: 20 }}
                      testID="updateClaimAmountToolTip-UnapproveClaim"
                    />
                  </Pressable>

                  {showClaimAmountTooltip && (
                    <View style={styles.tooltip}>
                      <Text
                        {...setTestProps({ name: "claimAmountToolTipText-ManageClaims" })}
                        style={{ color: "#FFFFFF" }}
                      >
                        {i18n.t("SubmitNewClaim.updateAmountForAcceptedClaim")}
                      </Text>
                    </View>
                  )}
                </View>
                <TouchableOpacity
                  onPress={() => {
                    setSelectedAction(Actions.UPDATE_PAY_TO);
                  }}
                  style={styles.radioButton}
                >
                  <View style={styles.radioButtonIcon} testID="updateClaimPayToRadioButton-ManageClaims">
                    {selectedAction === Actions.UPDATE_PAY_TO && <View style={styles.radioButtonSelected} />}
                  </View>
                  <Text style={styles.radioButtonLabel}>{Actions.UPDATE_PAY_TO}</Text>
                </TouchableOpacity>
              </View>

              {selectedAction === Actions.UPDATE_AMOUNT && (
                <UpdateClaimAmount
                  claimDetails={claimDetails}
                  claimId={claimId}
                  getClaim={() => {
                    getClaim();
                  }}
                />
              )}

              {selectedAction === Actions.UPDATE_STATUS && (
                <UpdateClaimStatus
                  claimDetails={claimDetails}
                  claimId={claimId}
                  getClaim={() => {
                    getClaim();
                  }}
                />
              )}

              {selectedAction === Actions.UPDATE_PAY_TO && (
                <UpdateClaimPayTo
                  claimDetails={claimDetails}
                  claimId={claimId}
                  getClaim={() => {
                    getClaim();
                  }}
                />
              )}
            </View>
          </>
        )}
      </View>
    </SafeAreaView>
  );
};
