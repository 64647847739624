import { Button } from "./Button";
import { Platform, StyleSheet, Text, View } from "react-native";
import { Some } from "../utils/Some";
import { setTestProps } from "../utils/propHelper";
import { useTranslation } from "../hooks/useTranslation";
import EditIcon from "../../assets/icons/edit_m_action.png";
import React from "react";
import RemoveIcon from "../../assets/icons/close_circle_m_action.png";
import type { FormValues as ServiceProvider } from "../components/ServiceProviderForm";

type Props = {
  readonly onEdit: () => void;
  readonly onRemove: () => void;
  readonly serviceProviderInfo: ServiceProvider;
};

export const ServiceProviderInfo = ({ onEdit, onRemove, serviceProviderInfo }: Props) => {
  const i18n = useTranslation();

  const styles = StyleSheet.create({
    label: {
      minWidth: 150,
    },
    row: {
      flexDirection: "row",
      paddingVertical: 2,
    },
    text: {
      flex: 1,
      flexWrap: "wrap",
      ...(Platform.OS === "web" && { wordBreak: "break-word" }),
    },
  });

  return (
    <View>
      <View style={{ paddingVertical: 16 }}>
        <Text style={{ fontSize: 16, fontWeight: "600" }} {...setTestProps({ name: "title-ServiceProviderInfo" })}>
          {i18n.t("ServiceProviderInfo.title")}
        </Text>
      </View>
      <View style={{ width: "100%" }}>
        <View style={styles.row}>
          <View style={styles.label}>
            <Text>{i18n.t("ServiceProviderInfo.nameLabel")}</Text>
          </View>
          <Text style={styles.text} {...setTestProps({ name: "name-ServiceProviderInfo" })}>
            {serviceProviderInfo.name}
          </Text>
        </View>
        <View style={styles.row}>
          <View style={styles.label}>
            <Text>{i18n.t("ServiceProviderInfo.addressLabel")}</Text>
          </View>
          <Text style={styles.text} {...setTestProps({ name: "address-ServiceProviderInfo" })}>
            {serviceProviderInfo.address}
          </Text>
        </View>
        <View style={styles.row}>
          <View style={styles.label}>
            <Text>{i18n.t("ServiceProviderInfo.phoneNumberLabel")}</Text>
          </View>
          <Text {...setTestProps({ name: "phoneNumber-ServiceProviderInfo" })}>{serviceProviderInfo.phoneNumber}</Text>
        </View>
        <View style={styles.row}>
          <View style={styles.label}>
            <Text>{i18n.t("ServiceProviderInfo.emailAddressLabel")}</Text>
          </View>
          <Text style={styles.text} {...setTestProps({ name: "email-ServiceProviderInfo" })}>
            {serviceProviderInfo.email}
          </Text>
        </View>
        <View style={styles.row}>
          <View style={styles.label}>
            <Text>{i18n.t("ServiceProviderInfo.websiteLabel")}</Text>
          </View>
          <Text style={styles.text} {...setTestProps({ name: "website-ServiceProviderInfo" })}>
            {serviceProviderInfo.website}
          </Text>
        </View>
        {Some(serviceProviderInfo.contactName) && (
          <View style={styles.row}>
            <View style={styles.label}>
              <Text>{i18n.t("ServiceProviderInfo.contactNameLabel")}</Text>
            </View>
            <Text style={styles.text} {...setTestProps({ name: "contactName-ServiceProviderInfo" })}>
              {serviceProviderInfo.contactName}
            </Text>
          </View>
        )}
        {Some(serviceProviderInfo.contactTitle) && (
          <View style={styles.row}>
            <View style={styles.label}>
              <Text>{i18n.t("ServiceProviderInfo.contactTitleLabel")}</Text>
            </View>
            <Text style={styles.text} {...setTestProps({ name: "contactTitle-ServiceProviderInfo" })}>
              {serviceProviderInfo.contactTitle}
            </Text>
          </View>
        )}

        <View style={[styles.row, { paddingVertical: 16 }]}>
          <View style={{ minWidth: 150 }}>
            <View style={{ width: 120 }}>
              <Button
                iconLeft={EditIcon}
                onPress={onEdit}
                testID="editButton-ServiceProviderInfo"
                text={i18n.t("ServiceProviderInfo.editButtonText")}
                type="secondary"
              />
            </View>
          </View>
          <View style={{ width: 120 }}>
            <Button
              iconLeft={RemoveIcon}
              onPress={onRemove}
              testID="removeButton-ServiceProviderInfo"
              text={i18n.t("ServiceProviderInfo.removeButtonText")}
              type="secondary"
            />
          </View>
        </View>
      </View>
    </View>
  );
};
