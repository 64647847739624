// Copyright 2022 Merit International Inc. All Rights Reserved

import { Image, Platform, Pressable, StyleSheet, Text, View } from "react-native";
import { Some } from "../../utils/Some";
import { setTestProps } from "../../utils/propHelper";
import { useTranslation } from "../../hooks/useTranslation";
import React from "react";
import ReactSelect from "react-select";
import document from "../../../assets/icons/document_m_default.png";
import type { SelectProps } from "./types";

const styles = StyleSheet.create({
  labelContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 1,
    paddingVertical: 5,
  },
});

export const Select = ({
  disabled,
  hasError = false,
  helpText,
  label,
  onSelectOption,
  options,
  selectedValue,
  testID,
}: SelectProps) => {
  const i18n = useTranslation();

  return (
    <>
      <View style={styles.labelContainer}>
        <Text>{label}</Text>
        {Some(helpText) && (
          <Pressable>
            <Image accessible source={document} style={{ height: 20, width: 20 }} />
          </Pressable>
        )}
      </View>
      <View {...setTestProps({ name: testID })}>
        <ReactSelect
          isDisabled={disabled}
          onChange={(option) => {
            onSelectOption(option);
          }}
          options={options}
          placeholder={i18n.t("Select.placeholder")}
          styles={{
            control: (provided) => ({
              ...provided,
              ...(hasError && { borderColor: "#D03931" }),
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial',
            }),
            indicatorSeparator: () => ({}),
            menu: (provided) => ({
              ...provided,
              ...Platform.select({
                android: { elevation: 1, shadowColor: "rgba(0, 0, 0, 0.05)" },
                ios: {
                  shadowColor: "rgba(0, 0, 0, 0.05)",
                  shadowRadius: 1,
                },
              }),
            }),
            option: (provided) => ({
              ...provided,
              color: "rgba(11,29,55,1)",
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial',
            }),
          }}
          theme={(selectTheme) => ({
            ...selectTheme,
            colors: {
              ...selectTheme.colors,
              primary: "rgba(238,235,227,1)",
              primary25: "rgba(244,245,247,1)",
              primary50: "rgba(235,236,240,1)",
            },
          })}
          value={options.find((_) => _.value === selectedValue)}
        />
      </View>
    </>
  );
};
