import * as Yup from "yup";
import { Button } from "./Button";
import { ErrorMessage, Formik } from "formik";
import { Keyboard, StyleSheet, Text, View } from "react-native";
import { Some } from "../utils/Some";
import { TextInput } from "./TextInput";
import { setTestProps } from "../utils/propHelper";
import { useTranslation } from "../hooks/useTranslation";
import React from "react";
import validator from "validator";
import type { FieldMetaProps, FormikHelpers } from "formik";

export type FormValues = {
  readonly address: string;
  readonly contactName: string | undefined;
  readonly contactTitle: string | undefined;
  readonly email: string;
  readonly name: string;
  readonly phoneNumber: string;
  readonly website: string;
};

type Props = {
  readonly onSubmit: (values: FormValues) => void;
  readonly serviceProviderInfo: FormValues | undefined;
};

export const ServiceProviderForm = ({ onSubmit, serviceProviderInfo }: Props) => {
  const i18n = useTranslation();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: "#FFFFFF",
      paddingHorizontal: 32,
      paddingVertical: 24,
    },
    fieldItem: {
      paddingVertical: 16,
      zIndex: 99,
    },
    label: {
      fontSize: 14,
      paddingBottom: 8,
    },
  });

  const validationSchema = Yup.object().shape({
    address: Yup.string().trim().required(i18n.t("ServiceProviderForm.addressEmptyError")),
    email: Yup.string()
      .trim()
      .email(i18n.t("ServiceProviderForm.emailAddressInvalidError"))
      .required(i18n.t("ServiceProviderForm.emailAddressEmptyError")),
    name: Yup.string().trim().required(i18n.t("ServiceProviderForm.nameEmptyError")),
    phoneNumber: Yup.string()
      .required(i18n.t("ServiceProviderForm.phoneNumberEmptyError"))
      .test("validate-phoneNumber", i18n.t("ServiceProviderForm.phoneNumberInvalidError"), (number) => {
        if (Some(number) && validator.isMobilePhone(number)) {
          return true;
        }

        return false;
      }),
    website: Yup.string()
      .trim()
      .required(i18n.t("ServiceProviderForm.websiteEmptyError"))
      .test(
        "validate-website",
        i18n.t("ServiceProviderForm.websiteInvalidError"),
        (website) => Some(website) && validator.isURL(website),
      ),
  });

  const defaultFormValues: FormValues = {
    address: "",
    contactName: undefined,
    contactTitle: undefined,
    email: "",
    name: "",
    phoneNumber: "",
    website: "",
  };

  const errorText = (error: string) => (
    <View style={{ paddingVertical: 8 }}>
      <Text
        style={{ color: "#D03931", fontSize: 12 }}
        {...setTestProps({ name: `${error}-ErrorMessage-ServiceProviderForm` })}
      >
        {error}
      </Text>
    </View>
  );

  const isValid = (getFieldMeta: (name: string) => FieldMetaProps<string>, field: string) =>
    Some(getFieldMeta(field).error) && getFieldMeta(field).touched;

  return (
    <View style={styles.container}>
      <Formik
        initialValues={Some(serviceProviderInfo) ? serviceProviderInfo : defaultFormValues}
        onSubmit={(values: Readonly<FormValues>, {}: Readonly<FormikHelpers<FormValues>>) => {
          onSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({ getFieldMeta, handleChange, handleSubmit, values }) => (
          <>
            <View style={styles.fieldItem}>
              <View style={styles.label}>
                <Text>{i18n.t("ServiceProviderForm.nameLabel")}</Text>
              </View>

              <TextInput
                hasError={isValid(getFieldMeta, "name")}
                onChangeText={handleChange("name")}
                placeholder={i18n.t("ServiceProviderForm.namePlaceholder")}
                testID="nameInputField-ServiceProviderForm"
                value={values.name}
              />
              <ErrorMessage name="name">{errorText}</ErrorMessage>
            </View>
            <View style={styles.fieldItem}>
              <View style={styles.label}>
                <Text>{i18n.t("ServiceProviderForm.addressLabel")}</Text>
              </View>

              <TextInput
                hasError={isValid(getFieldMeta, "address")}
                onChangeText={handleChange("address")}
                placeholder={i18n.t("ServiceProviderForm.addressPlaceholder")}
                testID="addressInputField-ServiceProviderForm"
                value={values.address}
              />
              <ErrorMessage name="address">{errorText}</ErrorMessage>
            </View>
            <View style={styles.fieldItem}>
              <View style={styles.label}>
                <Text>{i18n.t("ServiceProviderForm.phoneNumberLabel")}</Text>
              </View>

              <TextInput
                hasError={isValid(getFieldMeta, "phoneNumber")}
                onChangeText={handleChange("phoneNumber")}
                placeholder={i18n.t("ServiceProviderForm.phoneNumberPlaceholder")}
                testID="phoneNumberInputField-ServiceProviderForm"
                value={values.phoneNumber}
              />
              <ErrorMessage name="phoneNumber">{errorText}</ErrorMessage>
            </View>
            <View style={styles.fieldItem}>
              <View style={styles.label}>
                <Text>{i18n.t("ServiceProviderForm.emailAddressLabel")}</Text>
              </View>

              <TextInput
                hasError={isValid(getFieldMeta, "email")}
                onChangeText={handleChange("email")}
                placeholder={i18n.t("ServiceProviderForm.emailAddressPlaceholder")}
                testID="emailInputField-ServiceProviderForm"
                value={values.email}
              />
              <ErrorMessage name="email">{errorText}</ErrorMessage>
            </View>
            <View style={styles.fieldItem}>
              <View style={styles.label}>
                <Text>{i18n.t("ServiceProviderForm.websiteLabel")}</Text>
              </View>

              <TextInput
                hasError={isValid(getFieldMeta, "website")}
                onChangeText={handleChange("website")}
                placeholder={i18n.t("ServiceProviderForm.websitePlaceholder")}
                testID="websiteInputField-ServiceProviderForm"
                value={values.website}
              />
              <ErrorMessage name="website">{errorText}</ErrorMessage>
            </View>
            <View style={styles.fieldItem}>
              <View style={styles.label}>
                <Text>{i18n.t("ServiceProviderForm.contactNameLabel")}</Text>
              </View>

              <TextInput
                onChangeText={handleChange("contactName")}
                placeholder={i18n.t("ServiceProviderForm.contactNamePlaceholder")}
                testID="contactNameInputField-ServiceProviderForm"
                value={values.contactName}
              />
            </View>
            <View style={styles.fieldItem}>
              <View style={styles.label}>
                <Text>{i18n.t("ServiceProviderForm.contactTitleLabel")}</Text>
              </View>

              <TextInput
                onChangeText={handleChange("contactTitle")}
                placeholder={i18n.t("ServiceProviderForm.contactTitlePlaceholder")}
                testID="contactTitleInputField-ServiceProviderForm"
                value={values.contactTitle}
              />
            </View>

            <View style={{ alignSelf: "flex-end", paddingHorizontal: 24, paddingVertical: 10 }}>
              <Button
                onPress={() => {
                  Keyboard.dismiss();
                  handleSubmit();
                }}
                testID="saveButton-ServiceProviderForm"
                text={i18n.t("ServiceProviderForm.saveButtonText")}
              />
            </View>
          </>
        )}
      </Formik>
    </View>
  );
};
