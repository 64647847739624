// Browser proxy for expo-datadog, see also webpack.config.js

/* eslint-disable @typescript-eslint/parameter-properties */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable require-await */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable functional/no-classes */
/* eslint-disable @typescript-eslint/max-params */

import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

// https://docs.datadoghq.com/getting_started/site/
const DATADOG_SITE = "datadoghq.com";

export const DdSdkReactNative = {
  initialize: (config: DdSdkReactNativeConfiguration) => {
    datadogLogs.init({
      clientToken: config.clientToken,
      env: config.env,
      forwardErrorsToLogs: config.trackErrors,
      service: config.serviceName,
      site: DATADOG_SITE,
    });

    datadogRum.init({
      applicationId: config.applicationId,
      clientToken: config.clientToken,
      defaultPrivacyLevel: "mask",
      env: config.env,
      service: config.serviceName,
      site: DATADOG_SITE,
      trackResources: config.trackResources,
      trackUserInteractions: config.trackInteractions,
    });
  },
};

export class DdSdkReactNativeConfiguration {
  // eslint-disable-next-line functional/prefer-readonly-type
  public serviceName: string | undefined;

  public constructor(
    public readonly clientToken: string,
    public readonly env: string,
    public readonly applicationId: string,
    public readonly trackInteractions = false,
    public readonly trackResources = false,
    public readonly trackErrors = false,
  ) {}
}
