import { None } from "../utils/None";
import { Platform } from "react-native";
import { UnreachableCaseError } from "../utils/UnreachableCaseError";
import { UserType, useUserStore } from "../store/userStore";
import { useCallback } from "react";
import { useLogoutFromAuth0 } from "./auth";

const getUseLogoutURL = (userType: UserType | undefined) => {
  if (None(userType)) {
    return window.location.origin;
  }

  switch (userType) {
    case UserType.PARENT:
      return window.location.origin;
    case UserType.MERIT_CS:
      return `${window.location.origin}/merit-cs`;
    case UserType.SERVICE_PROVIDER:
      return `${window.location.origin}/service-provider`;
    default:
      throw new UnreachableCaseError(userType);
  }
};

const useLogout = () => {
  const logoutFromAuth0 = useLogoutFromAuth0();
  const { logoutFromUserStore, userType: userTypeFromStore } = useUserStore();

  const logout = useCallback(async () => {
    await logoutFromAuth0();
    logoutFromUserStore();

    if (Platform.OS === "web") {
      const logoutURL = getUseLogoutURL(userTypeFromStore);

      // eslint-disable-next-line functional/immutable-data
      window.location.href = logoutURL;
    }
  }, [logoutFromAuth0, logoutFromUserStore, userTypeFromStore]);

  return { logout };
};

export { useLogout };
