import "react-native-url-polyfill/auto";
import * as Sentry from "sentry-expo";
import * as SplashScreen from "expo-splash-screen";
import { AppRoot } from "./src/AppRoot/AppRoot";
import { AuthProvider } from "./src/components/AuthProvider";
import { DdProvider } from "./src/components/DdProvider";
import { Loading } from "./src/components/Loading";
import { SentryErrorBoundary } from "./src/utils/SentryErrorBoundary";
import { config } from "./src/config/config";
import { useEffect, useState } from "react";
import { useGetConfiguration } from "./src/hooks/useGetConfiguration";
import { useLocaleStore } from "./src/store/localeStore";
import { useUserStore } from "./src/store/userStore";

// Native: Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

Sentry.init({
  ...config.sentry,
  debug: config.sentry.environment === "development",
  enableWatchdogTerminationTracking: false,
  enabled: config.sentry.environment !== "development",
});

export const App = () => {
  const [storesReady, setStoresReady] = useState(false);
  const { isLoading: isConfigurationLoading } = useGetConfiguration();

  useEffect(() => {
    const hydrate = async () => {
      await Promise.all([useUserStore.persist.rehydrate(), useLocaleStore.persist.rehydrate()]);
      await SplashScreen.hideAsync();
      setStoresReady(true);
    };

    hydrate();
  }, []);

  if (!storesReady) {
    return null;
  }

  if (isConfigurationLoading) {
    return <Loading />;
  }

  return (
    <DdProvider>
      <AuthProvider>
        <SentryErrorBoundary>
          <AppRoot />
        </SentryErrorBoundary>
      </AuthProvider>
    </DdProvider>
  );
};
