import { Image, SafeAreaView, StyleSheet, Text, View } from "react-native";
import { PRIMARY_THEME_COLOR } from "../constants";
import { Platform } from "expo-modules-core";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useTranslation } from "../hooks/useTranslation";
import BankSymbol from "../../assets/images/bank-symbol.png";
import React from "react";

export const BankAlreadyLinkedScreen = () => {
  const i18n = useTranslation();
  const { isDesktopOrLarger } = useDeviceSize();

  const styles = StyleSheet.create({
    header: {
      backgroundColor: PRIMARY_THEME_COLOR,
      borderBottomColor: "#CCCCCC",
      borderBottomWidth: 1,
      paddingHorizontal: 40,
      paddingVertical: 24,
    },
    headerText: {
      color: "#FFFFFF",
      fontSize: 18,
      fontWeight: "600",
    },
    wrapper: {
      backgroundColor: isDesktopOrLarger ? "#F2F2F2" : "#FFFFFF",
      flex: 1,
    },
  });

  return (
    <SafeAreaView style={styles.wrapper}>
      <View style={{ alignItems: "center", flex: 1 }}>
        <View style={{ alignItems: "center", flex: 1, paddingVertical: 40, width: isDesktopOrLarger ? "30%" : "80%" }}>
          <Image accessibilityLabel="" source={BankSymbol} style={{ height: 120, width: 120 }} />

          <View style={{ flex: 1, paddingVertical: 40 }}>
            <Text style={{ fontSize: 28, fontWeight: "600" }}>{i18n.t("BankAlreadyLinkedScreen.contentTitle")}</Text>

            <View style={{ alignItems: "center", paddingVertical: 20 }}>
              <Text style={{ fontSize: 16 }}>
                {Platform.OS === "web"
                  ? i18n.t("BankAlreadyLinkedScreen.contentSubtitleWeb")
                  : i18n.t("BankAlreadyLinkedScreen.contentSubtitleNative")}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};
