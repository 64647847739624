import { ActivityIndicator, Text } from "react-native";
import { CenteredContent } from "../Layouts/CenteredContent";
import { PRIMARY_THEME_COLOR } from "../constants";
import { Some } from "../utils/Some";

type Props = {
  readonly text?: string;
};

export const Loading = ({ text }: Props) => (
  <CenteredContent>
    <ActivityIndicator color={PRIMARY_THEME_COLOR} />
    {Some(text) && <Text style={{ marginTop: 16 }}>{text}</Text>}
  </CenteredContent>
);
