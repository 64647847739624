// Copyright 2022 Merit International Inc. All Rights Reserved

import { Button } from "../components/Button";
import { Image, Modal, Pressable, StyleSheet, Text, View } from "react-native";
import { setTestProps } from "../utils/propHelper";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useTranslation } from "../hooks/useTranslation";
import CloseIcon from "../../assets/icons/close_l_action.png";

type Props = {
  readonly isVisible: boolean;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
  readonly text: string;
};

export const ConfirmationModal = ({ isVisible, onCancel, onConfirm, text }: Props) => {
  const { isDesktopOrLarger } = useDeviceSize();
  const i18n = useTranslation();
  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      borderRadius: 4,
      paddingHorizontal: 32,
      paddingVertical: 24,
      width: isDesktopOrLarger ? "50%" : "95%",
    },
    footer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      width: "100%",
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingVertical: 12,
      width: "100%",
    },
    headerText: {
      fontSize: 20,
      fontWeight: "600",
    },
    wrapper: {
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, 0.5)",
      flex: 1,
      justifyContent: "center",
    },
  });

  return (
    <Modal animationType="fade" onRequestClose={onCancel} transparent visible={isVisible}>
      <View style={styles.wrapper}>
        <View style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.headerText} {...setTestProps({ name: "header-ConfirmationModal" })}>
              {i18n.t("ConfirmationModal.title")}
            </Text>
            <Pressable onPress={onCancel}>
              <Image
                accessibilityLabel="Close"
                accessible
                resizeMode="contain"
                source={CloseIcon}
                style={{ height: 14, width: 14 }}
                {...setTestProps({ name: "closeIcon-ConfirmationModal" })}
              />
            </Pressable>
          </View>
          <>
            <Text style={{ width: "100%" }} {...setTestProps({ name: "message-ConfirmationModal" })}>
              {text}
            </Text>
            <View style={{ height: 24 }} />
            <View style={styles.footer}>
              <Button
                onPress={onCancel}
                testID="noButton-ConfirmationModal"
                text={i18n.t("ConfirmationModal.cancel")}
                type="secondary"
              />
              <View style={{ width: 8 }} />
              <Button
                onPress={onConfirm}
                testID="yesButton-ConfirmationModal"
                text={i18n.t("ConfirmationModal.confirm")}
              />
            </View>
          </>
        </View>
      </View>
    </Modal>
  );
};
