import { Loading } from "../components/Loading";
import { NotEligible as MeritCSNotEligible } from "./MeritCS/NotEligible";
import { None } from "../utils/None";
import { NotEligibleScreen as ParentNotEligible } from "./NotEligibleScreen";
import { Platform } from "react-native";
import { SelectOrgModal } from "../components/ServiceProvider/SelectOrgModal";
import { Some } from "../utils/Some";
import { TermsOfServiceModal } from "../components/TermsOfServiceModal";
import { UserType, useUserStore } from "../store/userStore";
import { config } from "../config/config";
import { showToast } from "../utils/showToast";
import { useAccessToken, useMeritAuth0 } from "../hooks/auth";
import { useApi } from "../services/useApi";
import { useCallback, useEffect, useState } from "react";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";
import { useLogout } from "../hooks/useLogout";
import type { SelectedOrg as ServiceProvider } from "../store/userStore";

export const OnboardingScreen = () => {
  const { isAuthenticated, user } = useMeritAuth0();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showNotEligibleScreen, setShowNotEligibleScreen] = useState<boolean>(false);
  const [showTosModal, setShowTosModal] = useState(false);
  const { agentClient, loginClient, serviceProviderClient } = useApi();
  const [showSelectOrgModel, setShowSelectOrgModal] = useState(false);
  const { setSelectedOrg } = useUserStore();
  const [serviceProviders, setServiceProviders] = useState<readonly ServiceProvider[]>();
  const { setUser, userType } = useUserStore();
  const { logout } = useLogout();
  const getAccessToken = useAccessToken();

  const { errorHandler } = useDefaultErrorHandler();

  const fetchUserData = useCallback(() => {
    try {
      switch (userType) {
        case UserType.SERVICE_PROVIDER:
          if (Platform.OS === "web") {
            const getServiceProviderAccessMerits = async () => {
              try {
                const serviceProviderMeritsResponse = await serviceProviderClient.getServiceProviderAccessMerits();

                if (serviceProviderMeritsResponse.success) {
                  const { merits: serviceProviderAccessMerits } = serviceProviderMeritsResponse.data;

                  if (serviceProviderAccessMerits.length === 1) {
                    setSelectedOrg(serviceProviderAccessMerits[0]);
                    const response = await loginClient.loginAsServiceProvider(serviceProviderAccessMerits[0].id);
                    if (response.success) {
                      setUser(response.data);
                      setIsLoading(false);
                    } else {
                      setIsLoading(false);
                      showToast({ message: response.message });
                    }
                  } else {
                    setServiceProviders(serviceProviderAccessMerits);
                    setShowSelectOrgModal(true);
                    setIsLoading(false);
                  }
                } else {
                  showToast({ message: serviceProviderMeritsResponse.message });
                }
              } catch (error: unknown) {
                errorHandler(error);
              }
            };

            getServiceProviderAccessMerits();
          } else {
            showToast({ message: "Only Parent/Guardian type users can access mobile app" });
          }

          break;
        case UserType.MERIT_CS:
          if (Platform.OS === "web") {
            const getMeritCSResponse = async () => {
              try {
                const response = await loginClient.loginAsMeritCs();
                if (response.success) {
                  setUser(response.data);
                } else {
                  setShowNotEligibleScreen(true);
                  showToast({ message: response.message });
                }
              } catch (error: unknown) {
                errorHandler(error);
              }
            };

            getMeritCSResponse();
          } else {
            showToast({ message: "Only Parent/Guardian type users can access mobile app" });
          }

          break;
        case UserType.PARENT:
          const getParent = async () => {
            try {
              const accessToken = await getAccessToken();

              if (isAuthenticated && Some(accessToken) && accessToken !== "") {
                const response = await loginClient.loginAsParent();

                if (response.success) {
                  if (response.data.children.length === 0) {
                    setIsLoading(false);
                    setShowNotEligibleScreen(true);
                  } else {
                    setUser(response.data);
                    setIsLoading(false);
                  }
                } else {
                  // Hack: Need to set the timeout for logout else we cannot see the toast as we are redirecting to another screen
                  showToast({ message: response.message });
                  setTimeout(() => {
                    logout();
                  }, 1500);
                  setIsLoading(false);
                }
              } else {
                showToast({ message: "Somehow unable to authorise, please try again" });
              }
            } catch (error: unknown) {
              errorHandler(error);
            }
          };

          getParent();

          break;
        default:
          throw new Error("Somehow invalid user type is found");
      }
    } catch (error) {
      errorHandler(error);
    }
  }, [
    errorHandler,
    getAccessToken,
    isAuthenticated,
    loginClient,
    logout,
    serviceProviderClient,
    setSelectedOrg,
    setUser,
    userType,
  ]);

  useEffect(() => {
    if (None(user)) {
      throw new Error("Failed to populate user data");
    }

    const getLinks = async () => {
      try {
        const { capabilitiesApproved, tosAccepted } = await agentClient.getAgentLinks(user.agentID, {
          baseURL: config.api.stellar.agents.baseUrl,
        });
        if (!capabilitiesApproved || !tosAccepted) {
          setShowTosModal(true);
        } else {
          fetchUserData();
        }
      } catch (error) {
        errorHandler(error);
      }
    };
    getLinks();
  }, [agentClient, errorHandler, fetchUserData, logout, user]);

  const shouldFetchUserData = (status: boolean) => {
    if (status) {
      setShowTosModal(false);
      fetchUserData();
    }
  };

  if (showTosModal) {
    return <TermsOfServiceModal onCancelClick={setShowTosModal} shouldFetchUserData={shouldFetchUserData} />;
  }

  if (showNotEligibleScreen) {
    if (userType === UserType.MERIT_CS) {
      return <MeritCSNotEligible />;
    }

    if (userType === UserType.PARENT) {
      return <ParentNotEligible />;
    }
  }

  return (
    <>
      {isLoading && <Loading />}

      {showSelectOrgModel && (
        <SelectOrgModal
          onClose={() => {
            logout();
          }}
          serviceProviders={serviceProviders}
          visible={showSelectOrgModel}
        />
      )}
    </>
  );
};
