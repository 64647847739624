import { Image, Pressable, StyleSheet, Text } from "react-native";
import { setTestProps } from "../../utils/propHelper";
import { useLocaleStore } from "../../store/localeStore";
import LanguageActionIcon from "../../../assets/icons/language_m_action.png";
import LanguageActionSecondaryIcon from "../../../assets/icons/language_m_action_secondary.png";
import type { StyleProp, ViewStyle } from "react-native";

type SwitchLanguageButtonProps = {
  readonly theme: "dark" | "light";
  readonly style?: StyleProp<ViewStyle>;
  readonly testID?: string;
};

export const SwitchLanguageButton = ({ style, testID, theme = "light" }: SwitchLanguageButtonProps) => {
  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      flexDirection: "row",
    },
    icon: {
      height: 20,
      marginRight: 8,
      width: 20,
    },
    languageCode: {
      color: theme === "light" ? "#FFFFFF" : "#000000",
      fontWeight: "600",
    },
  });

  const { locale, setLocale, supportedLocales } = useLocaleStore();

  return (
    <Pressable
      hitSlop={8}
      onPress={() => {
        const nextLocaleIndex = (supportedLocales.indexOf(locale) + 1) % supportedLocales.length;
        setLocale(supportedLocales[nextLocaleIndex]);
      }}
      style={[styles.container, style]}
      {...setTestProps({ name: testID === undefined ? "SwitchLanguageButton" : `${testID}-SwitchLanguageButton` })}
    >
      <Image source={theme === "light" ? LanguageActionSecondaryIcon : LanguageActionIcon} style={styles.icon} />
      <Text style={styles.languageCode}>{locale.toUpperCase()}</Text>
    </Pressable>
  );
};
