// Copyright 2022 Merit International Inc. All Rights Reserved

import { Image, Platform, Pressable, StyleSheet, Text, View } from "react-native";
import { InvalidClaimScreen } from "../ErrorScreens/InvalidClaimScreen";
import { Loading } from "../../components/Loading";
import { None } from "../../utils/None";
import { PRIMARY_THEME_COLOR } from "../../constants";
import { Some } from "../../utils/Some";
import { formatCurrency } from "../../utils/FormatHelper";
import { getServiceCategoryLabel } from "../../utils/ServiceCategory";
import { openURL } from "../../utils/openURL";
import { setTestProps } from "../../utils/propHelper";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useRoute } from "@react-navigation/native";
import { useUserStore } from "../../store/userStore";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import type { GetClaimByServiceProviderResponse } from "../../__generated__/api/ClaimRoute";
import type { RouteParamsAll } from "../../Routes";
import type { RouteProp } from "@react-navigation/native";

export const ClaimScreen = () => {
  const {
    params: { claimId },
  } = useRoute<RouteProp<RouteParamsAll, "ServiceProviderClaimScreen">>();
  const [isLoading, setIsLoading] = useState(true);
  const { errorHandler } = useDefaultErrorHandler();
  const { claimClient } = useApi();
  const { isDesktopOrLarger } = useDeviceSize();
  const [claimDetails, setClaimDetails] = useState<GetClaimByServiceProviderResponse>();
  const [invalidClaimMessage, setInvalidClaimMessage] = useState<string>();
  const { selectedOrg } = useUserStore();

  useEffect(() => {
    const getClaimDetails = async () => {
      try {
        if (None(selectedOrg)) {
          throw new Error("Service provider cannot be in this state without selecting an org");
        }
        setIsLoading(true);
        const response = await claimClient.getClaimByServiceProvider(claimId, selectedOrg.id);
        if (response.success) {
          setClaimDetails(response.data);
        } else {
          setInvalidClaimMessage(response.message);
        }

        setIsLoading(false);
      } catch (error: unknown) {
        errorHandler(error);
      }
    };

    getClaimDetails();
  }, [errorHandler, claimClient, claimId, selectedOrg]);

  const styles = StyleSheet.create({
    contentWrapper: {
      backgroundColor: "#FFFFFF",
      height: "auto",
      marginTop: isDesktopOrLarger ? 40 : 0,
      paddingHorizontal: 40,
      paddingVertical: 24,
      width: isDesktopOrLarger ? "80%" : "100%",
    },
    header: {
      alignItems: "center",
      backgroundColor: PRIMARY_THEME_COLOR,
      flexDirection: "row",
      justifyContent: "center",
      paddingHorizontal: 40,
      paddingVertical: 24,
      width: "100%",
    },
    headerText: {
      color: "#FFFFFF",
      fontSize: 20,
      fontWeight: "600",
    },
    infoIconWrapper: {
      marginLeft: isDesktopOrLarger ? 8 : 10,
      marginRight: isDesktopOrLarger ? 0 : 25,
    },

    innerContainer: {
      alignItems: "center",
      backgroundColor: "#F2F2F2",
      width: "100%",
    },
    nonAceServiceProvider: {
      backgroundColor: "#f9f8f8",
      borderBottomColor: "#C1C7D0",
      borderRadius: 4,
      flexDirection: "column",
      padding: 20,
    },
    nonAceServiceProviderRow: {
      flexDirection: "row",
      paddingVertical: 16,
    },
    row: {
      borderBottomColor: "#C1C7D0",
      borderBottomWidth: 1,
      flexDirection: "row",
      paddingVertical: 16,
    },
    tooltip: {
      backgroundColor: "#007A98",
      borderColor: "#DFE1E6",
      borderRadius: 4,
      borderWidth: 1,
      bottom: isDesktopOrLarger ? 22 : 25,
      elevation: 20,
      paddingHorizontal: 16,
      paddingVertical: 16,
      position: "absolute",
      ...(isDesktopOrLarger ? { right: 15 } : { left: 10 }),
      shadowColor: "rgba(0, 0, 0, 0.3)",
      shadowRadius: 4,
      width: 220,
    },
  });

  if (Some(invalidClaimMessage)) {
    return <InvalidClaimScreen message={invalidClaimMessage} />;
  }

  if (isLoading || None(claimDetails)) {
    return <Loading />;
  }

  const {
    amount,
    category,
    child: { firstName, lastName },
    dateOfService,
    description,
    files,
    nonAceServiceProvider,
    parent,
    payTo,
    physicalGood,
    serviceProvider,
  } = claimDetails;

  return (
    <View
      style={{
        alignItems: "center",
        flex: 1,
      }}
    >
      <View style={styles.header}>
        <Text {...setTestProps({ name: "header-ClaimScreen" })} style={styles.headerText}>
          Claim Details
        </Text>
      </View>

      <View style={styles.innerContainer}>
        <View style={styles.contentWrapper}>
          <View style={styles.row}>
            <View style={{ flex: 1 }}>
              <Text>Student</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "studentFullName-ClaimScreen" })}>{`${firstName} ${lastName}`}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ flex: 1 }}>
              <Text>Parent/Guardian</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "parentFullName-ClaimScreen" })}>
                {parent.firstName} {parent.lastName}
              </Text>
            </View>
          </View>

          {Some(serviceProvider?.number) && (
            <View style={styles.row}>
              <View style={{ flex: 1 }}>
                <Text>Service provider number</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text {...setTestProps({ name: "serviceProviderNumber-ClaimScreen" })}>{serviceProvider.number}</Text>
              </View>
            </View>
          )}

          {None(serviceProvider?.number) && Some(nonAceServiceProvider) && (
            <>
              <View
                style={[
                  styles.nonAceServiceProvider,
                  {
                    borderBottomWidth: None(serviceProvider?.number) && Some(nonAceServiceProvider) ? 0 : 1,
                  },
                ]}
              >
                <View style={styles.nonAceServiceProviderRow}>
                  <View style={{ flex: 1 }}>
                    <Text>Service provider name</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text>{nonAceServiceProvider.name}</Text>
                  </View>
                </View>
                <View style={styles.nonAceServiceProviderRow}>
                  <View style={{ flex: 1 }}>
                    <Text>Service provider address</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text>{nonAceServiceProvider.address}</Text>
                  </View>
                </View>
                <View style={styles.nonAceServiceProviderRow}>
                  <View style={{ flex: 1 }}>
                    <Text>Service provider phone number</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text>{nonAceServiceProvider.phoneNumber}</Text>
                  </View>
                </View>
                <View style={styles.nonAceServiceProviderRow}>
                  <View style={{ flex: 1 }}>
                    <Text>Service provider email address</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text>{nonAceServiceProvider.email}</Text>
                  </View>
                </View>
                <View style={styles.nonAceServiceProviderRow}>
                  <View style={{ flex: 1 }}>
                    <Text>Service provider website</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text>{nonAceServiceProvider.website}</Text>
                  </View>
                </View>
                {Some(nonAceServiceProvider.contactName) && (
                  <View style={styles.nonAceServiceProviderRow}>
                    <View style={{ flex: 1 }}>
                      <Text>Contact name (optional)</Text>
                    </View>
                    <View style={{ flex: 2 }}>
                      <Text>{nonAceServiceProvider.contactName}</Text>
                    </View>
                  </View>
                )}
                {Some(nonAceServiceProvider.contactTitle) && (
                  <View style={styles.nonAceServiceProviderRow}>
                    <View style={{ flex: 1 }}>
                      <Text>Contact title (optional)</Text>
                    </View>
                    <View style={{ flex: 2 }}>
                      <Text>{nonAceServiceProvider.contactTitle}</Text>
                    </View>
                  </View>
                )}
              </View>
            </>
          )}

          <View
            style={[
              styles.row,
              {
                ...(None(serviceProvider?.number) &&
                  Some(nonAceServiceProvider) && {
                    borderTopColor: "#C1C7D0",
                    borderTopWidth: 1,
                  }),
              },
            ]}
          >
            <View style={{ flex: 1 }}>
              <Text>Service category</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "serviceCategory-ClaimScreen" })}>
                {getServiceCategoryLabel(category)}
              </Text>
            </View>
          </View>

          {Some(description) && (
            <View style={styles.row}>
              <View style={{ flex: 1 }}>
                <Text>What educational benefits did your child receive?</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text {...setTestProps({ name: "educationalBenefits-ClaimScreen" })}>{description}</Text>
              </View>
            </View>
          )}

          {Some(physicalGood) && (
            <View style={styles.row}>
              <View style={{ flex: 1 }}>
                <Text>Is this for a physical good?</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text {...setTestProps({ name: "physicalGood-ClaimScreen" })}>{physicalGood ? "Yes" : "No"}</Text>
              </View>
            </View>
          )}

          <View style={styles.row}>
            <View style={{ alignItems: "center", flex: 1, flexDirection: "row" }}>
              <Text>Date of Service</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "serviceDate-ClaimScreen" })}>
                {dayjs(dateOfService).format("MM/DD/YYYY")}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ flex: 1 }}>
              <Text>Claim amount</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "claimAmount-ClaimScreen" })}>{formatCurrency(amount)}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ flex: 1 }}>
              <Text>Deliver funds to </Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "payTo-ClaimScreen" })}>
                {payTo === "User" ? "Student (Parent/Guardian)" : "Service Provider"}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ flex: 1 }}>
              <Text>Account/invoice/receipt number</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text {...setTestProps({ name: "receiptNumber-ClaimScreen" })}>
                {files
                  .filter((_) => Some(_.receiptNumber))
                  .map((_) => _.receiptNumber)
                  .join(", ")}
              </Text>
            </View>
          </View>

          <View style={[styles.row, { borderBottomWidth: 0 }]}>
            <View style={{ flex: 1 }}>
              <Text>Receipt or Invoice</Text>
            </View>
            <View style={{ flex: 2 }}>
              {files.map((file, index) => (
                <Pressable
                  key={file.filename}
                  onPress={() => {
                    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
                    Platform.OS === "web" ? window.open(file.url) : openURL(file.url);
                  }}
                  style={{ marginBottom: 20 }}
                >
                  {file.detectedFileExt === "pdf" ? (
                    <Text style={{ color: "#006699" }}>{file.filename}</Text>
                  ) : (
                    <Image
                      {...setTestProps({ name: `${index}-InvoiceImage-ClaimScreen` })}
                      source={{ uri: file.url }}
                      style={{ height: 200, width: 200 }}
                    />
                  )}
                </Pressable>
              ))}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
