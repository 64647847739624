import { Button } from "../../components/Button";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { Select } from "../../components/Select";
import { Some } from "../../utils/Some";
import { TextInput } from "../../components/TextInput";
import { setTestProps } from "../../utils/propHelper";
import CloseIcon from "../../../assets/icons/close_l_action.png";
import React, { useState } from "react";

type Reason = {
  readonly description?: string;
  readonly reason: string;
};

type Props = {
  readonly onCancel: () => void;
  readonly onReject: (reason: Reason) => void;
};

const styles = StyleSheet.create({
  fieldItem: {
    paddingVertical: 16,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
  },
});

type ReasonOption = {
  readonly reason: string;
  readonly needsDescription: boolean;
};

const REASON_OPTIONS: readonly ReasonOption[] = [
  { needsDescription: false, reason: "Claim does not meet the program requirements" },
  { needsDescription: false, reason: "Incorrect service provider" },
  { needsDescription: false, reason: "Insufficient information provided" },
  { needsDescription: false, reason: "Receipt/Invoice does not match claim" },
  { needsDescription: true, reason: "Other (Write in a reason)" },
];

export const RejectModal = ({ onCancel, onReject }: Props) => {
  const [selectedReasonOption, setSelectedReasonOption] = useState<ReasonOption>();
  const [description, setDescription] = useState<string>();

  // Gross, can't I do this better?
  const needsDescription = selectedReasonOption?.needsDescription ?? false;
  const descriptionIsProvided = Some(description) && description.trim() !== "";
  const descriptionValidOrNotNeeded = !needsDescription || descriptionIsProvided;
  const canSubmit = Some(selectedReasonOption) && descriptionValidOrNotNeeded;

  return (
    <View>
      <View style={styles.header}>
        <Text style={{ fontSize: 20, fontWeight: "600" }} {...setTestProps({ name: "title-RejectModal" })}>
          Reject this claim
        </Text>

        <Pressable onPress={onCancel}>
          <Image
            accessibilityLabel="Close"
            accessible
            resizeMode="contain"
            source={CloseIcon}
            style={{ height: 14, width: 14 }}
            {...setTestProps({ name: "closeIcon-RejectModal" })}
          />
        </Pressable>
      </View>

      <View style={[styles.fieldItem, { zIndex: 4 }]}>
        <Text>Rejection reason</Text>
        <Select
          onSelectOption={(option) => {
            setSelectedReasonOption(REASON_OPTIONS.find((_) => _.reason === option?.value));
          }}
          options={REASON_OPTIONS.map(({ reason }) => ({
            label: reason,
            value: reason,
          }))}
          testID="rejectionReasonDropdown-RejectModal"
        />
      </View>

      <View style={[styles.fieldItem, { zIndex: 1 }]}>
        <View style={{ paddingBottom: 8 }}>
          <Text>Additional description of reason ({needsDescription ? "required" : "optional"})</Text>
        </View>

        <View style={{ zIndex: 99 }}>
          <TextInput
            height={80}
            multiline
            numberOfLines={4}
            onChangeText={setDescription}
            testID="descriptionInputField-RejectModal"
          />
        </View>
      </View>

      <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
        <Button onPress={onCancel} testID="cancelButton-RejectModal" text="Cancel" type="secondary" />
        <View style={{ width: 8 }} />

        <Button
          disabled={!canSubmit}
          onPress={() => {
            if (!canSubmit) {
              return;
            }

            onReject({
              description: Some(description) ? description.trim() : undefined,
              reason: selectedReasonOption.reason,
            });
          }}
          testID="rejectButton-RejectModal"
          text="Reject"
          type="destructive"
        />
      </View>
    </View>
  );
};
