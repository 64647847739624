export const HELP_EMAIL_ADDRESS = "help@keep.ks.gov";

export const SERVICE_PROVIDER_HELP_EMAIL_ADDRESS = "qesp-help@keep.ks.gov";

export const MARKETPLACE_URL = "https://keep.ks.gov/marketplace";

export const HELP_URL = "https://help.keep.ks.gov";

export const HELP_URL_EMAIL_NOT_RECEIVED =
  "https://help.keep.ks.gov/article/91-i-have-not-received-an-email-from-merit";
