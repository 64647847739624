// Copyright 2022 Merit International Inc. All Rights Reserved

import { Button } from "../components/Button";
import { ChildCard } from "../components/ChildCard";
import { HELP_URL, PRIMARY_THEME_COLOR } from "../constants";
import { Image, Platform, Pressable, SafeAreaView, ScrollView, StatusBar, StyleSheet, Text, View } from "react-native";
import { None } from "../utils/None";
import { SwitchLanguageButton } from "../components/SwitchLanguageButton";
import { UserType, useUserStore } from "../store/userStore";
import { openURL } from "../utils/openURL";
import { setTestProps } from "../utils/propHelper";
import { useApi } from "../services/useApi";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useLogout } from "../hooks/useLogout";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "../hooks/useTranslation";
import Bank from "../../assets/icons/bank.png";
import Help from "../../assets/icons/help.png";
import Information from "../../assets/icons/information.png";
import InformationOutlined from "../../assets/icons/information_outlined_m_default.png";
import React, { useEffect, useState } from "react";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParamsAll } from "../Routes";

export const Dashboard = () => {
  const i18n = useTranslation();
  const { logout } = useLogout();
  const { userClient } = useApi();
  const { errorHandler } = useDefaultErrorHandler();
  const { setUser, user } = useUserStore();

  if (None(user)) {
    throw new Error("Could not load user details");
  }

  if (user.type !== UserType.PARENT) {
    throw new Error("Logged in user is not a parent");
  }

  const parent = user;

  const { isDesktopOrLarger } = useDeviceSize();
  const [showConnectBankHelpText, setShowConnectBankHelpText] = useState(false);

  const navigation = useNavigation<NativeStackNavigationProp<RouteParamsAll>>();

  const styles = StyleSheet.create({
    buttonsContainer: {
      alignItems: "center",
      flexDirection: "row",
    },
    buttonsSeparator: {
      alignSelf: "stretch",
      backgroundColor: "pink",
      borderColor: "#FFFFFF",
      borderRightWidth: 1,
      marginRight: 16,
    },
    container: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      flex: 1,
      paddingTop: 24,
    },
    header: {
      alignItems: "center",
      backgroundColor: PRIMARY_THEME_COLOR,
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 24,
      paddingVertical: 24,
    },
    headerText: {
      color: "#FFFFFF",
      fontSize: 20,
      fontWeight: "600",
    },
    helpCenterButtonContainer: {
      alignItems: "center",
      flexDirection: "row",
      marginRight: 16,
    },
    helpCenterButtonIcon: {
      height: 20,
      width: 20,
    },
    helpCenterButtonText: {
      color: "#FFFFFF",
      fontWeight: "600",
      marginLeft: 8,
    },
    link: {
      color: "#000000",
      fontSize: 14,
      paddingVertical: 8,
      textAlign: "center",
      textDecorationLine: "none",
    },
    linkWrapper: {
      backgroundColor: "#6CADDF",
      borderColor: `solid 1px ${PRIMARY_THEME_COLOR}`,
      borderRadius: 4,
      borderStyle: "solid",
      borderWidth: 1,
      marginBottom: 8,
      opacity: 0.9,
      width: Platform.OS === "web" ? 200 : "80%",
    },
    scrollViewContent: {
      flex: 1,
      paddingHorizontal: 24,
      width: isDesktopOrLarger ? "40%" : "100%",
    },
    switchLanguageButton: {
      marginRight: 16,
    },
  });

  useEffect(() => {
    const getUser = async () => {
      try {
        const userFromDB = await userClient.getParent();
        setUser(userFromDB);
      } catch (error: unknown) {
        errorHandler(error);
      }
    };

    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectBankHelpText = (
    <View style={{ paddingVertical: 8 }}>
      <Text {...setTestProps({ name: "connectBankHelpMessage-Dashboard" })}>
        {parent.hasProvidedBankDetails
          ? i18n.t("Dashboard.reconnectBankDescription")
          : i18n.t("Dashboard.connectBankDescription")}
      </Text>
    </View>
  );

  return (
    <SafeAreaView style={{ backgroundColor: "#fff", flex: 1 }}>
      <StatusBar backgroundColor={PRIMARY_THEME_COLOR} />
      <View style={styles.header}>
        <Text style={styles.headerText} {...setTestProps({ name: "title-Dashboard" })}>
          {i18n.t("Dashboard.title")}
        </Text>
        <View style={styles.buttonsContainer}>
          <SwitchLanguageButton style={styles.switchLanguageButton} testID="loggedInHome" theme="light" />
          {isDesktopOrLarger ? <View style={styles.buttonsSeparator} /> : null}
          <Pressable
            hitSlop={8}
            onPress={() => {
              // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
              Platform.OS === "web" ? window.open(HELP_URL) : openURL(HELP_URL);
            }}
            style={styles.helpCenterButtonContainer}
          >
            <Image
              {...setTestProps({ name: "helpIcon-Dashboard" })}
              accessible
              source={Help}
              style={styles.helpCenterButtonIcon}
            />
            {isDesktopOrLarger ? (
              <Text style={styles.helpCenterButtonText} {...setTestProps({ name: "helpLink-Dashboard" })}>
                {i18n.t("Dashboard.helpCenter")}
              </Text>
            ) : null}
          </Pressable>
          <Button
            onPress={() => {
              logout();
            }}
            size="small"
            testID="logoutButton-Dashboard"
            text={i18n.t("Dashboard.logout")}
            type="secondary"
          />
        </View>
      </View>
      <View style={styles.container}>
        <ScrollView showsVerticalScrollIndicator={false} style={{ width: "100%" }}>
          <View style={{ alignItems: "center" }}>
            <View style={styles.scrollViewContent}>
              {(!parent.hasProvidedBankDetails || !parent.hasHealthyBankConnection) && (
                <>
                  <View style={{ flexDirection: "row", paddingVertical: 8 }}>
                    <View style={{ width: "80%" }}>
                      <Text
                        style={{ fontSize: 20, fontWeight: "600" }}
                        {...setTestProps({ name: "connectBankAccountText-Dashboard" })}
                      >
                        {parent.hasProvidedBankDetails
                          ? i18n.t("Dashboard.reconnectBankTitle")
                          : i18n.t("Dashboard.connectBankTitle")}
                      </Text>
                      {isDesktopOrLarger ? connectBankHelpText : <>{showConnectBankHelpText && connectBankHelpText}</>}
                    </View>
                    {isDesktopOrLarger ? (
                      <View>
                        <Image accessibilityLabel="bank" accessible source={Bank} style={{ height: 82, width: 82 }} />
                      </View>
                    ) : (
                      <View>
                        <Pressable
                          onPress={() => {
                            setShowConnectBankHelpText((prevState) => !prevState);
                          }}
                        >
                          <Image
                            accessibilityLabel="information"
                            accessible
                            source={showConnectBankHelpText ? Information : InformationOutlined}
                            style={{ height: 20, width: 20 }}
                            {...setTestProps({ name: "informationIcon-Dashboard" })}
                          />
                        </Pressable>
                      </View>
                    )}
                  </View>

                  <View style={styles.linkWrapper}>
                    <Pressable
                      accessibilityHint="Navigates to bank account form, please complete the form"
                      accessibilityLabel="Connect bank account"
                      accessible
                      onPress={() => {
                        navigation.navigate("ParentLinkBankAccountScreen");
                      }}
                      {...setTestProps({ name: "connectBankAccountLink-Dashboard" })}
                    >
                      <Text style={styles.link}>{i18n.t("Dashboard.connectBankButtonText")}</Text>
                    </Pressable>
                  </View>
                </>
              )}

              {parent.children.map((child) => (
                <View key={child.id} style={{ paddingVertical: 12 }}>
                  <ChildCard {...child} />
                </View>
              ))}
            </View>
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};
