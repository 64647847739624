import { Button, Platform, Text } from "react-native";
import { CenteredContent } from "../../Layouts/CenteredContent";
import { Some } from "../../utils/Some";
import { reloadApp } from "../../utils/reloadApp/reloadApp";
import { useLogout } from "../../hooks/useLogout";
import React from "react";

type Props = {
  readonly heading: string;
  readonly description?: string;
};

export const ErrorScreen = ({ description, heading }: Props) => {
  const { logout } = useLogout();

  const startAgain = () => {
    try {
      logout();
      // Just swallow errors, in case the problem is somehow to do with localStorage etc..
    } catch {}

    reloadApp();
  };

  return (
    <CenteredContent>
      <Text style={{ marginBottom: 40 }}>{heading}</Text>

      {Some(description) && <Text style={{ marginBottom: 40 }}>{description}</Text>}

      {Platform.OS === "web" ? (
        <a href="/" onClick={startAgain}>
          Start again from homepage
        </a>
      ) : (
        <Button onPress={startAgain} title="Start again" />
      )}
    </CenteredContent>
  );
};
