/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit/form214: Import custom error for 400 status code
import { Error400 } from "../../exceptions/Error400";
// merit/form214

import { CreateAgentPayload } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

// merit/form214: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit/form214

export class Agents<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags agents
   * @name GetAgents
   * @summary Retrieve Agents
   * @request GET:/v1/agents
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getAgents = (params: RequestParams = {}) =>
    this.request<
      {
        /** The agent list. */
        agents?: {
          /** @format uuid4 */
          id: string;
          accessType: "login" | "link";
          state: "live" | "removed";
          status: "live" | "paused";
          name: string;
          description: string;
          /**
           * The org that owns this agent.
           * @format uuid4
           */
          org: string;
          privacyTos: string;
          usageTos: string;
          dataTos: string;
          /** The capabilities that this agent requires */
          capabilities?: {
            /** The unique key that identifies this capability, e.g. read-container */
            key: string;
            /** A human readable version of key, e.g. Read Container */
            name: string;
            /** A description of what this capability allows an agent to do */
            description: string;
            objects?: {
              /** The object attribute that determines which objects the agent can act on */
              identifier: string;
              /** The value the object's attribute must match for the capability to apply */
              value: string;
              /** Determines how to interpret the value field */
              valueType: "string" | "number";
            }[];
          }[];
          additionals?: Record<string, string>;
          /** The Auth0 client ID for an agent */
          auth0ClientId?: string;
          /** The Auth0 client secret for an agent */
          auth0ClientSecret?: string;
          /** Auth0 options associated with agent */
          auth0Options?: {
            /** logout urls allowed in auth0 for this agent */
            allowedLogoutURLs?: string[];
            /** origins allowed in auth0 for this agent */
            allowedOrigins?: string[];
            /** auth0 callbacks for this agent */
            callbacks?: string[];
            /** auth0 web origins for this agent */
            webOrigins?: string[];
            /**
             * logo uri for this agent
             * @format uri
             */
            logoURI?: string;
          } | null;
        }[];
      },
      {
        /** Error code. */
        code: number;
        /** Error explanation. */
        errors: string[];
      }
    >({
      path: `/v1/agents`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @tags agents
   * @name CreateAgent
   * @summary Create an Agent
   * @request POST:/v1/agents
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  createAgent = async (
    agent: CreateAgentPayload,
    params: RequestParams = {},
  ): Promise<
    Response<{
      /** @format uuid4 */
      id: string;
      accessType: "login" | "link";
      state: "live" | "removed";
      status: "live" | "paused";
      name: string;
      description: string;
      /**
       * The org that owns this agent.
       * @format uuid4
       */
      org: string;
      privacyTos: string;
      usageTos: string;
      dataTos: string;
      /** The capabilities that this agent requires */
      capabilities?: {
        /** The unique key that identifies this capability, e.g. read-container */
        key: string;
        /** A human readable version of key, e.g. Read Container */
        name: string;
        /** A description of what this capability allows an agent to do */
        description: string;
        objects?: {
          /** The object attribute that determines which objects the agent can act on */
          identifier: string;
          /** The value the object's attribute must match for the capability to apply */
          value: string;
          /** Determines how to interpret the value field */
          valueType: "string" | "number";
        }[];
      }[];
      additionals?: Record<string, string>;
      /** The Auth0 client ID for an agent */
      auth0ClientId?: string;
      /** The Auth0 client secret for an agent */
      auth0ClientSecret?: string;
      /** Auth0 options associated with agent */
      auth0Options?: {
        /** logout urls allowed in auth0 for this agent */
        allowedLogoutURLs?: string[];
        /** origins allowed in auth0 for this agent */
        allowedOrigins?: string[];
        /** auth0 callbacks for this agent */
        callbacks?: string[];
        /** auth0 web origins for this agent */
        webOrigins?: string[];
        /**
         * logo uri for this agent
         * @format uri
         */
        logoURI?: string;
      } | null;
    }>
  > => {
    try {
      const response = await this.request<
        {
          /** @format uuid4 */
          id: string;
          accessType: "login" | "link";
          state: "live" | "removed";
          status: "live" | "paused";
          name: string;
          description: string;
          /**
           * The org that owns this agent.
           * @format uuid4
           */
          org: string;
          privacyTos: string;
          usageTos: string;
          dataTos: string;
          /** The capabilities that this agent requires */
          capabilities?: {
            /** The unique key that identifies this capability, e.g. read-container */
            key: string;
            /** A human readable version of key, e.g. Read Container */
            name: string;
            /** A description of what this capability allows an agent to do */
            description: string;
            objects?: {
              /** The object attribute that determines which objects the agent can act on */
              identifier: string;
              /** The value the object's attribute must match for the capability to apply */
              value: string;
              /** Determines how to interpret the value field */
              valueType: "string" | "number";
            }[];
          }[];
          additionals?: Record<string, string>;
          /** The Auth0 client ID for an agent */
          auth0ClientId?: string;
          /** The Auth0 client secret for an agent */
          auth0ClientSecret?: string;
          /** Auth0 options associated with agent */
          auth0Options?: {
            /** logout urls allowed in auth0 for this agent */
            allowedLogoutURLs?: string[];
            /** origins allowed in auth0 for this agent */
            allowedOrigins?: string[];
            /** auth0 callbacks for this agent */
            callbacks?: string[];
            /** auth0 web origins for this agent */
            webOrigins?: string[];
            /**
             * logo uri for this agent
             * @format uri
             */
            logoURI?: string;
          } | null;
        },
        {
          /** Error code. */
          code: number;
          /** Error explanation. */
          errors: string[];
        }
      >({
        path: `/v1/agents`,
        method: "POST",
        body: agent,
        type: ContentType.Json,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @tags agents
   * @name GetAgent
   * @summary Retrieve an Agent by id
   * @request GET:/v1/agents/{agentID}
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  getAgent = async (
    agentId: string,
    params: RequestParams = {},
  ): Promise<
    Response<{
      /** @format uuid4 */
      id: string;
      accessType: "login" | "link";
      state: "live" | "removed";
      status: "live" | "paused";
      name: string;
      description: string;
      /**
       * The org that owns this agent.
       * @format uuid4
       */
      org: string;
      privacyTos: string;
      usageTos: string;
      dataTos: string;
      /** The capabilities that this agent requires */
      capabilities?: {
        /** The unique key that identifies this capability, e.g. read-container */
        key: string;
        /** A human readable version of key, e.g. Read Container */
        name: string;
        /** A description of what this capability allows an agent to do */
        description: string;
        objects?: {
          /** The object attribute that determines which objects the agent can act on */
          identifier: string;
          /** The value the object's attribute must match for the capability to apply */
          value: string;
          /** Determines how to interpret the value field */
          valueType: "string" | "number";
        }[];
      }[];
      additionals?: Record<string, string>;
      /** The Auth0 client ID for an agent */
      auth0ClientId?: string;
      /** The Auth0 client secret for an agent */
      auth0ClientSecret?: string;
      /** Auth0 options associated with agent */
      auth0Options?: {
        /** logout urls allowed in auth0 for this agent */
        allowedLogoutURLs?: string[];
        /** origins allowed in auth0 for this agent */
        allowedOrigins?: string[];
        /** auth0 callbacks for this agent */
        callbacks?: string[];
        /** auth0 web origins for this agent */
        webOrigins?: string[];
        /**
         * logo uri for this agent
         * @format uri
         */
        logoURI?: string;
      } | null;
    }>
  > => {
    try {
      const response = await this.request<
        {
          /** @format uuid4 */
          id: string;
          accessType: "login" | "link";
          state: "live" | "removed";
          status: "live" | "paused";
          name: string;
          description: string;
          /**
           * The org that owns this agent.
           * @format uuid4
           */
          org: string;
          privacyTos: string;
          usageTos: string;
          dataTos: string;
          /** The capabilities that this agent requires */
          capabilities?: {
            /** The unique key that identifies this capability, e.g. read-container */
            key: string;
            /** A human readable version of key, e.g. Read Container */
            name: string;
            /** A description of what this capability allows an agent to do */
            description: string;
            objects?: {
              /** The object attribute that determines which objects the agent can act on */
              identifier: string;
              /** The value the object's attribute must match for the capability to apply */
              value: string;
              /** Determines how to interpret the value field */
              valueType: "string" | "number";
            }[];
          }[];
          additionals?: Record<string, string>;
          /** The Auth0 client ID for an agent */
          auth0ClientId?: string;
          /** The Auth0 client secret for an agent */
          auth0ClientSecret?: string;
          /** Auth0 options associated with agent */
          auth0Options?: {
            /** logout urls allowed in auth0 for this agent */
            allowedLogoutURLs?: string[];
            /** origins allowed in auth0 for this agent */
            allowedOrigins?: string[];
            /** auth0 callbacks for this agent */
            callbacks?: string[];
            /** auth0 web origins for this agent */
            webOrigins?: string[];
            /**
             * logo uri for this agent
             * @format uri
             */
            logoURI?: string;
          } | null;
        },
        {
          /** Error code. */
          code: number;
          /** Error explanation. */
          errors: string[];
        }
      >({
        path: `/v1/agents/${agentId}`,
        method: "GET",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @tags agents
   * @name GetAgentLinks
   * @summary Get links to the agent for the entity specified in the Authorization header
   * @request GET:/v1/agents/{agentID}/links
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getAgentLinks = (agentId: string, params: RequestParams = {}) =>
    this.request<
      {
        /** True if the entity has accepted the agent's terms of service */
        tosAccepted: boolean;
        /** True if the entity has approved the agent's capabilities */
        capabilitiesApproved: boolean;
      },
      {
        /** Error code. */
        code: number;
        /** Error explanation. */
        errors: string[];
      }
    >({
      path: `/v1/agents/${agentId}/links`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @tags agents
   * @name LinkAgent
   * @summary Create a Link
   * @request PUT:/v1/agents/{agentID}/link/{entityID}
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  linkAgent = async (agentId: string, entityId: string, params: RequestParams = {}): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          /** Error code. */
          code: number;
          /** Error explanation. */
          errors: string[];
        }
      >({
        path: `/v1/agents/${agentId}/link/${entityId}`,
        method: "PUT",
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @tags agents
   * @name AcceptEntityTos
   * @summary Accept ToS/Privacy Policies
   * @request POST:/v1/agents/{agentID}/accept-tos
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  acceptEntityTos = (agentId: string, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error code. */
        code: number;
        /** Error explanation. */
        errors: string[];
      }
    >({
      path: `/v1/agents/${agentId}/accept-tos`,
      method: "POST",
      ...params,
    });
}
