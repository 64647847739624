import { None } from "../utils/None";
import { config } from "../config/config";
import { showToast } from "../utils/showToast";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { useConfigurationStore } from "../store/configurationStore";
import { useUserStore } from "../store/userStore";
import type { MeritUserInfo } from "../types/user";
import type { UserType } from "../store/userStore";

const useLogin = (userType: UserType) => {
  const { loginWithPopup, logout } = useAuth0();
  const { setUserType } = useUserStore();
  const { configuration } = useConfigurationStore();

  if (None(configuration)) {
    throw new Error("Somehow configuration is not loaded yet!");
  }

  const doPkceFlow = async () => {
    try {
      await loginWithPopup({
        authorizationParams: { audience: configuration.auth.audience, prompt: "login", scope: config.auth0.scopes },
      });

      setUserType(userType);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`Failed to login: ${String(err)}`);
      showToast({
        message: "There was a problem logging in",
        onClose: () => {
          logout({ logoutParams: { returnTo: window.location.origin } });
        },
      });
    }
  };

  return doPkceFlow;
};

const useAccessToken = () => {
  const { configuration } = useConfigurationStore();

  const { getAccessTokenSilently } = useAuth0();
  const getAccessToken = useCallback(async () => {
    if (None(configuration)) {
      throw new Error("Somehow configuration is not loaded yet!");
    }

    const accessToken = await getAccessTokenSilently({
      authorizationParams: { audience: configuration.auth.audience, scope: config.auth0.scopes },
    });

    return accessToken;

    // configuration is not included in the dependency array of useCallback due to circular dependency concerns.
    // Including configuration in the dependency array could lead to infinite re-renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently]);

  return getAccessToken;
};

const useMeritAuth0 = () => {
  const { isAuthenticated, isLoading, user } = useAuth0<MeritUserInfo>();

  return { isAuthenticated, isLoading, user };
};

/**
 * Logs the user out from Auth0.
 *
 * @warn Use the useLogout hook for a more appropriate logout mechanism
 */

const useLogoutFromAuth0 = () => {
  const { logout: logoutAuth0 } = useAuth0();
  const logout = useCallback(async () => {
    await logoutAuth0();
  }, [logoutAuth0]);

  return logout;
};

export { useLogin, useMeritAuth0, useLogoutFromAuth0, useAccessToken };
