import "react-native-get-random-values";
import * as ExpoDocumentPicker from "expo-document-picker";
import { Button } from "./Button";
import { ErrorMessage } from "formik";
import { Loading } from "./Loading";
import { Some } from "../utils/Some";
import { Text, View } from "react-native";
import { setTestProps } from "../utils/propHelper";
import { useTranslation } from "../hooks/useTranslation";
import { v4 as uuidv4 } from "uuid";
import React, { useState } from "react";

type DocumentPickerSuccessResult = Exclude<ExpoDocumentPicker.DocumentPickerResult, { readonly canceled: true }>;
type ExtendedDocumentPickerResult = Pick<DocumentPickerSuccessResult, "assets" | "canceled"> & {
  readonly id: string;
  readonly base64?: string;
};

type Props = {
  readonly afterUpload: (fileId: string) => void;
  readonly data: readonly ExtendedDocumentPickerResult[];
  readonly setFieldError: (field: string, message: string | undefined) => void;
  readonly setFieldValue: (field: string, data: readonly ExtendedDocumentPickerResult[]) => void;
  readonly beforeUpload: () => void;
  readonly onError: (message: string) => void;
  readonly invoiceError?: string;
  readonly testID?: string;
};

const MAX_NUMBER_OF_FILES = 5;

const MAX_FILE_SIZE = 10;

const VALID_FILE_EXTENSIONS = ["jpeg", "png", "webp", "pdf", "jpg"];

export const DocumentPicker = ({
  afterUpload,
  beforeUpload,
  data,
  invoiceError,
  onError,
  setFieldValue,
  testID,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const i18n = useTranslation();

  const pickDocument = async () => {
    beforeUpload();
    if (data.length >= MAX_NUMBER_OF_FILES) {
      return;
    }

    const result = await ExpoDocumentPicker.getDocumentAsync({
      copyToCacheDirectory: false,
      type: ["image/jpeg", "image/png", "image/webp", "application/pdf"],
    });

    if (result.canceled) {
      setIsLoading(false);

      return;
    }

    result.assets.forEach((asset) => {
      if (Some(asset)) {
        const { mimeType, size, uri } = asset;
        if (Some(size)) {
          if (size / (1024 * 1024) >= MAX_FILE_SIZE) {
            onError("Please select a file which is below 10mb");

            return;
          }
        }
        if (Some(mimeType)) {
          if (!VALID_FILE_EXTENSIONS.includes(mimeType.split("/")[1])) {
            setIsLoading(false);
            onError(`Selected ${mimeType} file type not supported`);

            return;
          }
        }
        if (Some(uri)) {
          const [base64] = uri.split(",").slice(-1);
          if (data.some((_) => _.base64 === base64)) {
            setIsLoading(false);
            onError("File already uploaded, please select another");

            return;
          }
          const id = uuidv4();
          setFieldValue("invoice", [...data, { ...result, base64, id }]);
          afterUpload(id);
          setIsLoading(false);
        }
      }
    });
  };

  return (
    <>
      <View style={{ alignItems: "flex-start", width: 200 }}>
        <Button
          customContent={
            isLoading ? (
              <View style={{ minWidth: 160 }}>
                <Loading />
              </View>
            ) : undefined
          }
          disabled={data.length >= MAX_NUMBER_OF_FILES || isLoading}
          onPress={() => {
            pickDocument();
          }}
          testID={testID}
          text={i18n.t("DocumentPicker.uploadButtonText")}
          type="secondary"
        />
      </View>
      <ErrorMessage name="invoice">
        {(error) => (
          <View style={{ paddingVertical: 8 }}>
            <Text
              style={{ color: "#D03931", fontSize: 12 }}
              {...setTestProps({ name: `${error}-ErrorMessage-DocumentPicker` })}
            >
              {error}
            </Text>
          </View>
        )}
      </ErrorMessage>

      {Some(invoiceError) && (
        <View style={{ paddingVertical: 8 }}>
          <Text
            style={{ color: "#D03931", fontSize: 12 }}
            {...setTestProps({ name: `${invoiceError}-ErrorMessage-DocumentPicker` })}
          >
            {invoiceError}
          </Text>
        </View>
      )}
    </>
  );
};
