import { Loading } from "../components/Loading";
import { Router } from "../Router";
import { ToastMessage } from "../utils/ToastMessage";
import { UpgradeAppScreen } from "../screens/UpgradeAppScreen";
import { useMeritAuth0 } from "../hooks/auth";
import { useShowUpgradeScreen } from "../hooks/useShowUpgradeScreen";
import React from "react";

export function AppRoot() {
  const { isLoading: isAuth0Loading } = useMeritAuth0();
  const { isLoading: isUpgradeScreenLoading, showUpgradeAppScreen } = useShowUpgradeScreen();

  if (showUpgradeAppScreen) {
    return <UpgradeAppScreen />;
  }

  if (isAuth0Loading || isUpgradeScreenLoading) {
    return <Loading />;
  }

  return (
    <ToastMessage>
      <Router />
    </ToastMessage>
  );
}
