import { Image, StyleSheet, Text, View } from "react-native";
import { Loading } from "./Loading";
import { None } from "../utils/None";
import { Table } from "./Table";
import { formatCurrency } from "../utils/FormatHelper";
import { setTestProps } from "../utils/propHelper";
import { useApi } from "../services/useApi";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";
import { useEffect, useMemo, useState } from "react";
import { useUserStore } from "../store/userStore";
import DocumentWithSearchmark from "../../assets/images/document-with-searchmark.png";
import TableLoader from "../../assets/images/table-loader.png";
import dayjs from "dayjs";
import type { GetPendingInternalReviewClaimsResponse } from "../__generated__/api/ServiceProviderRoute";
import type { TableColumn } from "./Table/types";

const styles = StyleSheet.create({
  boldText: {
    fontFamily: "Proxima Nova",
    fontSize: 28,
    fontWeight: "600",
    paddingVertical: 40,
  },
  centeredView: {
    alignItems: "center",
    height: "auto",
    justifyContent: "center",
    padding: 40,
    width: "100%",
  },
  content: {
    height: "100%",
    width: "100%",
  },
});

type Props = {
  readonly isSelected: boolean;
};

export const ServiceProviderPendingInternalReviewClaims = ({ isSelected }: Props) => {
  const { serviceProviderClient } = useApi();
  const [pendingInternalReviewClaims, setPendingInternalReviewClaims] =
    useState<GetPendingInternalReviewClaimsResponse["pendingInternalReviewClaims"]>();
  const { errorHandler } = useDefaultErrorHandler();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { selectedOrg } = useUserStore();

  useEffect(() => {
    const getPendingInternalReviewClaims = async () => {
      try {
        if (None(selectedOrg)) {
          throw new Error("Service provider cannot be in this state without selecting an org");
        }
        setIsDataLoading(true);
        const response = await serviceProviderClient.getPendingInternalReviewClaims(selectedOrg.id);
        setPendingInternalReviewClaims(response.pendingInternalReviewClaims);
        setIsDataLoading(false);
      } catch (error: unknown) {
        errorHandler(error);
      }
    };

    if (isSelected) {
      getPendingInternalReviewClaims();
    }
  }, [errorHandler, isSelected, selectedOrg, serviceProviderClient]);

  // Table columns
  const columns: readonly TableColumn[] = [
    { key: "childName", title: "Student name", width: "auto" },
    { key: "parentName", title: "Parent name", width: "auto" },
    { key: "submittedDate", title: "Submit date", width: "auto" },
    { key: "id", title: "Claim ID", width: "auto" },
    { key: "amount", title: "Claim amount", width: "auto" },
    { key: "payTo", title: "Deliver funds to", width: "auto" },
    { key: "status", title: "Status", width: "auto" },
  ];

  // Table data
  const pendingInternalReviewClaimsTableData = useMemo(
    () =>
      pendingInternalReviewClaims?.map((claim) => ({
        amount: claim.amount,
        childName: `${claim.child.firstName} ${claim.child.lastName}`,
        id: claim.id,
        parentName: `${claim.parent.firstName} ${claim.parent.lastName}`,
        payTo: claim.payTo === "User" ? "Parent/Guardian" : "Service provider",
        status: claim.status === "Pending Internal Review" ? "Pending" : claim.status,
        submittedDate: dayjs(claim.createdAt).format("MM/DD/YYYY"),
      })),
    [pendingInternalReviewClaims],
  );

  if (!isSelected) {
    return null;
  }

  if (isDataLoading || None(pendingInternalReviewClaims)) {
    return (
      <View style={{ paddingVertical: 50 }}>
        <Loading />
      </View>
    );
  }

  return (
    <View style={styles.content}>
      {pendingInternalReviewClaims.length === 0 ? (
        <View style={styles.centeredView}>
          <Image
            accessibilityLabel="document with searchmark"
            accessible
            source={DocumentWithSearchmark}
            style={{ height: 95, paddingTop: 20, width: 95 }}
          />

          <Text style={styles.boldText}>You have no pending merit claims</Text>
          <Image
            accessibilityLabel="table loader"
            accessible
            source={TableLoader}
            style={{ height: 184, width: 582 }}
          />
        </View>
      ) : (
        <Table
          columns={columns}
          data={pendingInternalReviewClaimsTableData}
          emptyComponent={
            <View style={styles.centeredView}>
              <Text>You have no pending merit claims</Text>
            </View>
          }
          render={(data, key) => {
            switch (key) {
              case "amount":
                return (
                  <Text
                    {...setTestProps({
                      name: `${data.id}-${key}-ServiceProviderPendingInternalReviewClaims`,
                    })}
                  >
                    {formatCurrency(data.amount)}
                  </Text>
                );

              default:
                return (
                  <Text
                    {...setTestProps({
                      name: `${data.id}-${key}-ServiceProviderPendingInternalReviewClaims`,
                    })}
                  >
                    {data[key]}
                  </Text>
                );
            }
          }}
        />
      )}
    </View>
  );
};
