import * as Yup from "yup";
import { Button } from "../Button";
import { ConfirmationModal } from "../ConfirmationModal";
import { ErrorMessage, Formik } from "formik";
import { Loading } from "../Loading";
import { None } from "../../utils/None";
import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import { Select } from "../Select";
import { Some } from "../../utils/Some";
import { TextInput } from "../TextInput";
import { setTestProps } from "../../utils/propHelper";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useToast } from "react-native-toast-notifications";
import React, { useState } from "react";
import type { GetServiceProviderResponse } from "../../__generated__/api/ServiceProviderRoute";

type Status = "Active" | "Inactive";

// A custom toastNotificationType can also be created.
type toastNotificationType = "danger" | "normal" | "success" | "warning";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  currentServiceProviderStatus: {
    fontWeight: "bold",
  },
  header: {
    alignItems: "center",
    backgroundColor: "#202945",
    borderBottomColor: "#CCCCCC",
    borderBottomWidth: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 40,
    paddingVertical: 24,
    width: "100%",
  },
  serviceProviderContainer: {
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 12,
  },
  serviceProviderFields: {
    marginBottom: 20,
    marginTop: 20,
    padding: 10,
  },
  statusDropDown: {
    marginTop: 40,
    width: "10%",
  },
  submitButton: {
    marginTop: 80,
  },
  validationErrorMessage: {
    marginVertical: 20,
  },
});

export const UpdateServiceProviderStatus = () => {
  const toast = useToast();
  const { serviceProviderClient } = useApi();

  const [isLoading, setIsLoading] = useState(false);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const { errorHandler } = useDefaultErrorHandler();
  const [validationError, setValidationError] = useState<string>();
  const [serviceProviderNumber, setServiceProvideNumber] = useState<string>();
  const [serviceProviderDetails, setServiceProviderDetails] = useState<GetServiceProviderResponse>();
  const [selectedStatus, setSelectedStatus] = useState<Status>();

  const toastNotification = (message: string, type: toastNotificationType) => {
    toast.show(<Text {...setTestProps({ name: "message-UpdateServiceProviderStatus" })}>{message}</Text>, {
      placement: "top",
      type,
    });
  };

  const validationSchema = Yup.object().shape({
    status: Yup.string().required("Please select a status"),
  });

  const getServiceProvider = async () => {
    if (None(serviceProviderNumber)) {
      throw new Error("Somehow trying to get service provider without valid service provider number");
    }

    setIsLoading(true);
    try {
      const response = await serviceProviderClient.getServiceProvider(serviceProviderNumber);
      if (response.success) {
        setServiceProviderDetails(response.data);
      } else {
        toastNotification(response.message, "danger");
      }
    } catch (error: unknown) {
      errorHandler(error);
    }
    setIsLoading(false);
  };

  const updateServiceProviderStatus = async (status: Status) => {
    if (None(serviceProviderNumber)) {
      throw new Error("Somehow trying to update service provider status without valid service provider number");
    }

    if (None(selectedStatus)) {
      throw new Error("Please select service provider status");
    }
    setShowUpdateStatusModal(false);
    try {
      const response = await serviceProviderClient.updateServiceProviderStatus({
        number: serviceProviderNumber,
        status,
      });
      setIsLoading(true);
      if (response.success) {
        toastNotification(
          `Service Provider status successfully updated from ${
            selectedStatus === "Active" ? "Inactive" : "Active"
          } to ${selectedStatus}`,
          "success",
        );
      } else {
        toastNotification(response.message, "danger");
      }
      setServiceProvideNumber(undefined);
      setServiceProviderDetails(undefined);
    } catch (error: unknown) {
      errorHandler(error);
    }
    setIsLoading(false);
  };

  // eslint-disable-next-line react/no-multi-comp
  const ErrorText = (error: string) => (
    <View style={{ paddingVertical: 8 }}>
      <Text style={{ color: "#D03931", fontSize: 12 }}>{error}</Text>
    </View>
  );

  return (
    <SafeAreaView style={{ backgroundColor: "#FFFFFF", flex: 1 }}>
      {None(serviceProviderDetails) && (
        <View style={styles.container}>
          <View>
            <Text>Enter service provider number</Text>
            <View style={styles.serviceProviderContainer}>
              <TextInput
                keyboardType="decimal-pad"
                onChangeText={(value) => {
                  setServiceProvideNumber(value);
                  if (/^[0-9]{6}$/u.test(value)) {
                    setValidationError(undefined);
                  } else {
                    setValidationError("Please enter valid service provider number");
                  }
                }}
                placeholder="000000"
                testID="serviceProviderInputField-UpdateServiceProviderStatus"
                value={serviceProviderNumber}
              />
              <View style={[{ marginLeft: 20 }]}>
                <Button
                  customContent={
                    isLoading ? (
                      <View style={{ minWidth: 160 }}>
                        <Loading />
                      </View>
                    ) : undefined
                  }
                  disabled={isLoading || Some(validationError) || None(serviceProviderNumber)}
                  onPress={() => {
                    getServiceProvider();
                  }}
                  testID="searchButton-UpdateServiceProviderStatus"
                  text="Search"
                />
              </View>
            </View>
          </View>

          {!isLoading && Some(validationError) && (
            <Text
              style={[styles.validationErrorMessage, { color: "#FF0000" }]}
              {...setTestProps({ name: "errorMessage-UpdateServiceProviderStatus" })}
            >
              {validationError}
            </Text>
          )}
        </View>
      )}

      {Some(serviceProviderDetails) && (
        <View style={styles.container}>
          <View>
            <Text
              style={styles.serviceProviderFields}
              {...setTestProps({ name: "serviceProviderDetails-UpdateServiceProviderStatus" })}
            >{`${serviceProviderDetails.name} ${serviceProviderDetails.number}`}</Text>
          </View>

          <View>
            <Text style={styles.serviceProviderFields}>
              <Text style={styles.currentServiceProviderStatus}>Current Status</Text> :
              <span data-testid="claimStatus-UpdateServiceProviderStatus">{serviceProviderDetails.status}</span>
            </Text>
          </View>

          <Text
            style={styles.serviceProviderFields}
            {...setTestProps({ name: "spStatusMessage-UpdateServiceProviderStatus" })}
          >
            What do you want to update this Service Provider's status to?
          </Text>

          <View style={styles.statusDropDown}>
            <Formik
              initialValues={{ status: undefined }}
              onSubmit={({ status }) => {
                setSelectedStatus(status);
                setShowUpdateStatusModal(true);
              }}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, setFieldValue }) => (
                <>
                  <View style={{ zIndex: 1 }}>
                    <Select
                      onSelectOption={(option) => {
                        setFieldValue("status", option?.value);
                      }}
                      options={[
                        { label: "ACTIVE", value: "Active" },
                        { label: "INACTIVE", value: "Inactive" },
                      ].filter((_) => _.value !== serviceProviderDetails.status)}
                      testID="statusDropdown-UpdateServiceProviderStatus"
                    />
                  </View>
                  <ErrorMessage name="status">{ErrorText}</ErrorMessage>
                  <View style={styles.submitButton}>
                    <Button
                      customContent={
                        isLoading ? (
                          <View style={{ minWidth: 160 }}>
                            <Loading />
                          </View>
                        ) : undefined
                      }
                      onPress={handleSubmit}
                      testID="submitButton-UpdateServiceProviderStatus"
                      text="Submit"
                    />
                  </View>
                </>
              )}
            </Formik>
          </View>
        </View>
      )}

      {Some(selectedStatus) && (
        <ConfirmationModal
          isVisible={showUpdateStatusModal}
          onCancel={() => {
            setShowUpdateStatusModal(false);
          }}
          onConfirm={() => {
            updateServiceProviderStatus(selectedStatus);
          }}
          text={
            selectedStatus === "Inactive"
              ? "Are you sure you want to update the service provider status to inactive? Doing this will prevent the service provider from accepting any new claims."
              : "Are you sure you want to update the service provider status to active? Doing this will allow the service provider to accept new claims."
          }
        />
      )}
    </SafeAreaView>
  );
};
