export type RouteParams = {
  readonly NotEligible: undefined;
  readonly Splash: undefined;
  readonly NotFound: undefined;
  readonly ReviewClaim: {
    readonly token: string;
  };
  readonly Onboarding: undefined;
  readonly ClaimApproved: undefined;
  readonly ClaimRejected: undefined;
  readonly SentForExternalReview: undefined;
  readonly ServiceProviderLogin: undefined;
  readonly MeritCSLogin: undefined;
  readonly ServiceProviderNotEligible: undefined;
  readonly MeritCSNotEligible: undefined;
  //  post login
  readonly Dashboard: undefined;
  readonly MeritCSDashboard: undefined;
  readonly ServiceProvider: {
    readonly childId: string;
    readonly serviceProviderInfo?: string;
  };
  readonly SubmitNewClaim: {
    readonly childId: string;
    readonly serviceProviderInfo?: string;
  };
  readonly ServiceProviderDashboard: undefined;
  readonly ParentLinkBankAccountScreen: undefined;
  readonly ServiceProviderClaimScreen: {
    readonly claimId: number;
  };
};

export type PostLoginRouteParams = Pick<
  RouteParams,
  | "Dashboard"
  | "MeritCSDashboard"
  | "ParentLinkBankAccountScreen"
  | "ServiceProvider"
  | "ServiceProviderClaimScreen"
  | "ServiceProviderDashboard"
  | "SubmitNewClaim"
>;

export type PreLoginRouteParams = Pick<
  RouteParams,
  | "ClaimApproved"
  | "ClaimRejected"
  | "MeritCSLogin"
  | "MeritCSNotEligible"
  | "NotEligible"
  | "NotFound"
  | "Onboarding"
  | "ReviewClaim"
  | "SentForExternalReview"
  | "ServiceProviderLogin"
  | "ServiceProviderNotEligible"
  | "Splash"
>;

export type RouteParamsAll = PostLoginRouteParams & PreLoginRouteParams;

// Map of route name to path (url on desktop)
export const ROUTES: Record<keyof RouteParamsAll, string> = {
  ClaimApproved: "/review-claim/approved",
  ClaimRejected: "/review-claim/rejected",
  Dashboard: "/dashboard",
  MeritCSDashboard: "/merit-cs/dashboard",
  MeritCSLogin: "/merit-cs",
  MeritCSNotEligible: "/merit-cs/not-eligible",
  NotEligible: "/not-eligible",
  NotFound: "*",
  Onboarding: "/onboarding",
  ParentLinkBankAccountScreen: "/parent/bank-account",
  ReviewClaim: "/review-claim",
  SentForExternalReview: "/review-claim/sent-for-external-review",
  ServiceProvider: "/non-keep-service-provider",
  ServiceProviderClaimScreen: "/service-provider/claim",
  ServiceProviderDashboard: "/service-provider/dashboard",
  ServiceProviderLogin: "/service-provider",
  ServiceProviderNotEligible: "/service-provider/not-eligible",
  Splash: "/",
  SubmitNewClaim: "/submit-new-claim",
};
