import * as Yup from "yup";
import { Button } from "../Button";
import { ConfirmationModal } from "../ConfirmationModal";
import { ErrorMessage, Formik } from "formik";
import { Loading } from "../Loading";
import { None } from "../../utils/None";
import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import { Some } from "../../utils/Some";
import { TextInput } from "../TextInput";
import { formatCurrency } from "../../utils/FormatHelper";
import { setTestProps } from "../../utils/propHelper";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useToast } from "react-native-toast-notifications";
import React, { useState } from "react";
import validator from "validator";
import type { ManageClaimsProps } from "./type";
import type { UpdateClaimAmountPayload } from "../../__generated__/api/data-contracts";

export const UpdateClaimAmount = ({ claimDetails, claimId, getClaim }: ManageClaimsProps) => {
  const toast = useToast();
  const { claimClient } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useDefaultErrorHandler();
  const [showUpdateAmountModal, setShowUpdateAmountModal] = useState(false);
  const [updateClaimAmountPayload, setUpdateClaimAmountPayload] = useState<UpdateClaimAmountPayload>();
  const [claimAmountFormReset, setClaimAmountFormReset] = useState<() => void>();

  const styles = StyleSheet.create({
    actionContainer: {
      alignItems: "center",
      margin: 20,
    },
    claimStatus: {
      fontWeight: "bold",
    },
    claimStatusMessage: {
      marginVertical: 20,
    },
    container: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
    },
    statusDropDown: {
      marginTop: 20,
      width: 300,
    },
    submitButton: {
      marginTop: 60,
    },
  });

  const validationSchemaForClaimAmount = Yup.object().shape({
    amount: Yup.string().test({
      test: (value, ctx) => {
        if (Some(value)) {
          if (!validator.isCurrency(value)) {
            return ctx.createError({ message: "Please enter a valid amount" });
          }
        } else if (None(value)) {
          return ctx.createError({ message: "Please enter a valid amount" });
        }

        return true;
      },
    }),
    note: Yup.string().trim().required("Please provide a reason for amount update"),
  });

  const updateClaimAmount = async () => {
    if (None(claimId)) {
      throw new Error("Somehow trying to update claim amount without valid claim id");
    }

    if (None(updateClaimAmountPayload)) {
      throw new Error("Somehow trying to update claim amount without valid claim payload");
    }

    setIsLoading(true);
    try {
      const response = await claimClient.updateClaimAmount(Number(claimId), updateClaimAmountPayload);
      if (response.success) {
        toast.show(
          <Text {...setTestProps({ name: "successMessage-UpdateClaimAmount" })}>
            Successfully updated the claim amount
          </Text>,
          {
            placement: "top",
            type: "success",
          },
        );
        getClaim();
        setShowUpdateAmountModal(false);
        setUpdateClaimAmountPayload(undefined);
        if (Some(claimAmountFormReset)) {
          claimAmountFormReset();
        }
      } else {
        toast.show(<Text {...setTestProps({ name: "toastErrorMessage-UpdateClaimAmount" })}>{response.message}</Text>, {
          placement: "top",
          type: "danger",
        });
        setShowUpdateAmountModal(false);
      }
    } catch (error: unknown) {
      errorHandler(error);
      setShowUpdateAmountModal(false);
    }
    setIsLoading(false);
  };

  // eslint-disable-next-line react/no-multi-comp
  const ErrorText = (error: string) => (
    <View style={{ paddingVertical: 8 }}>
      <Text style={{ color: "#D03931", fontSize: 12 }}>{error}</Text>
    </View>
  );

  return (
    <SafeAreaView style={{ backgroundColor: "#FFFFFF", flex: 2 }}>
      <View style={styles.container}>
        <>
          {Some(claimDetails) && (
            <>
              {isLoading ? (
                <>
                  <View style={{ minWidth: 160 }}>
                    <Loading />
                  </View>
                </>
              ) : (
                <>
                  <View style={styles.actionContainer}>
                    <View>
                      <View testID="errorMessage-UpdateClaimAmount">
                        {claimDetails.status !== "Accepted" &&
                          ErrorText(
                            `You can only update amounts for claims that are ‘Accepted’. The status of this claim is ${claimDetails.status}.`,
                          )}
                      </View>

                      <View style={styles.statusDropDown}>
                        {claimDetails.status === "Accepted" && (
                          <View>
                            <>
                              <Text style={styles.claimStatusMessage}>
                                Current Amount:
                                <Text
                                  style={styles.claimStatus}
                                  testID="currentAmount-UnapproveClaim"
                                >{` ${formatCurrency(claimDetails.amount)}`}</Text>
                              </Text>
                            </>

                            <Formik
                              initialValues={{
                                amount: Some(updateClaimAmountPayload) ? updateClaimAmountPayload.amount : undefined,
                                note: Some(updateClaimAmountPayload) ? updateClaimAmountPayload.note : undefined,
                              }}
                              onSubmit={(values, { resetForm }) => {
                                setShowUpdateAmountModal(true);
                                const formValues = {
                                  amount: Number(values.amount),
                                  note: Some(values.note) ? values.note : "",
                                };
                                setUpdateClaimAmountPayload(formValues);
                                const resetFunction = () => resetForm;
                                setClaimAmountFormReset(resetFunction);
                              }}
                              validationSchema={validationSchemaForClaimAmount}
                            >
                              {({ handleSubmit, setFieldValue, values }) => (
                                <>
                                  <View style={{ marginTop: 10, zIndex: 1 }}>
                                    <TextInput
                                      keyboardType="decimal-pad"
                                      onChangeText={(value) => {
                                        setFieldValue("amount", value);
                                      }}
                                      placeholder="Enter amount to update"
                                      testID="amountInputField-UnapproveClaim"
                                      value={Some(values.amount) ? values.amount.toString() : ""}
                                    />
                                  </View>
                                  <ErrorMessage name="amount">{ErrorText}</ErrorMessage>

                                  <View style={{ marginTop: 10, zIndex: 1 }}>
                                    <TextInput
                                      onChangeText={(value) => {
                                        setFieldValue("note", value);
                                      }}
                                      placeholder="Reason for the amount update"
                                      testID="reasonInputField-UnapproveClaim"
                                      value={Some(values.note) ? values.note : ""}
                                    />
                                  </View>
                                  <ErrorMessage name="note">{ErrorText}</ErrorMessage>

                                  <View style={styles.submitButton}>
                                    <Button
                                      disabled={!(Some(values.amount) && Some(values.note)) || values.note === ""}
                                      onPress={handleSubmit}
                                      testID="submitButton-UnapproveClaim"
                                      text="Submit"
                                    />
                                  </View>
                                </>
                              )}
                            </Formik>
                          </View>
                        )}
                      </View>
                    </View>
                  </View>

                  <ConfirmationModal
                    isVisible={showUpdateAmountModal}
                    onCancel={() => {
                      setShowUpdateAmountModal(false);
                    }}
                    onConfirm={() => {
                      updateClaimAmount();
                    }}
                    text={
                      Some(updateClaimAmountPayload)
                        ? `Are you sure you want to update the claim amount from ${formatCurrency(
                            Some(claimDetails.amount) ? claimDetails.amount : 0,
                          )} to ${formatCurrency(
                            Some(updateClaimAmountPayload.amount) ? updateClaimAmountPayload.amount : 0,
                          )} ?`
                        : ""
                    }
                  />
                </>
              )}
            </>
          )}
        </>
      </View>
    </SafeAreaView>
  );
};
