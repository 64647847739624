// Copyright 2022 Merit International Inc. All Rights Reserved

import { BankAccountForm } from "../../components/BankAccountForm";
import { Button } from "../../components/Button";
import { ExternalLink } from "../../components/ExternalLink";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { Loading } from "../../components/Loading";
import { None } from "../../utils/None";
import { PRIMARY_THEME_COLOR, SERVICE_PROVIDER_HELP_EMAIL_ADDRESS } from "../../constants";
import { ServiceProviderHistoryClaims } from "../../components/ServiceProviderHistoryClaims";
import { ServiceProviderPendingInternalReviewClaims } from "../../components/ServiceProviderPendingInternalReviewClaims";
import { ServiceProviderPendingVendorApprovalClaims } from "../../components/ServiceProviderPendingVendorApprovalClaims";
import { Some } from "../../utils/Some";
import { UnreachableCaseError } from "../../utils/UnreachableCaseError";
import { UserType, useUserStore } from "../../store/userStore";
import { setTestProps } from "../../utils/propHelper";
import { useApi } from "../../services/useApi";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useLogout } from "../../hooks/useLogout";
import AsteriskIcon from "../../../assets/icons/asterisk_m.png";
import BankIcon from "../../../assets/icons/bank.png";
import React, { useEffect, useState } from "react";
import type { GetClaimStatsResponse } from "../../__generated__/api/ServiceProviderRoute";

const styles = StyleSheet.create({
  activeTabs: {
    borderBottomColor: PRIMARY_THEME_COLOR,
    borderBottomWidth: 2,
  },
  bankIconWrapper: {
    alignItems: "center",
    marginVertical: 30,
  },
  connectedBankContainer: {
    alignItems: "center",
    paddingVertical: 40,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
  },
  header: {
    alignItems: "center",
    backgroundColor: PRIMARY_THEME_COLOR,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 24,
    paddingVertical: 24,
  },
  headerText: {
    color: "#FFFFFF",
    fontSize: 20,
    fontWeight: "600",
  },
  inlineTabs: {
    backgroundColor: "#FFFFFF",
    borderBottomColor: "#D3D3D3",
    borderBottomWidth: StyleSheet.hairlineWidth,
    flexDirection: "row",
    paddingLeft: 20,
    paddingTop: 20,
  },
  tabContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    fontSize: 18,
    fontWeight: "500",
    justifyContent: "space-between",
    padding: 15,
    position: "relative",
  },
  tabsContainer: {
    flexGrow: 1,
  },
});

const tabs = ["Pending provider review", "Pending merit review", "History", "Connect bank account"] as const;

export const Dashboard = () => {
  const { serviceProviderClient, userClient } = useApi();
  const { selectedOrg, setUser } = useUserStore();
  const user = useUserStore((_) => _.user);
  const { logout } = useLogout();

  if (None(user)) {
    throw new Error("Could not load user details");
  }

  if (user.type !== UserType.SERVICE_PROVIDER) {
    throw new Error("Logged in user is not a service provider");
  }

  const serviceProvider = user;

  const { isDesktopOrLarger } = useDeviceSize();
  const [showConnectBankForm, setShowConnectBankForm] = useState(false);
  const { errorHandler } = useDefaultErrorHandler();

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [claimStats, setClaimStats] = useState<GetClaimStatsResponse>();
  const [selectedTab, setSelectedTab] = useState<(typeof tabs)[number]>("Pending provider review");

  useEffect(() => {
    const getClaimStats = async () => {
      try {
        if (None(selectedOrg)) {
          throw new Error("Service provider cannot be in this state without selecting an org");
        }
        setIsDataLoading(true);
        const response = await serviceProviderClient.getClaimStats(selectedOrg.id);
        setClaimStats(response);
        setIsDataLoading(false);
      } catch (error: unknown) {
        errorHandler(error);
      }
    };
    getClaimStats();
  }, [errorHandler, selectedOrg, serviceProviderClient]);

  const getTabDisplayName = (currentTab: (typeof tabs)[number]) => {
    switch (currentTab) {
      case "Pending provider review": {
        return Some(claimStats) && claimStats.pendingVendorApprovalCount > 0
          ? `${currentTab} (${claimStats.pendingVendorApprovalCount})`
          : currentTab;
      }
      case "Pending merit review": {
        return Some(claimStats) && claimStats.pendingInternalReviewCount > 0
          ? `${currentTab} (${claimStats.pendingInternalReviewCount})`
          : currentTab;
      }
      case "History": {
        return Some(claimStats) && claimStats.historyCount > 0
          ? `${currentTab} (${claimStats.historyCount})`
          : currentTab;
      }
      case "Connect bank account": {
        return currentTab;
      }
      default:
        throw new UnreachableCaseError(currentTab);
    }
  };

  if (isDataLoading || None(claimStats)) {
    return <Loading />;
  }

  const updateUserBankDetails = async () => {
    if (None(selectedOrg)) {
      throw new Error("Service provider cannot be in this state without selecting an org");
    }
    const updatedUser = await userClient.setHasProvidedBankDetailsForServiceProvider(selectedOrg.id);
    setUser(updatedUser);
  };

  const connectBankText = serviceProvider.hasProvidedBankDetails
    ? "Your bank account has been disconnected. Reconnect your bank account to allow parents and guardians to submit claims with unpaid invoices so grant money can be disbursed directly to your business."
    : "Connecting your bank account will allow parents and guardians to submit claims with unpaid invoices so grant money can be disbursed directly to your business.";

  return (
    <View>
      <View style={styles.header}>
        <Text style={styles.headerText} {...setTestProps({ name: "title-Dashboard" })}>
          KEEP Claims
        </Text>
        <Button
          onPress={() => {
            logout();
          }}
          size="small"
          testID="logoutButton-Dashboard"
          text="Logout"
          type="secondary"
        />
      </View>

      <View style={styles.contentContainer}>
        <View style={styles.inlineTabs}>
          {tabs.map((tab) => (
            <Pressable
              key={tab}
              onPress={() => {
                setSelectedTab(tab);
              }}
              style={[styles.tabContainer, selectedTab === tab && styles.activeTabs]}
            >
              <Text {...setTestProps({ name: `${tab}-Tab-Dashboard` })}>{getTabDisplayName(tab)}</Text>
              {tab === "Connect bank account" && !serviceProvider.hasHealthyBankConnection ? (
                <Image source={AsteriskIcon} style={{ height: 20, width: 20 }} />
              ) : null}
            </Pressable>
          ))}
        </View>

        <View style={styles.tabsContainer}>
          <ServiceProviderPendingVendorApprovalClaims isSelected={selectedTab === "Pending provider review"} />
          <ServiceProviderPendingInternalReviewClaims isSelected={selectedTab === "Pending merit review"} />
          <ServiceProviderHistoryClaims isSelected={selectedTab === "History"} />

          {selectedTab === "Connect bank account" && (
            <>
              {serviceProvider.hasProvidedBankDetails && serviceProvider.hasHealthyBankConnection ? (
                <View style={styles.connectedBankContainer}>
                  <View style={{ width: isDesktopOrLarger ? "30%" : "80%" }}>
                    <View style={styles.bankIconWrapper}>
                      <Image source={BankIcon} style={{ height: 82, width: 82 }} />
                    </View>
                    <View style={{ marginVertical: 10 }}>
                      <Text
                        {...setTestProps({ name: "accountConnectedSuccessMessage-Dashboard" })}
                        style={{ fontSize: 28, fontWeight: "600" }}
                      >
                        Congratulations! Your bank account is connected
                      </Text>
                    </View>
                    <Text {...setTestProps({ name: "assistanceLink-Dashboard" })}>
                      {"Please contact "}
                      <ExternalLink
                        text={SERVICE_PROVIDER_HELP_EMAIL_ADDRESS}
                        textStyle={{ lineHeight: 24 }}
                        url={`mailto:${SERVICE_PROVIDER_HELP_EMAIL_ADDRESS}`}
                      />
                      {" for extra assistance"}
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={{ flex: 1 }}>
                  {showConnectBankForm ? (
                    <BankAccountForm
                      onSuccess={() => {
                        updateUserBankDetails();
                      }}
                    />
                  ) : (
                    <View style={styles.connectedBankContainer}>
                      <View style={{ width: isDesktopOrLarger ? "30%" : "80%" }}>
                        <View style={styles.bankIconWrapper}>
                          <Image source={BankIcon} style={{ height: 82, width: 82 }} />
                        </View>
                        <Text
                          style={{ fontSize: 20, fontWeight: "600" }}
                          {...setTestProps({ name: "connectBankAccountHelpMessage-Dashboard" })}
                        >
                          {connectBankText}
                        </Text>
                        <View style={{ marginVertical: 10 }}>
                          <Text>
                            Note: Merit has partnered with Dwolla to manage payment processing. This button will launch
                            the Dwolla bank account information collection form.
                          </Text>
                        </View>
                        <View style={{ marginVertical: 20 }}>
                          <Button
                            onPress={() => {
                              setShowConnectBankForm(true);
                            }}
                            testID="connectBankAccountButton-Dashboard"
                            text="Connect bank account"
                          />
                        </View>
                      </View>
                    </View>
                  )}
                </View>
              )}
            </>
          )}
        </View>
      </View>
    </View>
  );
};
