// Copyright 2024 Merit International Inc. All Rights Reserved

import { ClaimApprovedScreen } from "../screens/ReviewClaim/ClaimApprovedScreen";
import { ClaimRejectedScreen } from "../screens/ReviewClaim/ClaimRejectedScreen";
import { ClaimScreen } from "../screens/ServiceProvider/ClaimScreen";
import { Dashboard } from "../screens/Dashboard";
import { LinkBankAccountScreen } from "../screens/Parent/LinkBankAccountScreen";
import { Dashboard as MeritCSDashboard } from "../screens/MeritCS/Dashboard";
import { MeritCSLogin } from "../screens/MeritCSLogin";
import { NotEligible as MeritCSNotEligible } from "../screens/MeritCS/NotEligible";
import { NotFound } from "../screens/ErrorScreens/NotFound";
import { OnboardingScreen } from "../screens/OnboardingScreen";
import { Platform } from "react-native";
import { ReviewClaimScreen } from "../screens/ReviewClaim/ReviewClaimScreen";
import { SentForExternalReviewScreen } from "../screens/ReviewClaim/SentForExternalReviewScreen";
import { ServiceProvider } from "../screens/ServiceProvider";
import { Dashboard as ServiceProviderDashboard } from "../screens/ServiceProvider/Dashboard";
import { ServiceProviderLogin } from "../screens/ServiceProviderLogin";
import { NotEligible as ServiceProviderNotEligible } from "../screens/ServiceProvider/NotEligible";
import { SplashScreen } from "../screens/SplashScreen";
import { SubmitNewClaim } from "../screens/SubmitNewClaim";
import { UserType } from "../store/userStore";
import type { NavigationProp, RouteProp } from "@react-navigation/native";
import type { RouteParamsAll } from "../Routes";
import type { User } from "../store/userStore";

type ScreenProps<RouteName extends keyof RouteParamsAll> = {
  navigation: NavigationProp<RouteParamsAll, RouteName>;
  route: RouteProp<RouteParamsAll, RouteName>;
};

type ScreenConfig<RouteName extends keyof RouteParamsAll = keyof RouteParamsAll> = {
  name: RouteName;
  component: React.ComponentType<ScreenProps<RouteName>>;
};

type NavigationParams = {
  userType: UserType | undefined;
  isAuthenticated: boolean;
  user: User | undefined;
};

export const useNavigationMap = ({ isAuthenticated, user, userType }: NavigationParams): ScreenConfig[] => {
  const commonRoutes = [
    { component: ReviewClaimScreen, name: "ReviewClaim" },
    { component: ClaimApprovedScreen, name: "ClaimApproved" },
    { component: ClaimRejectedScreen, name: "ClaimRejected" },
    { component: SentForExternalReviewScreen, name: "SentForExternalReview" },
  ] as ScreenConfig[];

  if (!isAuthenticated) {
    return [
      { component: SplashScreen, name: "Splash" },
      { component: NotFound, name: "NotFound" },
      { component: ServiceProviderNotEligible, name: "ServiceProviderNotEligible" },
      { component: ServiceProviderLogin, name: "ServiceProviderLogin" },
      { component: MeritCSNotEligible, name: "MeritCSNotEligible" },
      { component: MeritCSLogin, name: "MeritCSLogin" },
      ...commonRoutes,
    ];
  }
  if (userType === UserType.PARENT && user !== undefined) {
    return [
      { component: Dashboard, name: "Dashboard" },
      { component: SubmitNewClaim, name: "SubmitNewClaim" },
      { component: LinkBankAccountScreen, name: "ParentLinkBankAccountScreen" },
      { component: ServiceProvider, name: "ServiceProvider" },
      ...commonRoutes,
    ];
  }

  if (userType === UserType.MERIT_CS && Platform.OS === "web" && user !== undefined) {
    return [{ component: MeritCSDashboard, name: "MeritCSDashboard" }, ...commonRoutes];
  }

  if (userType === UserType.SERVICE_PROVIDER && Platform.OS === "web" && user !== undefined) {
    return [
      { component: ServiceProviderDashboard, name: "ServiceProviderDashboard" },
      { component: ClaimScreen, name: "ServiceProviderClaimScreen" },
      ...commonRoutes,
    ];
  }

  if (user === undefined) {
    return [{ component: OnboardingScreen, name: "Onboarding" }, ...commonRoutes];
  }

  // fallback to splash
  return [{ component: SplashScreen, name: "Splash" }];
};
