import { I18n } from "i18n-js";
import { en, es } from "../locales";
import { useLocaleStore } from "../store/localeStore";
import type { LanguageCode, Translation } from "../locales";
import type { ReactNode } from "react";
import type { TranslateOptions } from "i18n-js";
import type { TranslationKey } from "../__generated__/TranslationKey";

type TypeSafeTranslate = (key: TranslationKey, options?: TranslateOptions) => string;
type EnhancedI18n = Omit<I18n, "t" | "translate>"> & {
  readonly translate: TypeSafeTranslate;
  readonly t: TypeSafeTranslate;
};
type Translations = Record<LanguageCode, Translation>;
type InterpolateVariables = Record<string, ReactNode | string>;

const translations: Translations = {
  en,
  es,
};

/**
 * Filters out non-interpolation variables from i18n options. See `TranslateOptions` the type for more info.
 * @param options The i18n options object that include interpolation variables.
 * @returns A filtered `TranslateOptions` object containing only interpolation variables.
 */
const filterOptions = (options: TranslateOptions): InterpolateVariables =>
  Object.fromEntries(
    Object.entries(options).filter(
      ([key]) => !["defaultValue", "count", "scope", "defaults", "missingBehavior"].includes(key),
    ),
  );

/**
 * Checks if any of the interpolation variables is a React element, e.g. the template key ends with "Element"
 * @param variables An object whose keys correspond to a `variableName` to be substituted in the `template`.
 * @returns boolean
 */
const doesVariablesContainReactElements = (variables: InterpolateVariables): boolean =>
  Object.entries(variables).some(([key]) => key.endsWith("Element"));

/**
 * Simple interpolation of string variables following the i18n-js interpolation template.
 * @param template The string template containing variables in the format `%{variableName}`. Important: `variableName` must not end with `Element`.
 * @param variables An object whose keys correspond to a `variableName` to be substituted in the `template`.
 * @returns An interpolated string.
 * @example For the template "I like %{animal}" and variables {animal: "turtles"}, this yields "I like turtles"
 */
const interpolateStrings = (template: string, variables: InterpolateVariables): string =>
  Object.entries(variables)
    .filter(([key]) => !key.endsWith("Element"))
    .reduce((str, [key, value]) => str.replaceAll(`%{${key}}`, String(value)), template);

/**
 * "Interpolates" React Elements with a given string template.
 * @param template The string template containing variables in the format `%{variableName}`. Important: `variableName` must end with `Element`.
 * @param variables An object whose keys correspond to a `variableName` to be substituted in the `template`.
 * @returns An array of Text children, e.g. renderable like `<Text>{returnValue}</Text>. May contain nested `Text` elements.
 * @example For the template "Some %{bold} text" and variables {bold: <Text style={...}>bolded</Text>}, this yields ["Some", <Text style={...}>bolded</Text>, "text"]
 */
const interpolateReactElements = (template: string, variables: InterpolateVariables): readonly ReactNode[] => {
  // first get all the *Element template variables
  const variableNames = Object.entries(variables)
    .filter(([key]) => key.endsWith("Element"))
    .map(([key]) => key);

  // now we get the template variables sorted by their indexes in the string in ascending order
  const sortedVariableNames = variableNames
    .map<readonly [string, number]>((variableName) => [variableName, template.indexOf(`%{${variableName}}`)])
    .filter(([, indexInTemplate]) => indexInTemplate !== -1)
    .sort((a, b) => (a[1] > b[1] ? 1 : -1))
    .map(([variableName]) => variableName);

  // finally for each React Element to be rendered, split the string and insert in the middle
  // this is why the variables are sorted in ascending order, since we work left to right
  const results = sortedVariableNames.reduce<{
    readonly elements: readonly ReactNode[];
    readonly workingTemplate: string;
  }>(
    (accumulator, variableName) => {
      const { elements, workingTemplate } = accumulator;
      // get the left and right indexes in the template, we need this because it shifts around as we build the string
      const leftIndex = workingTemplate.indexOf(`%{${variableName}}`);
      const rightIndex = leftIndex + 3 + variableName.length;
      const tuple: readonly [string, ReactNode] = [workingTemplate.slice(0, leftIndex), variables[variableName]];

      return {
        elements: elements.concat(tuple),
        workingTemplate: workingTemplate.slice(rightIndex),
      };
    },
    {
      elements: [],
      workingTemplate: template,
    },
  );

  return results.elements.concat(results.workingTemplate);
};

export const useTranslation = () => {
  const { locale } = useLocaleStore();
  const i18n = new I18n(translations, {
    enableFallback: true,
    locale,
  });

  // TODO: investigate if we can improve further https://github.com/fnando/i18n/blob/main/src/helpers/interpolate.ts
  // @ts-expect-error i18n.interpolate is typed as string[], but is actually capable of ReactNode[]
  // eslint-disable-next-line functional/immutable-data
  i18n.interpolate = (i18nInstance, message, options) => {
    const interpolationVariables = filterOptions(options);
    const interpolatedString = interpolateStrings(message, interpolationVariables);

    return doesVariablesContainReactElements(interpolationVariables)
      ? interpolateReactElements(interpolatedString, interpolationVariables)
      : interpolatedString;
  };

  return i18n as EnhancedI18n;
};
