// Copyright 2022 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import { BankAlreadyLinkedScreen } from "../screens/BankAlreadyLinkedScreen";
import { Button } from "./Button";
import { ErrorMessage, Formik } from "formik";
import { Loading } from "./Loading";
import { None } from "../utils/None";
import { Platform, StyleSheet, Text, View } from "react-native";
import { Select } from "./Select";
import { Some } from "../utils/Some";
import { TextInput } from "./TextInput";
import { UnreachableCaseError } from "../utils/UnreachableCaseError";
import { UserType, useUserStore } from "../store/userStore";
import { dwollaClient } from "../api-client/DwollaClient";
import { setTestProps } from "../utils/propHelper";
import { useApi } from "../services/useApi";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useToast } from "react-native-toast-notifications";
import { useTranslation } from "../hooks/useTranslation";
import React, { useState } from "react";
import validator from "validator";

type BankAccount = "checking" | "savings";

type FormValues = {
  readonly accountNumber: string;
  readonly name: string;
  readonly routingNumber: string;
  readonly type: string;
  readonly confirmAccountNumber: string;
};

const defaultFormValues = {
  accountNumber: "",
  confirmAccountNumber: "",
  name: "",
  routingNumber: "",
  type: "",
};

type Props = {
  readonly onSuccess: () => void;
};

export const BankAccountForm = ({ onSuccess }: Props) => {
  const { achClient } = useApi();
  const { isDesktopOrLarger } = useDeviceSize();
  const toast = useToast();
  const { errorHandler } = useDefaultErrorHandler();
  const i18n = useTranslation();
  const { selectedOrg, setUser, user, userType } = useUserStore();
  const [isLoading, setIsLoading] = useState(false);
  const styles = StyleSheet.create({
    body: {
      backgroundColor: "#FFFFFF",
      flex: 1,
      padding: 40,
    },
    container: {
      flex: 1,
      ...(isDesktopOrLarger && {
        paddingHorizontal: 150,
        paddingVertical: 40,
      }),
      zIndex: 1,
    },
    submit: {
      alignItems: "center",
      paddingVertical: 24,
    },
  });

  if (None(user)) {
    throw new Error("Could not load user details");
  }

  if (user.type === UserType.MERIT_CS) {
    throw new Error("Not a valid user type to connect the bank account");
  }

  // eslint-disable-next-line react/no-multi-comp
  const ErrorText = (error: string) => (
    <View style={{ paddingVertical: 8 }}>
      <Text style={{ color: "#D03931", fontSize: 12 }}>{error}</Text>
    </View>
  );

  const validationSchema = Yup.object().shape({
    accountNumber: Yup.string()
      .trim()
      .required(i18n.t("BankAccountForm.accountNumberEmptyError"))
      .test(
        "validate-account-number",
        i18n.t("BankAccountForm.accountNumberInvalidError"),
        (value) => Some(value) && validator.isNumeric(value) && /^[0-9]{4,17}$/u.test(value),
      ),
    confirmAccountNumber: Yup.string()
      .trim()
      .required(i18n.t("BankAccountForm.confirmAccountNumberEmptyError"))
      .oneOf([Yup.ref("accountNumber"), null], i18n.t("BankAccountForm.confirmAccountNumberInvalidError")),
    name: Yup.string().trim().min(1).max(50).required(i18n.t("BankAccountForm.bankAccountNameEmptyError")),
    routingNumber: Yup.string()
      .trim()
      .required(i18n.t("BankAccountForm.routingNumberEmptyError"))
      .test("validate-routing-number", i18n.t("BankAccountForm.routingNumberInvalidError"), (value) => {
        if (Some(value) && validator.isNumeric(value) && /^[0-9]{9}$/u.test(value)) {
          // https://en.wikipedia.org/wiki/Routing_transit_number
          // The first two digits of the nine digit RTN must be in the ranges
          // 00 through 12, 21 through 32, 61 through 72, or 80.
          const firstTwo = Number(value.slice(0, 2));
          const isValidFirstTwo =
            (firstTwo >= 0 && firstTwo <= 12) ||
            (firstTwo >= 21 && firstTwo <= 32) ||
            (firstTwo >= 61 && firstTwo <= 72) ||
            firstTwo === 80;

          // Checksum validation
          // https://github.com/Chris-ScoutCorps/bank-routing-number-validator/blob/master/index.js
          // http://www.brainjar.com/js/validation/
          const isValidChecksum =
            value.split("").reduce((sum, i, index) => sum + Number(i) * [3, 7, 1][index % 3], 0) % 10 === 0;

          return isValidFirstTwo && isValidChecksum;
        }

        return false;
      }),
    type: Yup.string().required(i18n.t("BankAccountForm.bankAccountTypeEmptyError")),
  });

  const submitBankDetails = async (values: Readonly<FormValues>) => {
    setIsLoading(true);

    const isBankAccount = (type: string): type is BankAccount => type === "checking" || type === "savings";

    const { accountNumber, name, routingNumber, type } = values;
    if (!isBankAccount(type)) {
      throw new Error("Invalid bank account type");
    }

    try {
      const getFundingSourcesToken = async () => {
        if (None(userType)) {
          throw new Error("Somehow userType is not present");
        }

        switch (userType) {
          case UserType.PARENT:
            const parentToken = await achClient.getFundingSourcesTokenForParent();

            return parentToken;
          case UserType.SERVICE_PROVIDER:
            if (None(selectedOrg)) {
              throw new Error("Service provider cannot be in this state without selecting an org");
            }
            const serviceProviderToken = await achClient.getFundingSourcesTokenForServiceProvider(selectedOrg.id);

            return serviceProviderToken;

          case UserType.MERIT_CS:
            throw new Error("Merit CS is not allowed to perform this action");

          default:
            throw new UnreachableCaseError(userType);
        }
      };

      const response = await getFundingSourcesToken();

      if (response.success) {
        const { token } = response.data;
        await dwollaClient.createFundingSource(token, { accountNumber, name, routingNumber, type });

        setIsLoading(false);

        toast.show(
          <Text {...setTestProps({ name: "bankDetailsSubmittedMessage-BankAccountForm" })}>
            {i18n.t("BankAccountForm.toastSuccessMessage")}
          </Text>,
          {
            placement: "bottom",
            type: "success",
          },
        );
        onSuccess();
      } else {
        setIsLoading(false);
        setUser({ ...user, hasHealthyBankConnection: true });
      }
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  if (Some(user) && user.hasHealthyBankConnection) {
    return <BankAlreadyLinkedScreen />;
  }

  return (
    <View style={{ flex: 1 }}>
      <Formik initialValues={defaultFormValues} onSubmit={submitBankDetails} validationSchema={validationSchema}>
        {({ handleChange, handleSubmit, setFieldValue, values }) => (
          <>
            <View style={styles.container}>
              <View style={styles.body}>
                <View style={{ width: isDesktopOrLarger ? "40%" : undefined }}>
                  <TextInput
                    keyboardType="decimal-pad"
                    label={i18n.t("BankAccountForm.routingNumberLabel")}
                    onChangeText={handleChange("routingNumber")}
                    placeholder={i18n.t("BankAccountForm.routingNumberLabel")}
                    testID="routingNumberInputField-BankAccountForm"
                    value={values.routingNumber.trim()}
                  />
                  <ErrorMessage name="routingNumber">{ErrorText}</ErrorMessage>
                </View>

                <View style={{ height: 20 }} />

                <View style={{ width: isDesktopOrLarger ? "40%" : undefined }}>
                  <TextInput
                    keyboardType="decimal-pad"
                    label={i18n.t("BankAccountForm.accountNumberLabel")}
                    onChangeText={handleChange("accountNumber")}
                    placeholder={i18n.t("BankAccountForm.accountNumberLabel")}
                    testID="accountNumberInputField-BankAccountForm"
                    value={values.accountNumber.trim()}
                  />
                  <ErrorMessage name="accountNumber">{ErrorText}</ErrorMessage>
                </View>

                <View style={{ height: 20 }} />

                <View style={{ width: isDesktopOrLarger ? "40%" : undefined }}>
                  <TextInput
                    keyboardType="decimal-pad"
                    label={i18n.t("BankAccountForm.confirmAccountNumberLabel")}
                    onChangeText={handleChange("confirmAccountNumber")}
                    placeholder={i18n.t("BankAccountForm.confirmAccountNumberLabel")}
                    testID="confirmAccountNumberInputField-BankAccountForm"
                    value={values.confirmAccountNumber.trim()}
                  />
                  <ErrorMessage name="confirmAccountNumber">{ErrorText}</ErrorMessage>
                </View>

                <View style={{ height: 20 }} />

                <View style={{ width: isDesktopOrLarger ? "40%" : undefined }}>
                  <TextInput
                    label={i18n.t("BankAccountForm.bankAccountNameLabel")}
                    onChangeText={handleChange("name")}
                    placeholder={i18n.t("BankAccountForm.bankAccountNameLabel")}
                    testID="bankAccountNameInputField-BankAccountForm"
                    value={values.name}
                  />
                  <ErrorMessage name="name">{ErrorText}</ErrorMessage>
                </View>

                <View style={{ height: 20 }} />

                <View style={{ width: isDesktopOrLarger ? "40%" : undefined }}>
                  <Text>{i18n.t("BankAccountForm.bankAccountTypeLabel")}</Text>
                  <View style={{ zIndex: 1 }}>
                    <Select
                      onSelectOption={(option) => {
                        setFieldValue("type", option?.value);
                      }}
                      options={[
                        { label: i18n.t("BankAccountForm.bankAccountTypeOptionChecking"), value: "checking" },
                        { label: i18n.t("BankAccountForm.bankAccountTypeOptionSavings"), value: "savings" },
                      ]}
                      selectedValue={values.type}
                      testID="bankAccountTypeDropdown-BankAccountForm"
                    />
                  </View>
                  <ErrorMessage name="type">{ErrorText}</ErrorMessage>
                </View>
              </View>
            </View>

            {!isDesktopOrLarger && Platform.OS === "web" && <View style={{ height: 40 }} />}

            <View style={styles.submit}>
              <Button
                customContent={
                  isLoading ? (
                    <View style={{ minWidth: 160 }}>
                      <Loading />
                    </View>
                  ) : undefined
                }
                disabled={isLoading}
                onPress={handleSubmit}
                testID="submitButton-BankAccountForm"
                text={i18n.t("BankAccountForm.submitButtonText")}
              />
            </View>
          </>
        )}
      </Formik>
    </View>
  );
};
