// Copyright 2024 Merit International Inc. All Rights Reserved.

import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import type { GetConfigurationResponse } from "../__generated__/api/ConfigurationRoute";

type Configuration = GetConfigurationResponse;

type ConfigurationState = {
  readonly configuration: Configuration | undefined;
  readonly setConfiguration: (configuration: Configuration) => void;
};

export const useConfigurationStore = create<ConfigurationState>()(
  persist(
    (set) => ({
      configuration: undefined,
      setConfiguration: (configuration: Configuration) => {
        set({ configuration });
      },
    }),
    {
      name: "configuration-storage",
      storage: createJSONStorage(() => AsyncStorage),
    },
  ),
);
