// Copyright 2022 Merit International Inc. All Rights Reserved

import { StyleSheet, View } from "react-native";
import { TableHeading } from "./TableHeading.web";
import { TableRow } from "./TableRow.web";
import { sortTableData, useSort } from "./sort";
import React, { useState } from "react";
import type { TableProps, TableRow as TableRowType } from "./types";

/*
  Merit-themed type-safe table

  The order of the columns is determined by the order of the columns array
*/
const MIN_COLUMN_WIDTH = 100;

const styles = StyleSheet.create({
  tableContainer: {
    backgroundColor: "#FFFFFF",
    flex: 1,
    overflow: "scroll",
  },
});

export function Table<T extends TableRowType>({
  columns,
  data,
  defaultSort = {
    direction: "Ascending",
    key: "id",
  },
  emptyComponent,
  render,
}: TableProps<T>) {
  const [tableWidth, setTableWidth] = useState<number>();
  const [currentSorting, handleChangeSort] = useSort<T>(defaultSort);

  // No columns - if there are dynamically generated columns this will just return
  // an empty view while they are being generated rather than crashing
  if (columns.length === 0) {
    return <View />;
  }

  const autoWidth = Math.max(MIN_COLUMN_WIDTH, (tableWidth ?? columns.length * 10) / columns.length);

  return (
    <View
      onLayout={(e) => {
        setTableWidth(e.nativeEvent.layout.width);
      }}
      style={styles.tableContainer}
    >
      <table
        style={{
          border: 0,
          borderCollapse: "collapse",
          borderSpacing: 0,
          margin: 0,
          padding: 0,
        }}
      >
        <thead>
          <tr
            style={{
              borderBottom: "1px solid rgba(0,0,0,0.1)",
            }}
          >
            {columns.map((column, index) => (
              <TableHeading
                autoWidth={autoWidth}
                column={column}
                currentSorting={currentSorting}
                handleChangeSort={handleChangeSort}
                isFirst={index === 0}
                isLast={index === columns.length}
                key={column.key}
                testID={`${column.title}-TableHeading`}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {data === undefined || data.length === 0
            ? emptyComponent
            : sortTableData(data, currentSorting).map((row) => (
                <TableRow autoWidth={autoWidth} columns={columns} key={row.id} render={render} row={row} />
              ))}
        </tbody>
      </table>
    </View>
  );
}
