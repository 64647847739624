/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit/form214: Import custom error for 400 status code
import { Error400 } from "../../exceptions/Error400";
// merit/form214

import { SubmitClaimPayload } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

// merit/form214: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit/form214

export class User<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetUser
   * @request GET:/api/user/
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getUser = (params: RequestParams = {}) =>
    this.request<
      | {
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            expirationDate?: string;
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | (2021 & 2022);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "parent";
        }
      | {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "service_provider";
        }
      | {
          email: string;
          id: number;
          type: "merit_cs";
        }
      | ({
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            expirationDate?: string;
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | (2021 & 2022);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "parent";
        } & {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "service_provider";
        } & {
          email: string;
          id: number;
          type: "merit_cs";
        }),
      any
    >({
      path: `/api/user/`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name GetParent
   * @request GET:/api/user/parent
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getParent = (params: RequestParams = {}) =>
    this.request<
      | {
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            expirationDate?: string;
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | (2021 & 2022);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "parent";
        }
      | {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "service_provider";
        }
      | {
          email: string;
          id: number;
          type: "merit_cs";
        }
      | ({
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            expirationDate?: string;
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | (2021 & 2022);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "parent";
        } & {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "service_provider";
        } & {
          email: string;
          id: number;
          type: "merit_cs";
        }),
      any
    >({
      path: `/api/user/parent`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name GetMeritCs
   * @request GET:/api/user/merit-cs
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getMeritCs = (params: RequestParams = {}) =>
    this.request<
      | {
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            expirationDate?: string;
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | (2021 & 2022);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "parent";
        }
      | {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "service_provider";
        }
      | {
          email: string;
          id: number;
          type: "merit_cs";
        }
      | ({
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            expirationDate?: string;
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | (2021 & 2022);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "parent";
        } & {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "service_provider";
        } & {
          email: string;
          id: number;
          type: "merit_cs";
        }),
      any
    >({
      path: `/api/user/merit-cs`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name GetQesp
   * @request GET:/api/user/service-provider/{selectedOrgId}
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getQesp = (selectedOrgId: string, params: RequestParams = {}) =>
    this.request<
      | {
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            expirationDate?: string;
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | (2021 & 2022);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "parent";
        }
      | {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "service_provider";
        }
      | {
          email: string;
          id: number;
          type: "merit_cs";
        }
      | ({
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            expirationDate?: string;
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | (2021 & 2022);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "parent";
        } & {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "service_provider";
        } & {
          email: string;
          id: number;
          type: "merit_cs";
        }),
      any
    >({
      path: `/api/user/service-provider/${selectedOrgId}`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name SubmitClaim
   * @request POST:/api/user/claim
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  submitClaim = async (body: SubmitClaimPayload, params: RequestParams = {}): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/user/claim`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name SetHasProvidedBankDetailsForParent
   * @request PATCH:/api/user/parent/set-bank-flag
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  setHasProvidedBankDetailsForParent = (params: RequestParams = {}) =>
    this.request<
      | {
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            expirationDate?: string;
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | (2021 & 2022);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "parent";
        }
      | {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "service_provider";
        }
      | {
          email: string;
          id: number;
          type: "merit_cs";
        }
      | ({
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            expirationDate?: string;
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | (2021 & 2022);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "parent";
        } & {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "service_provider";
        } & {
          email: string;
          id: number;
          type: "merit_cs";
        }),
      any
    >({
      path: `/api/user/parent/set-bank-flag`,
      method: "PATCH",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name SetHasProvidedBankDetailsForServiceProvider
   * @request PATCH:/api/user/service-provider/{selectedOrgId}/set-bank-flag
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  setHasProvidedBankDetailsForServiceProvider = (selectedOrgId: string, params: RequestParams = {}) =>
    this.request<
      | {
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            expirationDate?: string;
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | (2021 & 2022);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "parent";
        }
      | {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "service_provider";
        }
      | {
          email: string;
          id: number;
          type: "merit_cs";
        }
      | ({
          children: {
            awardDate: string;
            claims: {
              amount: number;
              createdAt: string;
              id: number;
              status:
                | "Pending Internal Review"
                | "Pending Vendor Approval"
                | "Accepted"
                | "Rejected"
                | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
            }[];
            dollars: {
              available: number;
              pending: number;
              spent: number;
            };
            expirationDate?: string;
            firstName: string;
            homeSchooled: boolean;
            id: number;
            lastName: string;
            schoolYear?: 2021 | 2022 | (2021 & 2022);
          }[];
          email: string;
          firstName: string;
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          lastName: string;
          type: "parent";
        } & {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          id: number;
          type: "service_provider";
        } & {
          email: string;
          id: number;
          type: "merit_cs";
        }),
      any
    >({
      path: `/api/user/service-provider/${selectedOrgId}/set-bank-flag`,
      method: "PATCH",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name GetExpirationDate
   * @request GET:/api/user/child/{childId}/expiration-date
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getExpirationDate = (childId: string, params: RequestParams = {}) =>
    this.request<
      {
        expirationDate: string;
      },
      any
    >({
      path: `/api/user/child/${childId}/expiration-date`,
      method: "GET",
      format: "json",
      ...params,
    });
}
