/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit/form214: Import custom error for 400 status code
import { Error400 } from "../../exceptions/Error400";
// merit/form214

import { UpdateServiceProviderStatusPayload } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

// merit/form214: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit/form214

export class ServiceProvider<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetServiceProvider
   * @request GET:/api/service-provider/{number}
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  getServiceProvider = async (
    number: string,
    params: RequestParams = {},
  ): Promise<
    Response<{
      hasHealthyBankConnection: boolean;
      hasProvidedBankDetails: boolean;
      name: string;
      number: string;
      status: "Active" | "Inactive" | ("Active" & "Inactive");
    }>
  > => {
    try {
      const response = await this.request<
        {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          name: string;
          number: string;
          status: "Active" | "Inactive" | ("Active" & "Inactive");
        },
        {
          error: string;
        }
      >({
        path: `/api/service-provider/${number}`,
        method: "GET",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name UpdateServiceProviderStatus
   * @request PATCH:/api/service-provider/status
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  updateServiceProviderStatus = async (
    body: UpdateServiceProviderStatusPayload,
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/service-provider/status`,
        method: "PATCH",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetPendingVendorApprovalClaims
   * @request GET:/api/service-provider/{selectedOrgId}/pending-vendor-approval-claims
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getPendingVendorApprovalClaims = (selectedOrgId: string, params: RequestParams = {}) =>
    this.request<
      {
        pendingVendorApprovalClaims: {
          amount: number;
          child: {
            firstName: string;
            lastName: string;
          };
          createdAt: string;
          externalReviewLink?: string;
          id: number;
          parent: {
            firstName: string;
            lastName: string;
          };
          payTo: string;
          status: string;
        }[];
      },
      any
    >({
      path: `/api/service-provider/${selectedOrgId}/pending-vendor-approval-claims`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name GetPendingInternalReviewClaims
   * @request GET:/api/service-provider/{selectedOrgId}/pending-internal-review-claims
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getPendingInternalReviewClaims = (selectedOrgId: string, params: RequestParams = {}) =>
    this.request<
      {
        pendingInternalReviewClaims: {
          amount: number;
          child: {
            firstName: string;
            lastName: string;
          };
          createdAt: string;
          id: number;
          parent: {
            firstName: string;
            lastName: string;
          };
          payTo: string;
          status: string;
        }[];
      },
      any
    >({
      path: `/api/service-provider/${selectedOrgId}/pending-internal-review-claims`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name GetHistoryClaims
   * @request GET:/api/service-provider/{selectedOrgId}/history-claims
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getHistoryClaims = (selectedOrgId: string, params: RequestParams = {}) =>
    this.request<
      {
        historyClaims: {
          amount: number;
          child: {
            firstName: string;
            lastName: string;
          };
          createdAt: string;
          deliverFundsTo: string;
          id: number;
          parent: {
            firstName: string;
            lastName: string;
          };
          reviewedAt: string;
          reviewerName: string;
          status:
            | "Pending Internal Review"
            | "Pending Vendor Approval"
            | "Accepted"
            | "Rejected"
            | ("Pending Internal Review" & "Pending Vendor Approval" & "Accepted" & "Rejected");
        }[];
      },
      any
    >({
      path: `/api/service-provider/${selectedOrgId}/history-claims`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name GetClaimStats
   * @request GET:/api/service-provider/{selectedOrgId}/claim-stats
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getClaimStats = (selectedOrgId: string, params: RequestParams = {}) =>
    this.request<
      {
        historyCount: number;
        pendingInternalReviewCount: number;
        pendingVendorApprovalCount: number;
      },
      any
    >({
      path: `/api/service-provider/${selectedOrgId}/claim-stats`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name GetServiceProviderAccessMerits
   * @request GET:/api/service-provider/access-merits
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  getServiceProviderAccessMerits = async (
    params: RequestParams = {},
  ): Promise<
    Response<{
      merits: {
        id: string;
        name: string;
        templateId: string;
      }[];
    }>
  > => {
    try {
      const response = await this.request<
        {
          merits: {
            id: string;
            name: string;
            templateId: string;
          }[];
        },
        {
          error: string;
        }
      >({
        path: `/api/service-provider/access-merits`,
        method: "GET",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetServiceProviderForReviewClaim
   * @request GET:/api/service-provider/{token}/{number}
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  getServiceProviderForReviewClaim = async (
    number: string,
    token: string,
    params: RequestParams = {},
  ): Promise<
    Response<{
      hasHealthyBankConnection: boolean;
      hasProvidedBankDetails: boolean;
      name: string;
      number: string;
      status: "Active" | "Inactive" | ("Active" & "Inactive");
    }>
  > => {
    try {
      const response = await this.request<
        {
          hasHealthyBankConnection: boolean;
          hasProvidedBankDetails: boolean;
          name: string;
          number: string;
          status: "Active" | "Inactive" | ("Active" & "Inactive");
        },
        {
          error: string;
        }
      >({
        path: `/api/service-provider/${token}/${number}`,
        method: "GET",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
}
